import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import CustomAxios from "../../../../utils/axios";
import moment from "moment";
import CustomBootstrapTable from "../../../_helpers/CustomBootstrapTable";
import { useHistory } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import "./OnboardingProgress.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ProgressIcon from "./OnboardingProgressIcon";
import * as requestFromServer from "../../../../app/modules/Jobs/_redux/customers/customersCrud";

const OnboardingProgress = ({
  settings,
  saveDraggable,
  saveSetting,
  ...props
}) => {
  const history = useHistory();
  const userData = useSelector((state) => state.auth.user);
  const [progress, setProgress] = useState(1);
  const [nounce, setNounce] = useState(0);

  const lines = ["Welcome!", "G'day!", "Let's grow your business!"];

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    requestFromServer
      .getOnboardingProgress()
      .then((response) => {
        //hideconsole.log(response.data);
        const onBoarding = response.data;
        const length = 100 / 5;

        if (onBoarding.find((obj) => obj.page_id == 8)?.first_view === 0) {
          setProgress((prev) => prev + length);
          //hideconsole.log("in onboarding 1");
          if (onBoarding.find((obj) => obj.page_id == 3)?.first_view === 0) {
            //hideconsole.log("in onboarding 2");
            setProgress((prev) => prev + length);
            if (onBoarding.find((obj) => obj.page_id == 6)?.first_view === 0) {
              //hideconsole.log("in onboarding 3");
              setProgress((prev) => prev + length);
              if (
                onBoarding.find((obj) => obj.page_id == 4)?.first_view === 0
              ) {
                setProgress((prev) => prev + length);
                if (
                  onBoarding.find((obj) => obj.page_id == 20)?.first_view === 0
                ) {
                  setProgress((prev) => prev + length);
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  }, []);

  //   useEffect(() => {

  //       const onBoarding = userData.onBoarding;
  //       const length = 100 / 5;

  //       if (onBoarding.find((obj) => obj.page_id == 8)?.first_view === 0) {
  //         setProgress((prev) => prev + length);
  //         //hideconsole.log("in onboarding 1");
  //         if (onBoarding.find((obj) => obj.page_id == 3)?.first_view === 0) {
  //           setProgress((prev) => prev + length);
  //           if (onBoarding.find((obj) => obj.page_id == 6)?.first_view === 0) {
  //             setProgress((prev) => prev + length);
  //             if (onBoarding.find((obj) => obj.page_id == 4)?.first_view === 0) {
  //               setProgress((prev) => prev + length);
  //               if (
  //                 onBoarding.find((obj) => obj.page_id == 20)?.first_view === 0
  //               ) {
  //                 setProgress((prev) => prev + length);
  //               }
  //             }
  //           }
  //         }
  //       }

  //   }, []);

  return (
    <div className={`card card-custom p-3 mt-4 mb-8 ml-4 totorial_progress`}>
      <ProgressBar
        percent={progress}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        {/* <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>{lines[nounce]}</Tooltip>}
            >
              <a
                className="sendie_icon"
                onClick={() =>
                  setNounce((prev) => (prev == lines.length - 1 ? 0 : prev + 1))
                }
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  ></SVG>
                </span>
                <img
                  style={{ maxWidth: 100 }}
                  src={toAbsoluteUrl("/icons/sendie3.webp")}
                />
              </a>
            </OverlayTrigger>
          )}
        </Step> */}

        <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>Team</Tooltip>}>
              <a
                className="progress_icon"
                onClick={() => history.push("/team?first_view=1")}
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>Job</Tooltip>}>
              <a
                className="progress_icon"
                onClick={() => history.push("/jobs?first_view=1")}
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </Step>

        <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>Quote</Tooltip>}>
              <a
                className="progress_icon"
                onClick={() => history.push("/quotes?first_view=1")}
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/File-done.svg")}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </Step>

        <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>Invoice</Tooltip>}>
              <a
                className="progress_icon"
                onClick={() => history.push("/invoices?first_view=1")}
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Selected-file.svg"
                    )}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </Step>

        {/* <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>Clients</Tooltip>}>
              <a
                className="progress_icon"
                onClick={() => history.push("/clients?first_view=1")}
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </Step> */}

        <Step transition="scale">
          {({ accomplished }) => (
            <OverlayTrigger overlay={<Tooltip>Settings</Tooltip>}>
              <a
                className="progress_icon"
                onClick={() => history.push("/settings?first_view=1")}
              >
                <span
                  className={`svg-icon svg-icon-2x ${
                    accomplished ? "svg-icon-warning" : ""
                  }`}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Settings-2.svg"
                    )}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { settings: state.settings, draggingData: state.draggingData };
};

export default connect(mapStateToProps, { saveSetting, saveDraggable })(
  OnboardingProgress
);
