import React, { useMemo, useState, useEffect } from "react";
import { useCustomersUIContext } from "../CustomersUIContext";
import * as RequestFromServer from "../../../_redux/customers/customersCrud";
import { ReceiptEditDailog } from "../customer-receipt-dialog/ReceiptEditDailog/ReceiptEditDailog";
import { BulkReviewModal } from "./BulkReviewModal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { JobsignatureModal } from "./JobSignatrueModal";
import { toast } from "react-toastify";
import { JobAddDialog } from "../customer-add-dialog/JobAddDialog";

export function CustomersGrouping({ value, ids, setIds }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      // ids: customersUIContext.ids,
      // setIds: customersUIContext.setIds,
      openDeleteCustomersDialog: customersUIContext.openDeleteCustomersDialog,
      openFetchCustomersDialog: customersUIContext.openFetchCustomersDialog,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openUpdateCustomersStatusDialog:
        customersUIContext.openUpdateCustomersStatusDialog,
      openReceiptCustomerDialog: customersUIContext.openReceiptCustomerDialog,
      openEditReceiptCustomerDialog:
        customersUIContext.openEditReceiptCustomerDialog,
      openCustomerCallBackDialog: customersUIContext.openCustomerCallBackDialog,
    };
  }, [customersUIContext]);
  const [openEditInoice, setopenEditInoice] = useState(false);
  const [ReciptData, setReciptData] = useState([]);
  const [show, setShow] = useState(false);
  const [showsignatureModal, setShowsignatureModal] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const { rolePermissions } = useSelector((state) => state.rightSettings);
  const [fetchingInvoice, setFetchingInvoice] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [jobToDuplicate, setJobToDuplicate] = useState(null);

  const handleCloseEditReceipt = () => {
    setopenEditInoice(false);
    setReciptData([]);
  };

  const handleDuplicateJob = async () => {
    try {
      console.log("Starting job duplication for ID:", ids[0]);

      const response = await RequestFromServer.getJobById(ids[0]);
      const jobData = response?.data?.result;

      if (!jobData) {
        toast.error("Failed to fetch job data. Please try again.");
        return;
      }

      // Fetch dropdown data: Job Types, Leads, and Workers
      const [
        jobTypesResponse,
        leadsResponse,
        workersResponse,
      ] = await Promise.all([
        RequestFromServer.getJobsType(),
        RequestFromServer.getCompanyLeadsList(),
        RequestFromServer.getWorkers(),
      ]);

      const jobTypeOptions = jobTypesResponse.data?.result || [];
      const leadsOptions = leadsResponse.data?.result || [];
      const workerOptions = workersResponse.data?.result || [];

      // Utility to map IDs to proper format { value, label }
      const mapToOption = (list, id) =>
        list.find((item) => item.value.toString() === id.toString()) || null;

      // Format clientData for JobAddDialog
      const clientData = {
        name: jobData.name || "",
        phone: jobData.phone || "",
        email: jobData.email || "",
        mainaddress: jobData.address || "",
        id: null,
        job_type: jobData.job_type
          ? jobData.job_type
              .split(",")
              .map((id) => mapToOption(jobTypeOptions, id))
          : [],
        assign_worker: jobData.user_id
          ? [mapToOption(workerOptions, jobData.user_id)].filter(Boolean)
          : [],
        lead_from: jobData.leads_id
          ? [mapToOption(leadsOptions, jobData.leads_id)].filter(Boolean)
          : [],
        appointment_time: jobData.appointment_time || "",
        schedule_appointment: jobData.appointment === 1,
        appointment: jobData.appointment === 1,
        latitude: jobData.latitude || "",
        longitude: jobData.longitude || "",
        company_comment: jobData.company_comment || "",
        tasks: jobData.tasks_info ? JSON.parse(jobData.tasks_info) : [],
        tasks_info: jobData.tasks_info, // Add this
        Add_info: jobData.tradie_comment || jobData.Add_info || "", // Add this
        tradie_comment: jobData.tradie_comment || "", // Add this
        parts: jobData.parts ? JSON.parse(jobData.parts) : [],
        salary: jobData.salary ? JSON.parse(jobData.salary) : [],
        money: jobData.money || 0,
        payby_id: jobData.payby_id || null,
        include_tax: jobData.include_tax || 0,
        suburb_name: jobData.suburb_name || "",
      };

      console.log("Mapped clientData for JobAddDialog:", clientData);

      // Pass populated data to the JobAddDialog modal
      setJobToDuplicate(clientData);
      setShowDuplicateModal(true);
    } catch (error) {
      console.error("Error in handleDuplicateJob:", error);
      toast.error("Failed to duplicate job. Please try again.");
    }
  };

  const handleCheckEditCreated = () => {
    setFetchingInvoice(true);
    const data = {
      job_id: ids[0],
    };
    RequestFromServer.fetchLastInvoiceAndReceipt(data)
      .then((res) => {
        const value = res?.data?.data;
        const isChecked = value
          ? value?.invoices?.length > 0 || value?.receipts?.length > 0 // Changed from lastReceipt/lastInvoice to invoices/receipts
          : false;

        if (isChecked) {
          setReciptData(value);
          setopenEditInoice(true);
        } else {
          customersUIProps.openReceiptCustomerDialog(ids[0]);
        }
        setFetchingInvoice(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingInvoice(false);
      });
  };

  const EditReceiptDailogSubmit = (invoiceData) => {
    console.log("Invoice data passed to EditReceiptDailogSubmit:", invoiceData); // Debug log
    customersUIProps?.openEditReceiptCustomerDialog(ids[0], invoiceData); // Pass the job and invoice data
  };

  const handleBulkReview = () => {
    setShow(true);
  };

  return (
    <div>
      <div
        className="d-flex flex-wrap mt-4 tour_buttons"
        style={{ gap: "1rem", marginBottom: "2rem" }}
      >
        {rolePermissions
          ?.find((item) => item.permission_id == 1)
          ?.roles?.includes(userData?.group_id) ? (
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm me-3"
            style={{ width: 208 }}
            disabled={ids.length != 1}
            onClick={() => customersUIContext.openEditCustomerDialog(ids[0])}
          >
            <i className="fa fa-edit"></i> View
          </button>
        ) : (
          ""
        )}

        <button
          style={{ width: 208 }}
          onClick={() => {
            //hideconsole.log(ids[0]);
            handleCheckEditCreated(ids[0]);
          }}
          type="button"
          disabled={ids.length != 1 || fetchingInvoice}
          className="btn btn-light-primary font-weight-bolder font-size-sm me-3 tour_new_invoice"
        >
          <i className="fa fa-file-invoice"></i> Invoice{" "}
          {fetchingInvoice ? (
            <span
              className="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            ""
          )}
        </button>

        {userData.pack_id != 1 && userData.pack_id != 2 ? (
          <button
            style={{ width: 208 }}
            onClick={() => {
              //hideconsole.log(ids);
              handleBulkReview();
            }}
            type="button"
            disabled={ids.length < 1}
            className="btn btn-light-primary font-weight-bolder font-size-sm "
          >
            <i className="fas fa-star"></i> Bulk Review
          </button>
        ) : (
          ""
        )}

        <button
          style={{ width: 208 }}
          onClick={() => {
            //hideconsole.log(ids);
            setShowsignatureModal(true);
          }}
          type="button"
          disabled={ids.length != 1}
          className="btn btn-light-primary font-weight-bolder font-size-sm"
        >
          <i className="fas fa-signature"></i> Signature
        </button>
        <button
          style={{
            width: 208,
            // marginLeft: window.innerWidth <= 1500 ? "0px" : "12px",
            // marginTop: window.innerWidth <= 1500 ? "10px" : "0px",
          }}
          onClick={() => {
            //hideconsole.log(ids);
            handleDuplicateJob();
          }}
          type="button"
          disabled={ids.length != 1}
          className="btn btn-light-primary font-weight-bolder font-size-sm"
        >
          <i className="fas fa-copy"></i> Duplicate
        </button>
      </div>

      <ReceiptEditDailog
        onSubmit={EditReceiptDailogSubmit}
        show={openEditInoice}
        onHide={handleCloseEditReceipt}
        receiptData={ReciptData}
        onNewInvoice={() => customersUIProps.openReceiptCustomerDialog(ids[0])}
      />

      <JobAddDialog
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
          setJobToDuplicate(null);
        }}
        type="duplicate"
        clientData={jobToDuplicate}
      />

      <BulkReviewModal
        ids={ids}
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />

      <JobsignatureModal
        id={ids[0]}
        show={showsignatureModal}
        onHide={() => {
          setShowsignatureModal(false);
        }}
      />
    </div>
  );
}
