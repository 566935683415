import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import Fab from "@mui/material/Fab";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { useRef } from "react";
import moment from "moment";
import Tutorials from "../../../_metronic/layout/components/subheader/components/Tutorials";

export const SendieHelpOverlay = forwardRef((props, ref) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [newMessageAdded, setNewMessageAdded] = useState(false);
  const [hasUnreadSupportMessages, setHasUnreadSupportMessages] = useState(
    false
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isTyping, setIsTyping] = useState(false);

  // Get user from Redux store
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  // Expose methods for toggling the overlay
  useImperativeHandle(ref, () => ({
    openOverlay: () => setIsOverlayOpen(true),
    closeOverlay: () => {
      setIsOverlayOpen(false);
      setIsChatOpen(false);
    },
    toggleOverlay: () => setIsOverlayOpen((prev) => !prev),
  }));

  useEffect(() => {
    if (messagesEndRef.current && (newMessageAdded || isInitialLoad)) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      setNewMessageAdded(false);
      setIsInitialLoad(false); // Reset initial load flag
    }
  }, [newMessageAdded, isInitialLoad]);

  useEffect(() => {
    console.log("check sendie props", props);
  }, [props]);

  // Fetch support messages when chat is opened
  useEffect(() => {
    if (isChatOpen) {
      fetchSupportMessages();
    }
  }, [isChatOpen]);

  const fetchUnreadMessages = async () => {
    try {
      const response = await axios.get(
        "https://www.api.sendatradie.com/messages/getUserUnreadCount",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      if (response.data.success) {
        setHasUnreadMessages(response.data.data.unread_count > 0);
        setHasUnreadSupportMessages(
          response.data.data.support_unread_count > 0
        );
      }
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    }
  };

  useEffect(() => {
    fetchUnreadMessages(); // Initial fetch
    const interval = setInterval(fetchUnreadMessages, 10000); // Poll every 10 sec

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const fetchSupportMessages = async () => {
    // Don't fetch if user is typing
    if (isTyping) return;

    try {
      setIsLoading(true);
      setError(null);

      const response = await axios.get(
        "https://www.api.sendatradie.com/messages/getSupportMessages",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      if (response.data.success) {
        const formattedMessages = response.data.data.map((message) => ({
          text: message.message,
          sender: message.is_support_reply ? "support" : "user",
          timestamp: message.created_at,
        }));

        const sortedMessages = formattedMessages.sort((a, b) =>
          moment(a.timestamp).diff(moment(b.timestamp))
        );

        const groupedMessages = groupMessagesByDate(sortedMessages);
        setChatMessages(groupedMessages);

        // If it's the initial fetch (when the chat is first opened), set the flag
        if (isInitialLoad) {
          // We'll leave the flag as true to let the useEffect handle it
        }
      } else {
        setError("Failed to load messages");
      }
    } catch (err) {
      console.error("Error fetching support messages:", err);
      setError("Error loading messages. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleOverlay = () => {
    setIsOverlayOpen((prev) => !prev);
    if (isChatOpen) setIsChatOpen(false);
  };

  const markUserSupportMessagesAsRead = async () => {
    try {
      console.log("Marking messages as read for company:", user.company);
      const response = await axios.put(
        "https://www.api.sendatradie.com/messages/markUserSupportMessagesRead",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );
      console.log("Mark as read response:", response.data);

      // Force refresh unread counts immediately
      fetchUnreadMessages();

      return response;
    } catch (error) {
      console.error("Error marking messages as read:", error);
      return null;
    }
  };

  const handleOpenChat = () => {
    setIsChatOpen(true);
    setIsInitialLoad(true); // Set flag for initial load
    setHasUnreadMessages(false);
    setHasUnreadSupportMessages(false);
    markUserSupportMessagesAsRead();

    // Add a direct scroll command with a delay to ensure messages are loaded
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500); // 500ms delay to allow messages to load
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    // Create new message
    const userMessage = {
      text: newMessage,
      sender: "user",
      timestamp: moment().format(),
    };

    // Determine which date group this belongs to
    const messageDate = moment(userMessage.timestamp);
    const today = moment().startOf("day");
    const yesterday = moment()
      .subtract(1, "days")
      .startOf("day");

    let dateLabel;
    if (messageDate.isSame(today, "day")) {
      dateLabel = "Today";
    } else if (messageDate.isSame(yesterday, "day")) {
      dateLabel = "Yesterday";
    } else if (messageDate.isAfter(today.clone().subtract(6, "days"), "day")) {
      dateLabel = messageDate.format("dddd");
    } else {
      dateLabel = messageDate.format("DD/MM/YYYY");
    }

    // Update the chat messages state
    setChatMessages((prevMessages) => {
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[dateLabel]) {
        updatedMessages[dateLabel] = [];
      }
      updatedMessages[dateLabel].push(userMessage);
      return updatedMessages;
    });

    // Clear input field
    const messageToSend = newMessage;
    setNewMessage("");

    try {
      // Send message to backend
      const response = await axios.post(
        "https://www.api.sendatradie.com/messages/sendSupportMessage",
        {
          message: messageToSend,
          is_support_reply: false,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      setNewMessageAdded(true);

      if (!response.data.success) {
        setError("Failed to send message");
        // If send fails, you could revert the optimistic update here
      }
    } catch (err) {
      console.error("Error sending support message:", err);
      setError("Error sending message. Please try again.");
      // Could revert the optimistic update here
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const messageDate = moment.utc(message.timestamp).local();
      const today = moment().startOf("day");
      const yesterday = moment()
        .subtract(1, "days")
        .startOf("day");

      let dateLabel;
      if (messageDate.isSame(today, "day")) {
        dateLabel = "Today";
      } else if (messageDate.isSame(yesterday, "day")) {
        dateLabel = "Yesterday";
      } else if (
        messageDate.isAfter(today.clone().subtract(6, "days"), "day")
      ) {
        dateLabel = messageDate.format("dddd"); // Day name (e.g., "Monday")
      } else {
        dateLabel = messageDate.format("DD/MM/YYYY"); // Older messages
      }

      if (!acc[dateLabel]) {
        acc[dateLabel] = [];
      }

      acc[dateLabel].push(message);
      return acc;
    }, {});
  };

  // Update the polling useEffect to include isTyping in its dependency array
  useEffect(() => {
    let interval;
    if (isChatOpen) {
      // Only set up polling if not typing
      if (!isTyping) {
        interval = setInterval(fetchSupportMessages, 10000);
      } else {
        // Clear interval if typing starts
        if (interval) clearInterval(interval);
      }
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isChatOpen, isTyping]); // Add isTyping to dependency array

  return (
    <>
      {/* Help Button */}
      {!isOverlayOpen && (
        <Fab
          id="tour_open_help"
          color="#198754"
          style={{
            position: "fixed",
            bottom: "40px",
            left: "40px",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="tour_sendie_help"
          onClick={() => {
            setIsOverlayOpen(true);
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              className="col p-0"
              style={{ display: "flex" }}
              src={toAbsoluteUrl("/icons/sendie3.webp")}
            />

            {/* Pulsing Red Dot for Unread Support Messages */}
            {hasUnreadSupportMessages && (
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  backgroundColor: "#e81e44",
                  border: "2px solid white",
                  boxShadow: "0 0 10px rgba(232, 30, 68, 0.8)",
                  animation: "pulse 1.5s infinite ease-in-out",
                }}
              />
            )}
          </div>
        </Fab>
      )}

      {/* Dimmed Background */}
      {isOverlayOpen && (
        <div
          onClick={toggleOverlay}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim effect
            zIndex: 100,
          }}
        />
      )}

      {/* Help Drawer */}
      <div
        style={{
          position: "fixed",
          direction: "flex",
          bottom: isOverlayOpen ? "10px" : "-300px", // Hidden when not open
          left: isOverlayOpen ? "10px" : "-1000px",
          maxWidth: isChatOpen ? "500px" : "400px", // Wider when chat is open
          width: isChatOpen ? "95%" : "auto",
          height: isChatOpen ? "500px" : "auto", // Taller when chat is open
          backgroundColor: "white",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          transition: "all 0.3s ease-in-out",
          zIndex: 999,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        {/* Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
            borderBottom: "1px solid #ddd",
            backgroundColor: isChatOpen ? "rgb(92, 197, 147)" : "white",
            color: isChatOpen ? "white" : "inherit",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <h3 style={{ margin: 0 }}>
            {isChatOpen ? "Sendatradie Support" : "Sendie Help"}
          </h3>
          <button
            onClick={toggleOverlay}
            style={{
              background: "none",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              color: isChatOpen ? "white" : "inherit",
            }}
          >
            x
          </button>
        </div>

        {/* Content - Conditional rendering based on chat state */}
        {!isChatOpen ? (
          <div
            style={{
              padding: "15px",
              overflowY: "auto",
            }}
          >
            <p style={{ marginTop: "60px" }}>
              Welcome to the Sendie Help section! Here, we offer assistance and
              customer support to help you with any questions or issues.
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px", // Even spacing between buttons
                marginTop: "20px",
              }}
            >
              <Button
                href="/onboarding"
                className="btn btn-light tour_back_onboarding"
                style={{
                  padding: "10px 20px", // Consistent padding
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Back to Onboarding Page
              </Button>

              <Tutorials
                page={location.pathname}
                settings={props.settings}
                firstView={props.firstView}
                user={user}
                setIsOverlayOpen={setIsOverlayOpen}
              />

              <Button
                className="btn tour_support"
                style={{
                  backgroundColor: "rgb(92, 197, 147)",
                  padding: "10px 20px", // Consistent padding
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  textAlign: "center",
                  border: "none", // Optional: Remove Bootstrap's default border
                  color: "white", // Optional: Ensure contrast for readability
                }}
                onClick={handleOpenChat}
              >
                Support Chat
              </Button>
            </div>
          </div>
        ) : (
          // Chat interface

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%", // Use full height
              position: "relative",
              overflow: "hidden", // Prevent outer scrolling
            }}
          >
            {/* Chat messages area */}
            <div
              style={{
                flex: 1,
                overflowY: "auto",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                maxHeight: "calc(100% - 120px)", // Adjust based on your header/footer heights
                position: "absolute",
                top: "60px", // Height of your header
                bottom: "60px", // Height of your footer
                left: 0,
                right: 0,
              }}
            >
              {isLoading && Object.keys(chatMessages).length === 0 ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  Loading messages...
                </div>
              ) : error ? (
                <div
                  style={{ textAlign: "center", padding: "20px", color: "red" }}
                >
                  {error}
                </div>
              ) : Object.keys(chatMessages).length === 0 ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  No messages yet. Start the conversation!
                </div>
              ) : (
                Object.entries(chatMessages).map(([dateLabel, messages]) => (
                  <div key={dateLabel}>
                    {/* Date separator */}
                    <div
                      style={{
                        textAlign: "center",
                        margin: "10px 0",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "5px 10px",
                          borderRadius: "10px",
                          fontSize: "12px",
                          color: "#666",
                        }}
                      >
                        {dateLabel}
                      </span>
                    </div>

                    {/* Messages for this date */}
                    {messages.map((message, msgIndex) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            message.sender === "user"
                              ? "flex-end"
                              : "flex-start",
                          width: "100%",
                          marginBottom: "10px", // Add margin between messages
                        }}
                      >
                        <div
                          key={`${dateLabel}-${msgIndex}`}
                          style={{
                            backgroundColor:
                              message.sender === "user" ? "#DCF8C6" : "#f0f0f0",
                            borderRadius: "12px",
                            padding: "10px 15px",
                            maxWidth: "70%",
                            wordBreak: "break-word",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                            animation: "fadeIn 0.3s ease-in-out",
                          }}
                        >
                          <div
                            style={{
                              maxHeight: "200px",
                              overflowY: "auto",
                              overflowX: "hidden",
                              whiteSpace: "normal",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {message.text}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#888",
                              marginTop: "5px",
                              textAlign:
                                message.sender === "user" ? "right" : "left",
                            }}
                          >
                            {moment
                              .utc(message.timestamp)
                              .local()
                              .format("HH:mm")}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              )}

              <div ref={messagesEndRef} />
            </div>

            {/* Chat input area */}
            <form
              onSubmit={handleSendMessage}
              style={{
                display: "flex",
                padding: "10px",
                borderTop: "1px solid #ddd",
                backgroundColor: "#f9f9f9",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
              }}
            >
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onFocus={() => setIsTyping(true)}
                onBlur={() => setIsTyping(false)}
                placeholder="Type your message here..."
                style={{
                  flex: 1,
                  padding: "10px 15px",
                  border: "1px solid #ddd",
                  borderRadius: "20px",
                  marginRight: "10px",
                  outline: "none",
                }}
                disabled={isLoading}
              />
              <Button
                type="submit"
                className="btn"
                style={{
                  backgroundColor: "rgb(92, 197, 147)",
                  borderRadius: "20px",
                  padding: "8px 20px",
                  border: "none", // Optional: Remove Bootstrap's default border
                  color: "white", // Optional: Ensure contrast for readability
                }}
                disabled={isLoading || !newMessage.trim()}
              >
                Send
              </Button>
            </form>
          </div>
        )}
      </div>

      <style>
        {`
          @keyframes upDown {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px); /* Moves up */
            }
            100% {
              transform: translateY(0); /* Moves back to the original position */
            }
          }
          
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
          }
        `}
      </style>

      <style>
        {`
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 10px rgba(232, 30, 68, 0.8);
      }
      50% {
        transform: scale(1.4);
        opacity: 0.7;
        box-shadow: 0 0 20px rgba(232, 30, 68, 0.6);
      }
      100% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 10px rgba(232, 30, 68, 0.8);
      }
    }
  `}
      </style>
    </>
  );
});
