import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./ModalStyles.css";

/**
 * Modal component for editing partner profile details
 */
const ProfileEditModal = ({
  isOpen,
  onClose,
  onUpdate,
  partnerData,
  apiUrl,
}) => {
  const [formData, setFormData] = useState({
    firstName: partnerData?.firstName || "",
    lastName: partnerData?.lastName || "",
    email: partnerData?.email || "",
    phone: partnerData?.phone || "",
    companyName: partnerData?.companyName || "",
    website: partnerData?.companyWebsite || "",
    bsb: partnerData?.bsb || "",
    accountNumber: partnerData?.accountNumber || "",
    accountName: partnerData?.accountName || "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Convert form data to API format
  const prepareFormDataForApi = () => {
    const apiData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      companyName: formData.companyName,
      companyWebsite: formData.website,
      bsb: formData.bsb,
      accountNumber: formData.accountNumber,
      accountName: formData.accountName,
    };

    // Log the data being sent to API for debugging
    console.log("Sending to API:", apiData);
    return apiData;
  };

  // Submit form data to API
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccessMessage("");

    const token = localStorage.getItem("partner_token");
    if (!token) {
      setError("You are not authenticated. Please log in again.");
      toast.error("You are not authenticated. Please log in again.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(
        `${apiUrl}/register/partners/profile`,
        prepareFormDataForApi(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setSuccessMessage("Profile updated successfully!");
        toast.success("Profile updated successfully!");

        // Pass updated data back to parent component
        onUpdate(formData);

        // Close modal after a short delay
        setTimeout(() => {
          onClose();
        }, 1500);
      } else {
        const errorMsg =
          response.data.message ||
          "Failed to update profile. Please try again.";
        setError(errorMsg);
        toast.error(errorMsg);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      const errorMsg =
        error.response?.data?.message ||
        "An error occurred while updating your profile. Please try again.";
      setError(errorMsg);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div
        className="modal-container"
        style={{ display: "flex", flexDirection: "column", maxHeight: "90vh" }}
      >
        <div className="modal-header" style={{ flexShrink: 0 }}>
          <h3>Edit Profile</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div
          className="modal-body"
          style={{ flex: 1, overflow: "auto", paddingBottom: "20px" }}
        >
          {error && <div className="alert alert-danger">{error}</div>}

          {successMessage && (
            <div className="alert alert-success">{successMessage}</div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="website">Website</label>
              <input
                type="url"
                className="form-control"
                id="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="https://example.com"
              />
            </div>

            <h4 className="mt-4 mb-3">Banking Details</h4>

            <div className="form-group">
              <label htmlFor="bsb">BSB</label>
              <input
                type="text"
                className="form-control"
                id="bsb"
                name="bsb"
                value={formData.bsb}
                onChange={handleChange}
                placeholder="123-456"
                maxLength="7"
              />
              {/* <small className="form-text text-muted">Format: XXX-XXX</small> */}
            </div>

            <div className="form-group">
              <label htmlFor="accountNumber">Account Number</label>
              <input
                type="text"
                className="form-control"
                id="accountNumber"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
                maxLength="20"
              />
            </div>

            <div className="form-group">
              <label htmlFor="accountName">Account Name</label>
              <input
                type="text"
                className="form-control"
                id="accountName"
                name="accountName"
                value={formData.accountName}
                onChange={handleChange}
                maxLength="100"
              />
            </div>
          </form>
        </div>

        <div
          className="modal-footer"
          style={{
            flexShrink: 0,
            borderTop: "1px solid #dee2e6",
            padding: "1rem",
            backgroundColor: "white",
          }}
        >
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditModal;
