import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./PartnerDashboard.css";
import ProfileEditModal from "./ProfileEditModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);

// Import environment variables or config
const API_URL = process.env.REACT_APP_API_URL || "https://www.api.sendatradie.com";

// Add keyframes for animations
const styles = `
  @keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }
  @keyframes slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  @keyframes progressPulse {
    0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); }
    100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); }
  }
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`;

// Add style tag to head
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

/**
 * Partner Dashboard
 * A simplified dashboard showing referral stats for SendaTradie partners
 */
const PartnerDashboard = () => {
  const [partnerData, setPartnerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [generatingCode, setGeneratingCode] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const history = useHistory();

  const [referrals, setReferrals] = useState([]);
  const [referralCount, setReferralCount] = useState(0);
  const [activeReferrals, setActiveReferrals] = useState(0);
  const [paidReferrals, setPaidReferrals] = useState(0);
  const [monthlyCommission, setMonthlyCommission] = useState("0.00");
  const [yearlyCommission, setYearlyCommission] = useState("0.00");
  const [currentTier, setCurrentTier] = useState(1);
  const [nextTierTarget, setNextTierTarget] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [commissionHistory, setCommissionHistory] = useState([]);

  // Add animation state
  const [animate, setAnimate] = useState(false);

  const getTierInfo = (tier) => {
    switch (tier) {
      case 3:
        return {
          name: "Gold",
          commission: 20,
          color: "#FFD700",
          textColor: "#000",
          gradient: "linear-gradient(135deg, #FFD700, #FDB931)",
          shadow: "0 10px 20px rgba(253, 185, 49, 0.2)",
        };
      case 2:
        return {
          name: "Silver",
          commission: 15,
          color: "#C0C0C0",
          textColor: "#000",
          gradient: "linear-gradient(135deg, #E2E2E2, #C0C0C0)",
          shadow: "0 10px 20px rgba(192, 192, 192, 0.2)",
        };
      default:
        return {
          name: "Bronze",
          commission: 10,
          color: "#CD7F32",
          textColor: "#fff",
          gradient: "linear-gradient(135deg, #CD7F32, #A05A1F)",
          shadow: "0 10px 20px rgba(205, 127, 50, 0.2)",
        };
    }
  };

  useEffect(() => {
    // Check if user is authenticated as a partner
    const token = localStorage.getItem("partner_token");

    console.log(
      "Debug: Starting data fetch with token:",
      token ? "Token exists" : "No token"
    );

    if (!token) {
      // Redirect to login if no token
      history.push("/partner-login");
      return;
    }

    // Fetch partner data from API
    const fetchPartnerData = async () => {
      try {
        console.log("Debug: Fetching partner profile data...");

        // Get partner profile
        const profileResponse = await axios.get(
          `${API_URL}/register/partners/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Debug: Full API Response:", profileResponse.data);

        if (profileResponse.data.success && profileResponse.data.partner) {
          console.log("Debug: Partner ID:", profileResponse.data.partner.id);
          console.log(
            "Debug: Raw referrals data:",
            profileResponse.data.partner.referrals
          );
          console.log(
            "Debug: Referrals list:",
            profileResponse.data.partner.referrals?.list
          );
          console.log(
            "Debug: Referrals count:",
            profileResponse.data.partner.referrals?.count
          );

          // Map database field names to frontend field names if needed
          const mappedPartnerData = {
            ...profileResponse.data.partner,
            firstName:
              profileResponse.data.partner.first_name ||
              profileResponse.data.partner.firstName,
            lastName:
              profileResponse.data.partner.last_name ||
              profileResponse.data.partner.lastName,
            email: profileResponse.data.partner.email,
            phone: profileResponse.data.partner.phone,
            companyName:
              profileResponse.data.partner.company_name ||
              profileResponse.data.partner.companyName,
            companyWebsite:
              profileResponse.data.partner.company_website ||
              profileResponse.data.partner.companyWebsite,
            bsb: profileResponse.data.partner.bsb,
            accountNumber:
              profileResponse.data.partner.account_number ||
              profileResponse.data.partner.accountNumber,
            accountName:
              profileResponse.data.partner.account_name ||
              profileResponse.data.partner.accountName,
          };

          console.log("Debug: Mapped partner data:", mappedPartnerData);

          setPartnerData(mappedPartnerData);
          localStorage.setItem(
            "partner_data",
            JSON.stringify(mappedPartnerData)
          );
        }

        // Get or generate referral code
        const referralResponse = await axios.get(
          `${API_URL}/register/partners/referral-link`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (referralResponse.data.success) {
          // Create full sign-up URL with referral code for the main CRM platform
          const code = referralResponse.data.referralCode;

          if (code) {
            // Use current environment for testing, but in production use the main SendaTradie platform
            const platformUrl =
              process.env.NODE_ENV === "production"
                ? "https://platform.sendatradie.com"
                : window.location.origin;

            setReferralLink(`${platformUrl}/auth/signup?refer_code=${code}`);
            console.log(
              "Referral link generated:",
              `${platformUrl}/auth/signup?refer_code=${code}`
            );
          } else {
            console.error("No referral code returned from API");
            setReferralLink("Error: No referral code available");
          }

          // If there's an error message, show alert
          if (referralResponse.data.message) {
            alert(referralResponse.data.message);
          }
        }
      } catch (error) {
        console.error("Error fetching partner data:", error);
        // If unauthorized, redirect to login
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("partner_token");
          localStorage.removeItem("partner_data");
          history.push("/partner-login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerData();
  }, [history]);

  useEffect(() => {
    if (partnerData?.referrals) {
      console.log("Debug: Processing referrals data in second useEffect");
      console.log("Debug: Input referrals data:", partnerData.referrals);

      const {
        list,
        count,
        activeCount,
        paidCount,
        revenue,
        tier,
      } = partnerData.referrals;

      const currentTierInfo = getTierInfo(tier?.current || 1);

      // Calculate commission history from revenue data
      if (revenue?.history) {
        const commissionData = revenue.history.map((item) => ({
          month: new Date(item.date).toLocaleString("default", {
            month: "short",
          }),
          commission: (item.total * (currentTierInfo.commission / 100)).toFixed(
            2
          ),
        }));
        setCommissionHistory(commissionData);
      } else {
        // If no history data, show last 6 months based on monthly revenue
        const last6Months = [];
        for (let i = 5; i >= 0; i--) {
          const date = new Date();
          date.setMonth(date.getMonth() - i);
          last6Months.push({
            month: date.toLocaleString("default", { month: "short" }),
            commission: i === 0 ? monthlyCommission : "0.00", // Current month shows actual commission
          });
        }
        setCommissionHistory(last6Months);
      }

      console.log("Debug: Commission History:", commissionHistory);

      setReferrals(list || []);
      setReferralCount(count || 0);
      setActiveReferrals(activeCount || 0);
      setPaidReferrals(paidCount || 0);
      // Calculate commission based on tier percentage
      setMonthlyCommission(
        (
          (revenue?.monthly?.total || 0) *
          (currentTierInfo.commission / 100)
        ).toFixed(2)
      );
      setYearlyCommission(
        (
          (revenue?.yearly?.total || 0) *
          (currentTierInfo.commission / 100)
        ).toFixed(2)
      );
      setCurrentTier(tier?.current || 1);
      setNextTierTarget(tier?.nextTier || null);
      setProgressPercentage(tier?.progress || 0);

      console.log("Debug: State after updates:", {
        referralsLength: list?.length || 0,
        count,
        activeCount,
        paidCount,
        monthlyCommission: revenue?.monthly?.commission,
        yearlyCommission: revenue?.yearly?.commission,
        currentTier: tier?.current,
        nextTierTarget: tier?.nextTier,
        progressPercentage: tier?.progress,
      });
    }
  }, [partnerData]);

  useEffect(() => {
    // Trigger animations after data is loaded
    if (!loading) {
      setAnimate(true);
    }
  }, [loading]);

  const handleLogout = () => {
    localStorage.removeItem("partner_token");
    localStorage.removeItem("partner_data");
    history.push("/partner-login");
  };

  // Functions for profile edit modal
  const openProfileModal = () => setShowProfileModal(true);
  const closeProfileModal = () => setShowProfileModal(false);

  // Functions for password change modal
  const openPasswordModal = () => setShowPasswordModal(true);
  const closePasswordModal = () => setShowPasswordModal(false);

  // Function to update partner data after a successful profile edit
  const handleProfileUpdate = (updatedData) => {
    // Map frontend field names to match backend field names if needed
    const updatedPartnerData = {
      ...partnerData,
      // Frontend field names
      firstName: updatedData.firstName,
      lastName: updatedData.lastName,
      email: updatedData.email,
      phone: updatedData.phone,
      companyName: updatedData.companyName,
      companyWebsite: updatedData.website,
      bsb: updatedData.bsb,
      accountNumber: updatedData.accountNumber,
      accountName: updatedData.accountName,
      // Also update database field names to keep them in sync
      first_name: updatedData.firstName,
      last_name: updatedData.lastName,
      company_name: updatedData.companyName,
      company_website: updatedData.website,
      account_number: updatedData.accountNumber,
      account_name: updatedData.accountName,
    };

    console.log(
      "Updated partner data (after form submission):",
      updatedPartnerData
    );

    setPartnerData(updatedPartnerData);
    localStorage.setItem("partner_data", JSON.stringify(updatedPartnerData));
    closeProfileModal();
  };

  const copyReferralLink = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        toast.success("Referral link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Function to refresh referral data
  const refreshReferralData = async () => {
    const token = localStorage.getItem("partner_token");
    if (!token) {
      history.push("/partner-login");
      return;
    }

    setRefreshing(true);

    try {
      // Get partner profile with fresh data
      const profileResponse = await axios.get(
        `${API_URL}/register/partners/profile?nocache=${Date.now()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (profileResponse.data.success && profileResponse.data.partner) {
        console.log("Refreshed partner data:", profileResponse.data.partner);
        console.log(
          "Refreshed referrals:",
          profileResponse.data.partner.referrals?.list || []
        );

        // Map database field names to frontend field names if needed
        const mappedPartnerData = {
          ...profileResponse.data.partner,
          firstName:
            profileResponse.data.partner.first_name ||
            profileResponse.data.partner.firstName,
          lastName:
            profileResponse.data.partner.last_name ||
            profileResponse.data.partner.lastName,
          email: profileResponse.data.partner.email,
          phone: profileResponse.data.partner.phone,
          companyName:
            profileResponse.data.partner.company_name ||
            profileResponse.data.partner.companyName,
          companyWebsite:
            profileResponse.data.partner.company_website ||
            profileResponse.data.partner.companyWebsite,
          bsb: profileResponse.data.partner.bsb,
          accountNumber:
            profileResponse.data.partner.account_number ||
            profileResponse.data.partner.accountNumber,
          accountName:
            profileResponse.data.partner.account_name ||
            profileResponse.data.partner.accountName,
        };

        setPartnerData(mappedPartnerData);
        localStorage.setItem("partner_data", JSON.stringify(mappedPartnerData));

        if (profileResponse.data.partner.referrals?.list?.length > 0) {
          console.log(
            `Found ${profileResponse.data.partner.referrals.list.length} referrals!`
          );
        } else {
          console.log(
            "No referrals found. Make sure companies have signed up using your referral link."
          );
        }
      }
    } catch (error) {
      console.error("Error refreshing data:", error);
      alert("Failed to refresh data. Please try again.");
    } finally {
      setRefreshing(false);
    }
  };

  // Function to generate a new referral code
  const generateNewReferralCode = async () => {
    const token = localStorage.getItem("partner_token");
    if (!token) {
      history.push("/partner-login");
      return;
    }

    setGeneratingCode(true);

    try {
      // Call API endpoint to generate a new code
      const response = await axios.post(
        `${API_URL}/register/partners/generate-referral-code`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success && response.data.referralCode) {
        // Use current environment for testing, but in production use the main SendaTradie platform
        const platformUrl =
          process.env.NODE_ENV === "production"
            ? "https://platform.sendatradie.com"
            : window.location.origin;

        setReferralLink(
          `${platformUrl}/auth/signup?refer_code=${response.data.referralCode}`
        );
        alert("New referral code generated successfully!");
      } else {
        alert("Failed to generate new referral code. Please try again.");
      }
    } catch (error) {
      console.error("Error generating new referral code:", error);
      alert("An error occurred while generating your referral code.");
    } finally {
      setGeneratingCode(false);
    }
  };

  // Add chart data configurations
  const referralChartData = {
    labels: ["Paid Referrals", "Unpaid Referrals"],
    datasets: [
      {
        data:
          referralCount === 0
            ? [1]
            : [paidReferrals, referralCount - paidReferrals],
        backgroundColor:
          referralCount === 0
            ? ["#e4e6ef"] // Light gray for empty state
            : ["#3699ff", "#e4e6ef"],
        borderWidth: 0,
        cutout: "75%",
      },
    ],
  };

  const commissionChartData = {
    labels: commissionHistory.map((item) => item.month),
    datasets: [
      {
        label: "Monthly Commission",
        data: commissionHistory.map((item) => item.commission),
        borderColor: "#3699ff",
        backgroundColor: "rgba(54, 153, 255, 0.1)",
        tension: 0.4,
        fill: true,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#3699ff",
        pointBorderWidth: 2,
        pointRadius: 4,
      },
    ],
  };

  const commissionChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "#181c32",
        bodyColor: "#181c32",
        borderColor: "#e4e6ef",
        borderWidth: 1,
        padding: 12,
        boxPadding: 6,
        usePointStyle: true,
        callbacks: {
          label: (context) =>
            `Commission: $${parseFloat(context.parsed.y).toFixed(2)}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#e4e6ef",
          drawBorder: false,
        },
        ticks: {
          callback: (value) => `$${value}`,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    animation: {
      duration: 2000,
      easing: "easeInOutQuart",
    },
  };

  if (loading) {
    return (
      <div className="partner-dashboard-loading">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="partner-dashboard-container">
      <header className="partner-dashboard-header">
        <div className="header-logo">
          <img
            src="/media/logos/logo-dark.png"
            alt="SendaTradie Logo"
            className="partner-logo"
          />
        </div>
        {/* <div className="header-title">
          <h1>Partner Dashboard</h1>
        </div> */}
        <div className="header-actions">
          <div className="user-info">
            {partnerData && (
              <span className="welcome-message">
                Welcome, {partnerData.firstName} {partnerData.lastName}
              </span>
            )}
            <div className="user-controls">
              <button className="btn btn-primary" onClick={openProfileModal}>
                Edit Profile
              </button>
              <button className="btn btn-secondary" onClick={openPasswordModal}>
                Change Password
              </button>
              <button className="btn btn-danger" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="partner-dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <div className="card welcome-card mb-4">
              <div className="card-body">
                <h1 className="card-title">
                  Welcome to your Partner Dashboard
                </h1>
                <p className="card-text">
                  This is your exclusive partner portal where you can track your
                  referrals and commissions. We're excited to have you on board!
                </p>
              </div>
            </div>
          </div>
        </div>

        {(!partnerData?.bsb ||
          !partnerData?.accountNumber ||
          !partnerData?.accountName) && (
          <div className="row mb-4">
            <div className="col-md-12">
              <div
                style={{
                  background:
                    "linear-gradient(135deg, #fff4de 0%, #fff6e5 100%)",
                  padding: "16px 24px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxShadow: "0 2px 8px rgba(255, 159, 67, 0.1)",
                  border: "1px solid rgba(255, 159, 67, 0.2)",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    className="fas fa-info-circle"
                    style={{
                      color: "#ff9f43",
                      marginRight: "12px",
                      fontSize: "20px",
                    }}
                  ></i>
                  <span style={{ color: "#444", fontWeight: "500" }}>
                    Please update your banking details to receive commission
                    payments
                  </span>
                </div>
                <button
                  className="btn btn-light-warning"
                  onClick={openProfileModal}
                  style={{
                    padding: "8px 16px",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <i className="fas fa-pen"></i>
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Tier Progress Section with animations */}
        <div
          className="row"
          style={{ animation: animate ? "slideIn 0.5s ease-out" : "none" }}
        >
          <div className="col-md-12">
            <div
              className="card"
              style={{
                borderRadius: "16px",
                border: "none",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                background: getTierInfo(currentTier).gradient,
                overflow: "hidden",
                transition: "all 0.3s ease",
              }}
            >
              <div className="card-body p-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex align-items-center">
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: getTierInfo(currentTier).textColor,
                        marginRight: "1rem",
                      }}
                    >
                      {getTierInfo(currentTier).name} Partner
                    </span>
                    <span
                      style={{
                        background: "rgba(255, 255, 255, 0.2)",
                        padding: "6px 16px",
                        borderRadius: "20px",
                        fontSize: "1rem",
                        color: getTierInfo(currentTier).textColor,
                        fontWeight: "500",
                      }}
                    >
                      {getTierInfo(currentTier).commission}% Commission Rate
                    </span>
                  </div>
                  {nextTierTarget && (
                    <span
                      style={{
                        color: getTierInfo(currentTier).textColor,
                        fontSize: "1rem",
                        opacity: "0.9",
                      }}
                    >
                      {nextTierTarget - referralCount} more referrals to reach{" "}
                      <span
                        style={{
                          background: getTierInfo(currentTier + 1).gradient,
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundSize: "200% auto",
                          animation: "shine 2s linear infinite",
                          fontWeight: "700",
                          textShadow: `0 0 10px ${
                            getTierInfo(currentTier + 1).color
                          }40`,
                          padding: "0 4px",
                        }}
                      >
                        {getTierInfo(currentTier + 1).name}
                      </span>{" "}
                      ({getTierInfo(currentTier + 1).commission}% commission)
                    </span>
                  )}
                </div>
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "12px",
                    height: "12px",
                    overflow: "hidden",
                    boxShadow: "inset 0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  <div
                    style={{
                      width: `${progressPercentage}%`,
                      height: "100%",
                      background: "rgba(255, 255, 255, 0.9)",
                      transition: "width 1s ease-in-out",
                      animation: "progressPulse 2s infinite",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  />
                </div>
                <div
                  className="d-flex justify-content-between mt-2"
                  style={{
                    color: getTierInfo(currentTier).textColor,
                    opacity: "0.9",
                  }}
                >
                  <span>{referralCount} referrals</span>
                  <span>{nextTierTarget || "Max"} target</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats Cards Row with animations */}
        <div
          className="row mb-4"
          style={{
            animation: animate ? "slideIn 0.5s ease-out 0.2s" : "none",
            animationFill: "forwards",
          }}
        >
          <div className="col-md-3">
            <div
              className="card stats-card h-100"
              style={{
                borderRadius: "16px",
                border: "none",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                background: "#fff",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "default",
                opacity: 0,
                animation: animate
                  ? "fadeIn 0.5s ease-out 0.4s forwards"
                  : "none",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 6px 30px 0 rgba(34, 41, 47, 0.2)",
                },
              }}
            >
              <div className="card-body text-center p-4">
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    color: "#3699ff",
                    marginBottom: "1rem",
                  }}
                >
                  Total Referrals
                </h5>
                <div
                  style={{
                    height: "160px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Doughnut
                    data={referralChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          enabled: referralCount > 0,
                          backgroundColor: "#ffffff",
                          titleColor: "#181c32",
                          bodyColor: "#181c32",
                          borderColor: "#e4e6ef",
                          borderWidth: 1,
                          padding: 12,
                        },
                      },
                      animation: {
                        animateRotate: true,
                        animateScale: true,
                        duration: 1000,
                      },
                      cutout: "75%",
                    }}
                  />
                </div>
                <div className="mt-3">
                  <div
                    className="big-number"
                    style={{
                      fontSize: "2.5rem",
                      fontWeight: "700",
                      color: "#181c32",
                      lineHeight: "1",
                    }}
                  >
                    {referralCount}
                  </div>
                  <p
                    className="card-text"
                    style={{
                      color: "#a1a5b7",
                      fontSize: "0.95rem",
                      marginBottom: "0",
                    }}
                  >
                    {referralCount === 0
                      ? "No referrals yet"
                      : `${paidReferrals} paid of ${referralCount} total`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="card stats-card h-100"
              style={{
                borderRadius: "16px",
                border: "none",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                background: "#fff",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "default",
                opacity: 0,
                animation: animate
                  ? "fadeIn 0.5s ease-out 0.6s forwards"
                  : "none",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 6px 30px 0 rgba(34, 41, 47, 0.2)",
                },
              }}
            >
              <div className="card-body text-center p-4 mt-20">
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    color: "#3699ff",
                    marginBottom: "1.5rem",
                  }}
                >
                  Monthly Commission
                </h5>
                <div
                  className="big-number"
                  style={{
                    fontSize: "3.5rem",
                    fontWeight: "700",
                    color: "#181c32",
                    marginBottom: "1rem",
                    lineHeight: "1",
                  }}
                >
                  ${monthlyCommission}
                </div>
                <p
                  className="card-text"
                  style={{
                    color: "#a1a5b7",
                    fontSize: "0.95rem",
                    marginBottom: "0",
                  }}
                >
                  {getTierInfo(currentTier).commission}% of monthly
                  subscriptions
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="card stats-card h-100"
              style={{
                borderRadius: "16px",
                border: "none",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                background: "#fff",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "default",
                opacity: 0,
                animation: animate
                  ? "fadeIn 0.5s ease-out 0.8s forwards"
                  : "none",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 6px 30px 0 rgba(34, 41, 47, 0.2)",
                },
              }}
            >
              <div className="card-body text-center p-4 mt-20">
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    color: "#3699ff",
                    marginBottom: "1.5rem",
                  }}
                >
                  Annual Commission
                </h5>
                <div
                  className="big-number"
                  style={{
                    fontSize: "3.5rem",
                    fontWeight: "700",
                    color: "#181c32",
                    marginBottom: "1rem",
                    lineHeight: "1",
                  }}
                >
                  ${yearlyCommission}
                </div>
                <p
                  className="card-text"
                  style={{
                    color: "#a1a5b7",
                    fontSize: "0.95rem",
                    marginBottom: "0",
                  }}
                >
                  {getTierInfo(currentTier).commission}% of yearly subscriptions
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="card h-100"
              style={{
                borderRadius: "16px",
                border: "none",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                background: "#fff",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "default",
                opacity: 0,
                animation: animate
                  ? "fadeIn 0.5s ease-out 1s forwards"
                  : "none",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 6px 30px 0 rgba(34, 41, 47, 0.2)",
                },
              }}
            >
              <div className="card-body p-4 mt-5">
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    color: "#3699ff",
                    marginBottom: "1.5rem",
                  }}
                >
                  Commission Trend
                </h5>
                <div style={{ height: "200px" }}>
                  <Line
                    data={commissionChartData}
                    options={commissionChartOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Your Referral Link</h5>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={referralLink}
                    readOnly
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={copyReferralLink}
                    >
                      Copy
                    </button>
                  </div>
                </div>
                <p className="mt-2 text-muted">
                  Share this link with potential customers. When they sign up
                  using this link, you'll earn a{" "}
                  {getTierInfo(currentTier).commission}% commission on their
                  subscription.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title mb-0">Your Referrals</h5>
                  {/* <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      console.log("Debug: Current referrals state:", {
                        referrals,
                        referralCount,
                        activeReferrals,
                        paidReferrals,
                      });
                      refreshReferralData();
                    }}
                    disabled={refreshing}
                  >
                    {refreshing ? "Refreshing..." : "Refresh List"}
                  </button> */}
                </div>

                {console.log(
                  "Debug: Rendering referrals table, length:",
                  referrals.length
                )}
                {referrals.length === 0 ? (
                  <div className="text-center py-5">
                    <p className="mb-0 text-muted">
                      No referrals yet. Start sharing your referral link!
                    </p>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Email</th>
                          <th>Signup Date</th>
                          <th>Status</th>
                          <th>Package</th>
                          <th>Monthly Value</th>
                          <th>Commission (10%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {referrals.map((referral) => (
                          <tr key={referral.id}>
                            <td>{referral.name || "Unnamed Company"}</td>
                            <td>{referral.email}</td>
                            <td>
                              {referral.register_date
                                ? new Date(
                                    referral.register_date
                                  ).toLocaleDateString()
                                : "Unknown"}
                            </td>
                            <td>
                              <span className="badge badge-success">
                                Active
                              </span>
                            </td>
                            <td>{referral.pack_name || "Free"}</td>
                            <td>
                              {parseFloat(referral.monthly_price || 0) > 0
                                ? `$${parseFloat(
                                    referral.monthly_price || 0
                                  ).toFixed(2)}`
                                : "$0.00"}
                            </td>
                            <td>
                              {parseFloat(referral.monthly_price || 0) > 0
                                ? `$${(
                                    parseFloat(referral.monthly_price || 0) *
                                    0.1
                                  ).toFixed(2)}`
                                : "$0.00"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="partner-dashboard-footer">
        <div className="footer-content">
          <p className="copyright">
            &copy; {new Date().getFullYear()} SendaTradie. All rights reserved.
          </p>
          {/* <div className="footer-links">
            <a href="/partner-terms">Terms of Service</a>
            <a href="/partner-privacy">Privacy Policy</a>
            <a href="/partner-support">Support</a>
          </div> */}
        </div>
      </footer>

      {/* Profile Edit Modal */}
      {showProfileModal && (
        <ProfileEditModal
          isOpen={showProfileModal}
          onClose={closeProfileModal}
          onUpdate={handleProfileUpdate}
          partnerData={partnerData}
          apiUrl={API_URL}
        />
      )}

      {/* Password Change Modal */}
      {showPasswordModal && (
        <ChangePasswordModal
          isOpen={showPasswordModal}
          onClose={closePasswordModal}
          apiUrl={API_URL}
        />
      )}
    </div>
  );
};

export default PartnerDashboard;
