import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

export default class CustomDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarRef: React.createRef(),
      endRef: React.createRef(),
      open: false,
      date: [
        {
          startDate: moment().subtract(
            props.oneYearRange
              ? 365
              : props.oneWeekRange
              ? 7
              : props.oneDayRange
              ? 1
              : 30,
            "days"
          )._d,
          endDate: new Date(),
          key: "selection",
        },
      ],
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", this.onClickOnPage, true);
    // Initial call to set the date range
    console.log("click update");
    this.updateDateToSend();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.displayDates?.start &&
      (prevProps.displayDates.start !== this.props.displayDates.start ||
        prevProps.displayDates.end !== this.props.displayDates.end)
    ) {
      console.log("component update update");
      console.log(this.props.displayDates);
      this.setState(
        {
          date: [
            {
              startDate: moment(this.props.displayDates.start).toDate(),
              endDate: moment(this.props.displayDates.end).toDate(),
              key: "selection",
            },
          ],
        },
        this.updateDateToSend
      );
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.onClickOnPage, true);
  }

  updateDateToSend = () => {
    const { startDate, endDate } = this.state.date[0];
    console.log("update date to send", startDate, endDate);
    this.props.setDateToSend({
      start: moment(startDate).format("yyyy-MM-DD"),
      end: moment(endDate).format("yyyy-MM-DD"),
    });
  };

  handleOnclick = () => {
    this.setState(
      (prevState) => ({ open: !prevState.open }),
      () => {
        if (this.state.open) this.scrollToRight();
      }
    );
  };

  onClickOnPage = (e) => {
    if (
      this.state.calendarRef.current &&
      !this.state.calendarRef.current.contains(e.target)
    ) {
      this.setState({ open: false });
    }
  };

  scrollToRight() {
    this.state.endRef?.current?.scrollIntoView();
  }

  handleDateChange = (e) => {
    console.log("date change update", [e.selection]);
    this.setState({ date: [e.selection] }, () => {
      this.updateDateToSend();
      if (
        moment(e.selection.startDate).format("yyyy-MM-DD") !==
          moment(e.selection.endDate).format("yyyy-MM-DD") ||
        moment(e.selection.endDate).unix() ===
          moment()
            .endOf("day")
            .unix()
      ) {
        this.handleOnclick();
      }
    });
  };

  render() {
    const { startDate, endDate } = this.state.date[0];

    return (
      <div ref={this.state.calendarRef} className={this.props.className}>
        <small className="form-text text-muted">
          <b>Start Date</b> To <b>End Date</b>
        </small>
        <div
          onClick={this.handleOnclick}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <input
            style={{ cursor: "pointer", width: "100%" }}
            readOnly
            type="text"
            className="form-control"
            value={`${moment(startDate).format("DD-MM-yyyy")} / ${moment(
              endDate
            ).format("DD-MM-yyyy")}`}
          />
          <DateRangeIcon
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="d-flex flex-row">
          {this.state.open && (
            <DateRangePicker
              className="rdrDateRangePickerWrapperCustom"
              onChange={this.handleDateChange}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={this.state.date}
              direction="vertical"
              inputRanges={[]}
            />
          )}
        </div>
      </div>
    );
  }
}
