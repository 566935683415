// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteFileModal from "./DeleteFileModal.js";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import { toast } from "react-toastify";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { Switch } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CustomFieldsRenderer } from "../../../../CustomFields/CustomFieldsRenderer.js";
export function FileTabDialog({
  updateDiaryEntry,
  saveFiles,
  customer,
  actionsLoading,
  onHide,
  ids,
  setAllFileArr,
  AllFileArr,
  saveJob,
  id,
  isSubmitting,
  customFieldsArray,
  setCustomFieldsArray,
  customFieldsData,
  setCustomFieldsData,
  companyId,
}) {
  // const [file, setFile] = React.useState([])
  const userData = useSelector((state) => state.auth.user);
  const [fileName, setFileName] = React.useState("");
  const [isDeleteModal, setisDeleteModal] = React.useState(false);
  // In the useEffect that loads initial files
  React.useEffect(() => {
    if (customer) {
      const files = customer?.files;
      // Filter out empty, undefined, and "img" entries
      const filesArr = files
        ? files
            .split(",")
            .filter((file) => file && file.trim() !== "" && file !== "img")
        : [];

      if (filesArr.length) {
        setAllFileArr(filesArr);
      }
    }
  }, [customer]);

  // const handleUpload = (customer) => {
  //     requestFromServer.addfiles()
  // }

  const handleImgDelete = () => {
    const deleting = toast.loading(`Deleting file ${fileName}...`);

    requestFromServer
      .deleteFile(fileName, id)
      .then((response) => {
        setAllFileArr(AllFileArr.filter((file) => file != fileName));

        // Create diary entry for the backend
        const newDiaryEntry = {
          job_id: id,
          company_id: companyId, // Need to pass this as prop
          data: [
            {
              type: "file",
              content: [
                {
                  filename: fileName,
                  url: `https://storage.googleapis.com/platform-295512.appspot.com/${fileName}`,
                  status: "removed",
                },
              ],
              timestamp: new Date().toISOString(),
              user: userData.name || "System",
              saved: true,
            },
          ],
        };

        // Save diary entry to backend
        axios
          .post("https://www.api.sendatradie.com/jobs/jobdiary/add", newDiaryEntry)
          .then((response) => {
            if (response.status === 200) {
              console.log("File removal diary entry saved successfully");
              // Update local diary entries
              updateDiaryEntry(
                "file",
                [
                  {
                    filename: fileName,
                    url: `https://storage.googleapis.com/platform-295512.appspot.com/${fileName}`,
                    status: "removed",
                  },
                ],
                userData.name || "System",
                true
              );
            }
          })
          .catch((error) => {
            console.error("Error saving file removal diary entry:", error);
          });

        toast.update(deleting, {
          render: `File deleted.`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.update(deleting, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      });
  };

  const handleCloseModal = () => {
    setisDeleteModal(false);
    setFileName(null);
  };

  const tabCustomComponents = customFieldsArray?.filter(
    (item) => item.tab_id == 2 && item.active
  );

  //hideconsole.log("Job Tab components are", tabCustomComponents);

  const tabCustomComponentsSection = [];
  let temp = [];
  for (var i = 0; i < tabCustomComponents?.length; i++) {
    //hideconsole.log(tabCustomComponents);
    temp.push(tabCustomComponents[i]);
    if (temp.length == 3) {
      tabCustomComponentsSection.push(temp);
      temp = [];
    }
  }

  if (temp.length < 3) tabCustomComponentsSection.push(temp);

  //hideconsole.log("tabCustomComponentsSection is", tabCustomComponentsSection);

  return (
    <>
      <Formik
        initialValues={{ file: null, id: 5 }}
        onSubmit={(values) => {
          if (!values.file || values.file.length === 0) {
            console.log("No file selected");
            return;
          }

          saveFiles(values);
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form>
                <div className="row">
                  <div className="form-group col-lg-8">
                    <label htmlFor="file">File Upload</label>
                    <div className="d-flex align-items-center">
                      {/* Hidden File Input */}
                      <input
                        id="file"
                        name="file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files);
                          submitForm(); // Automatically submit the form on file selection
                        }}
                        multiple
                      />

                      {/* Single Button */}
                      <label
                        htmlFor="file"
                        className="btn btn-light-primary mb-0"
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        Add Image
                      </label>
                    </div>

                    {/* File Count Display */}
                    {/* <div className="mt-2">
                      {values.file && values.file.length > 0
                        ? `${values.file.length} file${
                            values.file.length > 1 ? "s" : ""
                          } chosen`
                        : "No files chosen"}
                    </div> */}
                  </div>
                </div>

                <CustomFieldsRenderer
                  tabCustomComponents={tabCustomComponents}
                  tabCustomComponentsSection={tabCustomComponentsSection}
                  customFieldsArray={customFieldsArray}
                  setCustomFieldsArray={setCustomFieldsArray}
                  customFieldsData={customFieldsData}
                  setCustomFieldsData={setCustomFieldsData}
                />
              </Form>
              <div className="row mt-5">
                {AllFileArr?.filter(
                  (item) => item && item.trim() !== "" && item !== "img"
                ).map((item, index) => (
                  <div key={index} className="col-4">
                    <div
                      style={{
                        position: "absolute",
                        top: "-14px",
                        left: "2px",
                      }}
                    >
                      <CancelIcon
                        fontSize="large"
                        onClick={() => {
                          setFileName(item);
                          setisDeleteModal(true);
                        }}
                      />
                    </div>
                    <img
                      src={`https://storage.googleapis.com/platform-295512.appspot.com/${item}`}
                      alt="img"
                      style={{ maxHeight: "130px", maxWidth: "100%" }}
                    />
                  </div>
                ))}
              </div>
              <DeleteFileModal
                show={isDeleteModal}
                handleClose={handleCloseModal}
                id={fileName}
                handleSubmit={handleImgDelete}
              />
            </Modal.Body>
          </>
        )}
      </Formik>
    </>
  );
}
