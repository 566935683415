import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomBootstrapTable from "../../../../../../_metronic/_helpers/CustomBootstrapTable";
import { EditModal } from "./EditModal";

import axios from "axios";
import { filter } from "lodash";
var moment = require("moment");
export function CustomerReccurringJobs() {
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const userData = useSelector((state) => state.auth.user);
  const [columns, setColumns] = useState([]);
  const [ids, setIds] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [id, setId] = useState(null);
  const [editedFlag, setEditedFlag] = useState(1);
  const [rowData, setRowData] = useState(null);
  const [recurringEndValue, setRecurringEndValue] = useState("");
  const [endNumber, setEndNumber] = useState(null);
  const [repeatEvery, setRepeatEvery] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [selectedRepeatOption, setSelectedRepeatOption] = useState({
    value: "1",
    label: "Day",
  });
  const repeatOptions = [
    { value: "1", label: "Day(s)" },
    { value: "2", label: "Week(s)" },
    { value: "3", label: "Month(s)" },
  ];

  useEffect(() => {
    axios
      .get("https://www.api.sendatradie.com/jobs/getReccurringJobs")
      .then((res) => {
        const formattedData = res.data.result.map((row) => {
          const repeatOption =
            row.repeat_option === 1
              ? "Day(s)"
              : row.repeat_option === 2
              ? "Week(s)"
              : "Month(s)";

          // Add a new field for formatted repeat info
          row.repeat_info = `${row.repeat_every} ${repeatOption}`;
          return row;
        });
        setColumns([
          {
            text: "ID",
            dataField: "id",
          },
          {
            text: "Name",
            dataField: "job_name",
          },
          {
            text: "Repeat Every",
            dataField: "repeat_info",
          },
          {
            text: "Ends On",
            formatter: (cellContent, row) => {
              if (
                (row.end_option == 2 && row.end_datetime == null) ||
                (row.end_option == 3 && row.end_after_occurances < 1)
              ) {
                return "Over";
              }
              if (row.end_option === 1) {
                return "Never";
              } else if (row.end_option === 2) {
                return row.end_datetime;
              } else {
                // const jobDateTimeUTC = row.job_datetime;
                // const jobDateTimeLocal = moment(jobDateTimeUTC).tz(
                //   userData.time_zone
                // );
                // let future_datetime = moment.utc();
                // if (row.repeat_option == 1) {
                //   future_datetime = moment(jobDateTimeLocal)
                //     .add(row.repeat_every, "days")
                //     .format("yyyy-MM-DD HH:mm:ss");
                // } else if (row.repeat_option == 2) {
                //   future_datetime = moment(jobDateTimeLocal)
                //     .add(7 * row.repeat_every, "days")
                //     .format("yyyy-MM-DD HH:mm:ss");
                // } else if (row.repeat_option == 3) {
                //   future_datetime = moment(jobDateTimeLocal)
                //     .add(row.repeat_every, "months")
                //     .format("yyyy-MM-DD HH:mm:ss");
                // }
                // return future_datetime;

                return `${row.end_after_occurances} occurrences left`;
              }
            },
            dataField: "end_option",
          },
        ]);
        setRawData(formattedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [editedFlag]);

  useEffect(() => {
    axios
      .get("https://www.api.sendatradie.com/jobs/getReccurringJobs")
      .then((res) => {
        setColumns([
          {
            text: "ID",
            dataField: "id",
          },
          {
            text: "Name",
            dataField: "job_name",
          },
          {
            text: "Repeat Every",
            formatter: (cellContent, row) => {
              // getting the 'repeat_option' and 'repeat_every' from the row
              const repeatOption =
                row.repeat_option === 1
                  ? "Day(s)"
                  : row.repeat_option === 2
                  ? "Week(s)"
                  : "Month(s)";
              // returning the formatted string
              return `${row.repeat_every} ${repeatOption}`;
            },
            dataField: "repeat_every",
          },
          {
            text: "Ends On",
            formatter: (cellContent, row) => {
              if (
                (row.end_option == 2 && row.end_datetime == null) ||
                (row.end_option == 3 && row.end_after_occurances < 1)
              ) {
                return "Over";
              }
              if (row.end_option === 1) {
                return "Never";
              } else if (row.end_option === 2) {
                return row.end_datetime;
              } else {
                const jobDateTimeUTC = row.job_datetime;
                const jobDateTimeLocal = moment(jobDateTimeUTC).tz(
                  userData.time_zone
                );
                let future_datetime = moment.utc();
                if (row.repeat_option == 1) {
                  future_datetime = moment(jobDateTimeLocal)
                    .add(row.repeat_every, "days")
                    .format("yyyy-MM-DD HH:mm:ss");
                } else if (row.repeat_option == 2) {
                  future_datetime = moment(jobDateTimeLocal)
                    .add(7 * row.repeat_every, "days")
                    .format("yyyy-MM-DD HH:mm:ss");
                } else if (row.repeat_option == 3) {
                  future_datetime = moment(jobDateTimeLocal)
                    .add(row.repeat_every, "months")
                    .format("yyyy-MM-DD HH:mm:ss");
                }
                return future_datetime;
              }
            },
            dataField: "end_option",
          },
        ]);
        setRawData(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  return (
    <>
      <EditModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        id={ids[0]}
        data={data}
        editedFlag={editedFlag}
        setEditedFlag={setEditedFlag}
        recurringEndValue={recurringEndValue}
        setRecurringEndValue={setRecurringEndValue}
        endNumber={endNumber}
        setEndNumber={setEndNumber}
        rowData={rowData}
        repeatEvery={repeatEvery}
        setRepeatEvery={setRepeatEvery}
        selectedRepeatOption={selectedRepeatOption}
        setSelectedRepeatOption={setSelectedRepeatOption}
        repeatOptions={repeatOptions}
        endDateTime={endDateTime}
        setEndDateTime={setEndDateTime}
      />
      <button
        type="button"
        className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
        style={{ width: 208 }}
        disabled={ids.length != 1}
        onClick={() => {
          const filteredData = data.filter((item) => item.id == ids[0]);
          setRowData(filteredData);
          //hideconsole.log("rowdata is", filteredData);
          setRecurringEndValue(filteredData[0].end_option);
          setEndNumber(filteredData[0].end_after_occurances);
          setRepeatEvery(filteredData[0].repeat_every);
          setSelectedRepeatOption(
            repeatOptions.find(
              (item) => item.value == filteredData[0].repeat_option
            ) || { value: "1", label: "Day(s)" }
          );
          setEndDateTime(filteredData[0].end_datetime);
          setShowEditModal(true);
        }}
      >
        <i className="fa fa-edit"></i> Edit
      </button>
      <CustomBootstrapTable
        data={data}
        columns={columns}
        ids={ids}
        setIds={setIds}
        isSelectable
      />
    </>
  );
}
