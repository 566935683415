import React, { useEffect, useContext } from "react";
import Draggable from "react-draggable";
import { Dialer } from "./Dialer.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { CallInProgress } from "./CallInProgress.js";
import { ContactList } from "./ContactList.js";
import { DeviceContext } from "./DeviceContext.js";
import * as requestFromServer from "../../modules/Message/MessageCrud/MessageCrud.js"

const Device = require("@twilio/voice-sdk").Device;

export function Phone({ setFlag }) {
  const [callLoading, setCallLoading] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const windowHeight = window.innerHeight;
  const windowWidth = 360;
  const windowHeightMiddle = (windowHeight - windowWidth) / 2;
  const [number, setNumber] = useState("");
  const [inCallNumber, setIncallNumber] = useState("");
  const userData = useSelector((state) => state.auth);
  // //hideconsole.log("userData is", userData);
  const [callOnGoing, setCallOnGoing] = useState(false);
  const [callState, setCallState] = useState(null);
  const [callEnd, setCallEnd] = useState(false);
  const [myDevice, setMyDevice] = useState(null);
  const [isTransfer, setIsTransfer] = useState(false);
  const [callSID, setCallSID] = useState(null);
  const [conferenceName, setConferenceName] = useState(
    "Conf-" + userData.user.company_name + "-" + new Date().getTime().toString()
  );
  const [numberList, setNumberList] = useState([]);
  const [showInCallDialer, setShowInCallDialer] = React.useState(false);

  const mountDeviceAudio = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });

    myDevice?.audio
      .setAudioConstraints({ echoCancellation: true })
      .then(() => {
        myDevice.audio
          .setInputDevice("default")
          .then(() => {
            //hideconsole.log("successfully set device audio");
            //hideconsole.log("input device is", myDevice.audio.inputDevice);
          })
          .catch((err) => {
            //hideconsole.log("Got error while setting device audio", err);
          });
      })
      .catch((err) => console.log("error in setting input", err));
  };

  const unmountDeviceAudio = async () => {
    if (myDevice && myDevice.audio) {
      myDevice.audio.unsetInputDevice().then(() => {
        console.info("Audio device successfully unmounted!");
      });
    }
  };

  const loadDevice = async () => {
    const callDevice = async () => {
      try {
        const response = await requestFromServer.getTwilioToken();
  
        if (!response.data.token) {
          console.error("Failed to fetch Twilio token from the backend.");
          return;
        }
  
        const token = response.data.token;

        console.log("check token", token)
  
        const deviceTemp = new Device(token, {
          debug: true,
          answerOnBridge: true,
          codecPreferences: ["opus", "pcmu"],
        });
  
        console.log("Device initialized:", deviceTemp);
        return deviceTemp;
      } catch (err) {
        console.error("Error initializing device:", err);
      }
    };
  
    try {
      const dev = await callDevice();
      if (!dev) {
        console.error("Device initialization failed.");
        return;
      }
  
      const handleSuccessfulRegistration = () => {
        console.log("The device is ready to dial.");
      };
  
      dev.on("tokenWillExpire", async () => {
        try {
          console.log("Token will expire, fetching new token...");
          const response = await requestFromServer.getTwilioToken();
  
          if (!response.data.token) {
            console.error("Failed to fetch new Twilio token from the backend.");
            return;
          }
  
          const updatedToken = response.data.token;
          dev.updateToken(updatedToken);
          console.log("Device token updated.");
        } catch (err) {
          console.error("Error renewing token:", err);
        }
      });
  
      dev.on("error", async (error) => {
        console.error("Device error:", error);
        if (error.code === 31005) {
          console.log("Connection error occurred. Trying to reconnect...");
          await loadDevice(); 
        }
      });
  
      if (dev) {
        dev.on("registered", handleSuccessfulRegistration);
        if (dev.state !== "registered") {
          dev.register();
        }
      }

      setMyDevice(dev);
    } catch (err) {
      console.error("Error in loadDevice:", err);
    }
  };
  

  useEffect(() => {
    if (callState) {
      const handleDisconnect = () => {
        //hideconsole.log("call set completed");
        // myDevice.destroy();
      };
      callState.on("disconnect", handleDisconnect);

      return () => {
        callState.removeListener("disconnect", handleDisconnect);
      };
    }
  }, [callState]);

  useEffect(async () => {
    await loadDevice();

    //hideconsole.log("call device is", myDevice);
    await mountDeviceAudio();
  }, []);

  useEffect(() => {
    //hideconsole.log("showContacts pressed");
  }, [showContacts]);

  function isValidPhoneNumber(phoneNumber) {
    // Phone number regex
    let allCountryRegex = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
    return allCountryRegex.test(phoneNumber);
  }

  const handleDial = async () => {
    if (!isValidPhoneNumber(number)) {
      toast.error("Not a valid phone number!");
      return;
    }
    setCallLoading(true);
    if (!myDevice) {
      console.error("Twilio device not initialized yet.");
      return;
    }

    numberList.push(number);
    setNumberList(numberList);

    // const device = await callDevice();

    const params = {
      To: number.startsWith("0") ? number.replace("0", "61") : number,
    };
    let call = await myDevice.connect({ params });
    setCallState(call);
    //hideconsole.log("callSid is", call.parameters);

    call.on("ringing", (hasEarlyMedia) => {
      //hideconsole.log("Ringing", number);
    });
    call.on("accept", (call) => {
      // toast.success("Ringing");
      setCallOnGoing(true);
    });
    call.on("connect", (call) => {
      // toast.success("jinga-lala");
    });

    call.on("disconnect", (call) => {
      setCallLoading(false);
      setCallState(null);
      setCallOnGoing(false);
      setCallEnd(true);
      setNumberList([]);
      setNumber("");
      // toast.success("Call Disconnected");
    });
    call.on("error", (error) => {
      toast.error("Failed to make call");
    });
    call.on("rejected", (call) => {
      toast.error(number, "rejected call");
    });
    //hideconsole.log("call direction is", call.direction);
    //hideconsole.log("call status is", call.status());
  };

  const handleTransfer = async () => {};

  useEffect(() => {
    if (isTransfer) {
      handleTransfer();
    }
  }, [isTransfer]);

  const handleContactSelect = (e) => {
    //hideconsole.log("contact selected", e);
    if (!callOnGoing) {
      setNumber(e);
    } else {
      setIncallNumber(e);
      setShowInCallDialer(true);
    }

    setShowContacts(false);
  };

  return (
    <Draggable defaultPosition={{ x: 20, y: windowHeightMiddle }}>
      <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          border: "1px solid #ddd",
          borderRadius: "10px",
          width: "360px",
          height: "600px",
          overflowY: "auto",
          zIndex: "1000",
        }}
      >
        <div style={{ width: "100%", height: "40px" }}>
          <div style={{ position: "relative" }}>
            {!callState && (
              <IconButton
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#fff",
                  background: "orange",
                  padding: "0.1px", // Adjust padding to reduce the size of the button
                }}
                onClick={async () => {
                  setCallEnd(false);
                  setFlag(false);
                  await unmountDeviceAudio();
                }}
              >
                <CloseIcon fontSize="small" />{" "}
                {/* Use fontSize prop to adjust the size of the icon */}
              </IconButton>
            )}
          </div>
        </div>

        {!showContacts ? (
          <>
            {!callOnGoing && (
              <Dialer
                number={number}
                setNumber={setNumber}
                handleDial={handleDial}
                callLoading={callLoading}
                setCallLoading={setCallLoading}
                showContacts={showContacts}
                setShowContacts={setShowContacts}
              />
            )}
            {callOnGoing && (
              <CallInProgress
                myDevice={myDevice}
                number={number}
                callState={callState}
                setCallState={setCallState}
                callOnGoing={callOnGoing}
                setCallOnGoing={setCallOnGoing}
                setCallEnd={setCallEnd}
                setIsTransfer={setIsTransfer}
                conferenceName={conferenceName}
                numberList={numberList}
                setNumberList={setNumberList}
                showContacts={showContacts}
                setShowContacts={setShowContacts}
                inCallNumber={inCallNumber}
                setIncallNumber={setIncallNumber}
                showInCallDialer={showInCallDialer}
                setShowInCallDialer={setShowInCallDialer}
              />
            )}
          </>
        ) : (
          <ContactList
            showContacts={showContacts}
            setShowContacts={setShowContacts}
            callOnGoing={callOnGoing}
            handleContactSelect={handleContactSelect}
          />
        )}
      </div>
    </Draggable>
  );
}
