// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useRef, useMemo, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../../_metronic/_partials/controls";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import Select, { components } from "react-select";
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
import moment from "moment";
import { convertNeSwToNwSe } from "google-map-react";
import { v4 as uuidv4 } from "uuid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Switch } from "@material-ui/core";
import { ContentCutOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CustomFieldsRenderer } from "../../../../CustomFields/CustomFieldsRenderer";
import { AddEditRowModal } from "../../../../ManageInventory/AddEditModal/AddEditRowModal";
import { getSendInventory } from "../../../../ManageInventory/ManageInventoryCrud/ManageInventoryCrud";

export function JobEditForm({
  jobId,
  dateCreated,
  setIsGsmCOuntComment,
  setmsgCountAsNumComment,
  IsGsmCOuntComment,
  setCommentCount,
  setLeadsListValue,
  leadsListValue,
  leadsList,
  setAppointmentTime,
  appointmentTime,
  setTradieComment,
  tradieComment,
  setMoney,
  money,
  customer,
  actionsLoading,
  setchangePercentageData,
  changePercentageData,
  sendReview,
  setSendReview,
  partsData,
  setPartsData,
  partsOptions,
  jobStatusOptions,
  setJobStatus,
  jobStatus,
  payBy,
  setPayBy,
  includeTax,
  setIncludeTax,
  workerOptions,
  workers,
  setWorkers,
  jobTypeOptions,
  jobTypes,
  setJobTypes,
  sendSMS,
  setSendSMS,
  appointment,
  setAppointment,
  additionalInfo,
  setAdditionalInfo,
  partsBelongOptions,
  showModifyPercentage,
  setShowModifyPercentage,
  modifyPercentageData,
  setModifyPercentageData,
  paymentApprovalCode,
  setPaymentApprovalCode,
  isSubmitting,
  creditCardFee,
  setCreditCardFee,
  payBy2,
  splitPayment,
  money2,
  includeTax2,
  setSplitPayment,
  setPayBy2,
  setIncludeTax2,
  setMoney2,
  tax,
  setTax,
  creditCardFeeAmount,
  tax2,
  customFieldsArray,
  setCustomFieldsArray,
  customFieldsData,
  setCustomFieldsData,
  closedBy,
  currentListId,
  setNewListId,
  listFromTrello,
  setPartsOptions,
}) {
  const [listOptions, setListOptions] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const { rolePermissions } = useSelector((state) => state.rightSettings);
  const userData = useSelector((state) => state.auth.user);
  const [invoiceExist, setInvoiceExist] = useState(false);
  const [showNewPartModal, setShowNewPartModal] = useState(false);
  const [currentInventory, setCurrentInventory] = useState({ data: [] });
  const [inventoryOptions, setInventoryOptions] = React.useState([]);
  const [title, setTitle] = useState([]);

  const user = useSelector((state) => state.auth.user)

  // Todo check partsData
  useEffect(() => {
    console.log("parts data", inventoryOptions)
  }, [inventoryOptions])

  const addNewPart = async (newPart) => {
    try {
      const updatedInventory = {
        ...currentInventory,
        data: [...currentInventory.data, newPart],
      };
      setCurrentInventory(updatedInventory);

      const newOption = { value: newPart.id, label: newPart.Name };
      setInventoryOptions((prevOptions) => [...prevOptions, newOption]);

      await updateInventoryInBackend(updatedInventory);

      toast.success(`New part added successfully`, {
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      console.error("Error adding new part:", error);
    } finally {
      getData();
    }
  };

  const updateInventoryInBackend = async (updatedInventory) => {
    try {
      await axios.post("https://www.api.sendatradie.com/company/sendInventory", {
        inventory: JSON.stringify(updatedInventory),
      });
    } catch (error) {
      throw error;
    }
  };

  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>No parts found.</span>
        <button
          style={{
            marginLeft: "10px",
            fontSize: "12px",
            background: "none",
            border: "none",
            color: "blue",
            cursor: "pointer",
          }}
          onMouseDown={(e) => {
            // Prevent default to avoid the select closing
            e.preventDefault();
            e.stopPropagation();
            setShowNewPartModal(true);
          }}
        >
          + Add New Part
        </button>
      </div>
    </components.NoOptionsMessage>
  );

  useEffect(() => {
    axios
      .post("https://www.api.sendatradie.com/jobs/getInvoiceExist", { jobId: jobId })
      .then((response) => {
        console.log("check invoice exist", response.data.data);
        setInvoiceExist(response.data.data);
      })
      .catch((error) => console.error("Error fetching invoice:", error));
  }, [jobId]);

  useEffect(() => {
    if (selectedList) {
      // console.log("selectedList", selectedList);
      setNewListId(selectedList.value);
      // console.log("selected list id", selectedList.value);
    }
  }, [selectedList]);

  useEffect(() => {
    let isMounted = true;

    if (listFromTrello) {
      console.log(listFromTrello);
      setListOptions(
        listFromTrello.map((list) => ({
          value: list.id,
          label: list.name,
          company_id: list.company_id,
        }))
      );
    } else {
      axios
        .post("https://www.api.sendatradie.com/jobs/getLists")
        .then((response) => {
          if (isMounted) {
            setListOptions(
              response.data.data.map((list) => ({
                value: list.id,
                label: list.name,
                company_id: list.company_id,
              }))
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching lists:", error);
          toast.error("Failed to load lists");
        });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getData = () => {
    getSendInventory()
      .then((response) => {
        if (response.data.result.length) {
          //hideconsole.log(response.data.result[0]);
          var tempInventoryData = JSON.parse(response.data.result[0]?.data);
          tempInventoryData.data = tempInventoryData?.data?.filter(
            (d) => d.inactive != 1
          );
          setInventoryOptions(tempInventoryData.data)
          setCurrentInventory(tempInventoryData);
          setTitle(tempInventoryData.title);
          setTimeout(() => {
          }, 10);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);


  const filteredLists = useMemo(() => {
    return listOptions?.filter((list) => list.company_id === user.company_id);
  }, [listOptions, user.company_id]);

  useEffect(() => {
    let isMounted = true;

    const currentList = filteredLists.find(
      (list) => list.value === currentListId
    );

    if (isMounted) {
      setSelectedList(currentList);
    }

    return () => {
      isMounted = false;
    };
  }, [currentListId, filteredLists]);

  const sendReviewOptions = [
    { value: 0, label: "None" },
    { value: 1, label: "Email" },
    { value: 2, label: "SMS" },
  ];

  const payByOptions = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Credit Card" },
    { value: 3, label: "Transfer" },
  ];

  const ref = useRef(null);
  function handleJobStatus(event) {

    setJobStatus(event.value);
  }
  function handleJobTypes(event) {
    setJobTypes(event ? event.map((item) => item.value).toString() : "");
  }
  function handlePayBy(event) {
    //s //hideconsole.log("event", event.value);
    setPayBy(event.value);
    if (event.value == 1 && includeTax == true) {
      setIncludeTax(false);
      setMoney((prevMoney) => (prevMoney / (1 + tax / 100)).toFixed(4));
    }
  }

  function handlePayBy2(event) {
    //s //hideconsole.log("event", event.value);
    setPayBy2(event.value);
    if (event.value == 1 && includeTax2 == true) {
      setIncludeTax2(false);
      setMoney2((prevMoney) => (prevMoney / (1 + tax / 100)).toFixed(4));
    }
  }

  const handleChangeWorker = (e) => {
    setWorkers(e ? e.map((item) => item.value).toString() : "");
    //hideconsole.log(e);
    //hideconsole.log(partsData);
    var workersID = e
      ? e.map((item) => {
          return item.value;
        })
      : [];
    workersID.push(0);
    if (partsData) {
      const newPartsData = partsData.filter((item) =>
        workersID.includes(item.partsBelong)
      );
      setPartsData(newPartsData);
    }

    let AddValueForCP = [];
    e &&
      e.length &&
      e.map((data) => {
        //hideconsole.log(data);
        AddValueForCP = [
          ...AddValueForCP,
          { name: data.label, percentage: null },
        ];
      });
    ////hideconsole.log("435465htgvhgnhmgu", AddValueForCP);
    setchangePercentageData(AddValueForCP);
    // let temOptions = e;
    // temOptions.unshift({ value: 0, label: "Company" });

    // //hideconsole.log(temOptions);
  };

  const handleChangeLead = (e) => {
    //  e.persist();
    //hideconsole.log(e);
    //hideconsole.log(leadsList);
    setLeadsListValue(
      e
        ?.map((item) => {
          return item.value;
        })
        ?.toString()
    );
    //ustomerData({
    //   ...customer,
    //   leads_id: ALLDataleadsListValue ? ALLDataleadsListValue : null,
    // });
  };

  const handleSendReview = (e) => {
    setSendReview(e.value);
  };

  const handleChangePercentage = (e, index) => {
    let AlldataPercentage = [...changePercentageData];
    AlldataPercentage[index] = {
      ...AlldataPercentage[index],
      percentage: parseFloat(e.target.value),
    };
    //hideconsole.log(AlldataPercentage);
    setchangePercentageData(AlldataPercentage);
  };

  const handleAppointment = (e) => {
    setAppointment(e.target.checked);
  };
  const handleSendSMS = (e) => {
    setSendSMS(e.target.checked);
  };

  const handleIncludeTax = (e) => {
    const isChecked = e.target.checked;
    setIncludeTax(isChecked);

    setMoney((prevMoney) =>
      isChecked
        ? (prevMoney * (1 + tax / 100)).toFixed(4)
        : (prevMoney / (1 + tax / 100)).toFixed(4)
    );
  };
  const handleIncludeTax2 = (e) => {
    const isChecked = e.target.checked;
    setIncludeTax2(isChecked);

    setMoney2((prevMoney) =>
      isChecked
        ? (prevMoney * (1 + tax / 100)).toFixed(4)
        : (prevMoney / (1 + tax / 100)).toFixed(4)
    );
  };

  const handleChangeMoney = (e) => {
    setMoney(e.target.value);
  };

  const isGSMAlphabet = (text) => {
    var regexp = new RegExp(
      "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
    );

    return regexp.test(text);
  };

  const handleAddTab = () => {
    let tempdata = partsData ? [...partsData] : [];
    tempdata.push({
      id: uuidv4(),
      partsBelong: 0,
      assignedTo: 0,
      parts: [
        {
          id: uuidv4(),
          partId: 0,
          quantity: "1",
        },
      ],
    });
    setPartsData(tempdata);
    //hideconsole.log(tempdata);
    //  dispatch(handleRateByJobType(tempdata));
  };

  const handleAddParts = (tabId) => {
    const indexTab = partsData.findIndex((el) => el.id == tabId);
    let tempdata = [...partsData];
    tempdata[indexTab].parts.push({
      id: uuidv4(),
      partId: 0,
      quantity: 1,
    });
    setPartsData(tempdata);
    //hideconsole.log("parts", tempdata);
    // //hideconsole.log(tempdata);
    //  dispatch(handleRateByJobType(tempdata));
  };

  const handlePartsBelong = (e, tabId) => {
    // //hideconsole.log(partsData);

    const index = partsData.findIndex((el) => el.id == tabId);
    let tempdata = [...partsData];
    tempdata[index].partsBelong = e ? e.value : 0;
    //hideconsole.log("parts ", index);
    e.value == 0 && (tempdata[index].assignedTo = partsBelongOptions[1]?.value);
    setPartsData(tempdata);
    // dispatch(handleRateByJobType(tempdata));
  };

  const handleAssignedTo = (e, tabId) => {
    // //hideconsole.log(partsData);
    const index = partsData.findIndex((el) => el.id == tabId);
    let tempdata = [...partsData];
    //hideconsole.log("parts index", index);
    tempdata[index].assignedTo = e ? e.value : 0;
    setPartsData(tempdata);
    // dispatch(handleRateByJobType(tempdata));
  };

  const handlePartId = (event, tabId, partId) => {
    const indexTab = partsData.findIndex((el) => el.id == tabId);
    const indexPart = partsData[indexTab].parts.findIndex(
      (el) => el.id == partId
    );
    let tempdata = [...partsData];
    tempdata[indexTab].parts[indexPart].partId = event ? event.value : 0;
    setPartsData(tempdata);
  };

  const handleQuantity = (e, tabId, partId) => {
    // //hideconsole.log(e);
    if (e.target.value >= 0) {
      const indexTab = partsData.findIndex((el) => el.id == tabId);
      const indexPart = partsData[indexTab].parts.findIndex(
        (el) => el.id == partId
      );
      let tempdata = [...partsData];
      tempdata[indexTab].parts[indexPart].quantity = e ? e.target.value : 0;
      setPartsData(tempdata);
    } else {
      toast.error("Quantity cannot be negative.");
    }
  };

  const handleDeletepart = (tabId, partId) => {
    const indexTab = partsData.findIndex((el) => el.id == tabId);
    let tempdata = [...partsData];
    let newparts = tempdata[indexTab].parts.filter(
      (temp) => temp.id !== partId
    );
    tempdata[indexTab].parts = newparts;
    // //hideconsole.log(tempdata);
    setPartsData(tempdata);
  };

  const handleDeletTab = (e, tabId) => {
    e.stopPropagation();
    let tempdata = [...partsData];
    let newparts = tempdata.filter((temp) => temp.id !== tabId);

    setPartsData(newparts);
  };

  const handleModifyPercentage = (percentage, id) => {
    setModifyPercentageData({ ...modifyPercentageData, [id]: percentage });
  };

  const tabCustomComponents = customFieldsArray?.filter(
    (item) => item.tab_id == 0 && item.active
  );

  // //hideconsole.log("Job Tab components are", tabCustomComponents);

  const tabCustomComponentsSection = [];
  let temp = [];
  for (var i = 0; i < tabCustomComponents?.length; i++) {
    //hideconsole.log(tabCustomComponents);
    temp.push(tabCustomComponents[i]);
    if (temp.length == 3) {
      tabCustomComponentsSection.push(temp);
      temp = [];
    }
  }

  if (temp.length < 3) tabCustomComponentsSection.push(temp);

  // //hideconsole.log("tabCustomComponentsSection is", tabCustomComponentsSection);

  return (
    <>
      <Modal.Body>
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <div className="form-group row">
          {/* Job Status */}
          <div className="col-lg-4">
            <InputLabel>Job Status</InputLabel>
            <Select
              classNamePrefix="mySelect"
              name="job_status_id"
              label="Job Status"
              value={jobStatusOptions.find((item) => item.value === jobStatus)}
              onChange={handleJobStatus}
              options={jobStatusOptions}
            ></Select>
          </div>
          {/* Money */}
          {rolePermissions
            ?.find((item) => item.permission_id === 24)
            ?.roles?.includes(userData.group_id?.toString()) ? (
            <>
              {invoiceExist == true ? (
                <div className="col-lg-4">
                  <InputLabel>Amount Due</InputLabel>
                  <input
                    name="money"
                    className="form-control"
                    onChange={handleChangeMoney}
                    value={money}
                    type="number"
                    placeholder="Money"
                    step=".
                01"
                    // disabled
                  />
                </div>
              ) : (
                <div className="col-lg-4">
                  <InputLabel>Amount Due</InputLabel>
                  <input
                    name="money"
                    className="form-control"
                    onChange={handleChangeMoney}
                    value={money}
                    type="number"
                    placeholder="Money"
                    step=".
                01"
                  />
                  {/* {payBy !== 1 && (
                <>
                  <div>
                    {includeTax
                      ? `Plus $${tax.toFixed(2)} GST.`
                      : `$${tax.toFixed(2)} GST included.`}
                  </div>
                  {creditCardFeeAmount && payBy === 2 ? (
                    <div>
                      Plus ${creditCardFeeAmount.toFixed(2)} credit card fee.
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )} */}
                </div>
              )}

              {invoiceExist === false && (
                <div className="col-4">
                  <label>Tax Needs to be Added</label>
                  <div>
                    <Switch
                      checked={!!includeTax}
                      onChange={handleIncludeTax}
                      color="primary"
                      disabled={payBy === 1}
                      id="check"
                      name="check"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>

        <div className="form-group row">
          {/* Assign Worker */}
          <div className="col-lg-4">
            <InputLabel>Assign Workers</InputLabel>
            <Select
              classNamePrefix="mySelect"
              //className="form-control"
              name="assign_worker"
              label="Assign worker"
              value={workerOptions?.filter((item) =>
                workers
                  ?.toString()
                  ?.split(",")
                  ?.includes(item.value.toString())
              )}
              onChange={handleChangeWorker}
              isMulti
              isSearchable
              options={workerOptions}
              required
            ></Select>
          </div>
          {leadsList.length > 0 ? (
            <div className="col-lg-4">
              <InputLabel>Lead From</InputLabel>
              <Select
                classNamePrefix="mySelect"
                name="lead_from"
                label="Lead From"
                value={leadsList.filter((item) =>
                  leadsListValue?.includes(item.value.toString())
                )}
                onChange={handleChangeLead}
                options={leadsList}
                isMulti
                isSearchable
              ></Select>
            </div>
          ) : (
            ""
          )}
          {/* Pay By */}
          {rolePermissions
            ?.find((item) => item.permission_id == 24)
            ?.roles?.includes(userData.group_id?.toString()) ? (
            <div className="col-lg-4">
              <InputLabel>Payment Method</InputLabel>
              <Select
                classNamePrefix="mySelect"
                name="payby_id"
                label="pay by"
                value={payByOptions.find((item) => item.value === payBy)}
                onChange={handlePayBy}
                options={payByOptions}
              ></Select>
              {payBy === 2 ? (
                <>
                  {invoiceExist == false && (
                    <div className="row ml-0 align-items-center">
                      <label>Add Credit Card Fee</label>
                      <div>
                        <Switch
                          checked={creditCardFee}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setCreditCardFee(e.target.checked);
                            setMoney((prevMoney) =>
                              isChecked
                                ? (
                                    prevMoney *
                                    (1 + creditCardFeeAmount / 100)
                                  ).toFixed(4)
                                : (
                                    prevMoney /
                                    (1 + creditCardFeeAmount / 100)
                                  ).toFixed(4)
                            );
                          }}
                          color="primary"
                          disabled={payBy == 1}
                          id="check"
                          name="check"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </div>
                  )}
                  <input
                    className="mt-5"
                    name="payment_approval_code"
                    // component={Input}
                    placeholder="payment approval"
                    label="payment approval "
                    // withFeedbackLabel={false}
                    value={paymentApprovalCode || ""}
                    onChange={(e) => setPaymentApprovalCode(e.target.value)}
                    style={{
                      height: "calc(1rem + 1.3rem + 2px)",
                      width: "100%",
                    }}
                  />
                </>
              ) : (
                ""
              )}
              <>
                <label>Split Payment</label>
                &nbsp; &nbsp;
                <Switch
                  checked={!!splitPayment}
                  onChange={(e) => {
                    setSplitPayment(e.target.checked);
                  }}
                  color="primary"
                  id="check"
                  name="check"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </>
            </div>
          ) : (
            ""
          )}
        </div>

        {splitPayment ? (
          <div className="form-group row">
            <div className="col-lg-4">
              <InputLabel>Amount Due 2</InputLabel>
              <input
                name="money"
                className="form-control"
                onChange={(e) => setMoney2(e.target.value)}
                value={money2}
                type="number"
                placeholder="Money 2"
                step=".01"
              />
              {/* {payBy2 != 1 ? (
                <>
                  <div>
                    {includeTax2
                      ? `Plus $${tax2?.toFixed(2)} GST.`
                      : `$${tax2?.toFixed(2)} GST included.`}
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>

            {invoiceExist === false && (
              <div className="col-4">
                <label>Tax Needs to be Added</label>
                <div>
                  <Switch
                    checked={!!includeTax2}
                    onChange={(e) => {
                      handleIncludeTax2(e);
                    }}
                    color="primary"
                    id="check"
                    disabled={payBy2 == 1}
                    name="check"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              </div>
            )}

            <div className="col-lg-4">
              <InputLabel>Payment Method 2</InputLabel>
              <Select
                classNamePrefix="mySelect"
                name="payby_id"
                label="pay by 2"
                value={payByOptions.find((item) => item.value === payBy2)}
                onChange={(e) => {
                  handlePayBy2(e);
                }}
                options={payByOptions.filter((item) => item.value != 2)}
              ></Select>
            </div>
          </div>
        ) : null}

        <div className="form-group row">
          {/* Type */}
          <div className="col-lg-4">
            <InputLabel>Company Comment</InputLabel>
            <input
              value={additionalInfo}
              onChange={(e) => {
                setAdditionalInfo(e.target.value);
                setCommentCount(e.target.value.length);
                var lastChar = e.target.value.charAt(e.target.value.length - 1);
                //hideconsole.log(lastChar);
                let IsGsm = isGSMAlphabet(`${lastChar}`);
                //hideconsole.log(IsGsm);
                let temp = 0;
                if (IsGsm === false && IsGsmCOuntComment === 0) {
                  setIsGsmCOuntComment(1);
                  temp = 1;
                  // //hideconsole.log(
                  //   IsGsm === false && IsGsmCOuntName === 0,
                  //   IsGsm === false && IsGsmCOuntName === 1
                  // );
                }
                const value = temp === 0 ? 160 : 67;
                //hideconsole.log(value);

                setmsgCountAsNumComment(value);
              }}
              name="company_comment"
              as="textarea"
              className="form-control"
            />
          </div>

          <div className="col-lg-4">
            <InputLabel>Tradie comment</InputLabel>
            <input
              value={tradieComment == null ? "" : tradieComment}
              onChange={(e) => {
                setTradieComment(e.target.value);
              }}
              name="tradie_comment"
              as="textarea"
              className="form-control"
              // value={customer.tradie_comment}
            />
          </div>

          <div className="col-lg-4">
            <InputLabel>Request a Review</InputLabel>
            <Select
              classNamePrefix="mySelect"
              name="send_review"
              label="Send Review"
              value={sendReviewOptions.find(
                (item) => item.value === sendReview
              )}
              onChange={handleSendReview}
              options={sendReviewOptions}
            ></Select>
          </div>
        </div>

        <div className="form-group row">
          {/* Job Type */}
          {jobTypeOptions.length ? (
            <div className="col-lg-4">
              {/* {//hideconsole.log(jobTypes)}
                      {//hideconsole.log(jobTypeOptions)} */}
              <InputLabel>Job Types</InputLabel>
              <Select
                classNamePrefix="mySelect"
                name="job_type"
                label="Job Type"
                value={jobTypeOptions.filter(
                  (item) =>
                    jobTypes?.length &&
                    jobTypes.split(",").includes(item.value.toString())
                )}
                onChange={handleJobTypes}
                isMulti
                isSearchable
                options={jobTypeOptions}
                required
              >
                {" "}
              </Select>
            </div>
          ) : (
            ""
          )}

          {/* Dropdown for selecting a list */}
          <div className="col-lg-4 tour_workr">
            <InputLabel>Pipeline</InputLabel>
            <Select
              classNamePrefix="mySelect"
              name="pipeline"
              label="Pipeline"
              value={
                selectedList
                  ? {
                      value: selectedList.value,
                      label: selectedList.label,
                    }
                  : null
              }
              onChange={(selectedOption) => {
                console.log("Selected Option:", selectedOption); // Debugging log
                setSelectedList(selectedOption); // Store the selected option object
              }}
              options={filteredLists}
              placeholder="Select a list..."
              isClearable
              isSearchable
            />
          </div>
          <br />
        </div>

        <div className="form-group row">
          {/* Send SMS */}
          <div className="col-3">
            {!appointment ? (
              <>
                <label>Send SMS</label>
                &nbsp; &nbsp;
                <Switch
                  checked={!!sendSMS}
                  onChange={(e) => {
                    handleSendSMS(e);
                  }}
                  color="primary"
                  id="check"
                  name="check"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </>
            ) : null}
          </div>

          <div className="col-lg-5">
            <label>Schedule Appointment</label>
            &nbsp; &nbsp;
            <Switch
              checked={!!appointment}
              onChange={(e) => {
                if (e.target.checked) {
                  setSendSMS(false);
                }
                handleAppointment(e);
              }}
              color="primary"
              id="check"
              name="check"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            {appointment ? (
              <>
                <div className="mt-1">
                  <TextField
                    id="datetime-local"
                    className="form-control"
                    type="datetime-local"
                    variant="outlined"
                    label="Appointment Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      //hideconsole.log(e.target.value);
                      var utcStart = new moment(
                        e.target.value,
                        "yyyy-MM-DDTHH:mm"
                      ).utc();
                      //hideconsole.log(utcStart);

                      setAppointmentTime(e.target.value);
                    }}
                    // defaultValue={moment(appointmentTime).format(
                    //   "yyyy-MM-DDTHH:mm"
                    // )}
                    value={appointmentTime}
                  />
                </div>
                <div className="popup mt-4" id="taxInfo">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  <span
                    className="popuptext show"
                    id="taxPopup"
                    style={{ fontSize: "0.8rem", color: "red" }}
                  >
                    An SMS reminder will be sent one hour prior to the
                    appointment time.
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <br />
        </div>

        <div className="form-group mb-0 row ml-0">
          <label>Date Created: &nbsp;</label>
          {dateCreated}
        </div>
        {closedBy ? (
          <div className="form-group row ml-0">
            <label>Closed By: &nbsp;</label>
            {workerOptions?.find((item) => item.value == closedBy)?.label}
            {/* {console.log("check worker", workerOptions.find((item) => item.value == closedBy)?.label)}
            {console.log("check all worker", workerOptions)} */}
          </div>
        ) : (
          ""
        )}

        <CustomFieldsRenderer
          tabCustomComponents={tabCustomComponents}
          tabCustomComponentsSection={tabCustomComponentsSection}
          customFieldsArray={customFieldsArray}
          setCustomFieldsArray={setCustomFieldsArray}
          customFieldsData={customFieldsData}
          setCustomFieldsData={setCustomFieldsData}
        />

        <>
          {partsBelongOptions?.length > 1 ? (
            <div className="form-group mb-0">
              <label className="mb-0">Parts</label>
              <br></br>

              {partsData
                ? partsData.map((item) => {
                    //hideconsole.log(item);
                    // //hideconsole.log(partsOptions);
                    return (
                      <div key={item.id} className="form-group mb-0">
                        <Accordion
                          defaultExpanded={
                            item.parts.length <= 1 && !item?.parts[0]?.name
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            // className="bg-secondary"
                          >
                            {/* <Typography> */}
                            <div className="row ml-0 ">
                              <div
                                className="col-3"
                                onClick={(e) => e.stopPropagation()}
                                style={{ minWidth: "250px" }}
                              >
                                <InputLabel>Parts Belong To</InputLabel>
                                <Select
                                  classNamePrefix="mySelect"
                                  onChange={(e) =>
                                    handlePartsBelong(e, item.id)
                                  }
                                  name="partsBelong"
                                  options={partsBelongOptions}
                                  value={partsBelongOptions.find(
                                    (element) =>
                                      element.value == item.partsBelong
                                  )}
                                />
                              </div>

                              {item.partsBelong === 0 &&
                              workers?.split(",")?.length > 1 ? (
                                <div
                                  className="col-3 "
                                  onClick={(e) => e.stopPropagation()}
                                  style={{ minWidth: "250px" }}
                                >
                                  <InputLabel>Assigned To</InputLabel>
                                  <Select
                                    classNamePrefix="mySelect"
                                    onChange={(e) =>
                                      handleAssignedTo(e, item.id)
                                    }
                                    name="assignedTo"
                                    options={partsBelongOptions.filter(
                                      (obj) => obj.value != 0
                                    )}
                                    value={partsBelongOptions.find(
                                      (element) =>
                                        element.value === item.assignedTo
                                    )}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="col-2">
                                <div>&nbsp;</div>
                                <button
                                  onClick={(e) => handleDeletTab(e, item.id)}
                                  className="btn btn-outline-danger btn-sm align-bottom"
                                  type="button"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                            {/* </Typography> */}
                          </AccordionSummary>
                          <AccordionDetails>
                            {item.parts &&
                              item.parts.map((part) => {
                                //   //hideconsole.log(part);
                                console.log("part", part);
                                return (
                                  <div className="row m-1 mt-5" key={part.id}>
                                    <div className="col-lg-4">
                                      <InputLabel>Part</InputLabel>
                                      <Select
                                        classNamePrefix="mySelect"
                                        //native
                                        name="parts"
                                        options={[
                                          { label: "Select...", value: 0 },
                                          ...inventoryOptions.map((item) => ({
                                            label: item.Name, 
                                            value: item.id,
                                          })),
                                        ]}
                                        value={
                                          part.partId !== 0
                                            ? (() => {
                                                const foundElement = inventoryOptions.find(
                                                  (element) => element.id === part.partId
                                                );
                                                return foundElement
                                                  ? { label: foundElement.Name, value: foundElement.quantity }
                                                  : { label: "Select...", value: 0 };
                                              })()
                                            : { label: "Select...", value: 0 }
                                        }
                                        onChange={(e) =>
                                          handlePartId(e, item.id, part.id)
                                        }
                                        // filterOption={()}
                                        components={{
                                          NoOptionsMessage,
                                        }}
                                      />
                                    </div>
                                    <div className="col-lg-4">
                                      <InputLabel>Quantity</InputLabel>
                                      <input
                                        onChange={(e) =>
                                          handleQuantity(e, item.id, part.id)
                                        }
                                        name="quantity"
                                        value={part.quantity}
                                        className="form-control"
                                        type="number"
                                      />
                                    </div>
                                    {/* <i
                                      onClick={() =>
                                        handleDeletepart(item.id, part.id)
                                      }
                                      className="fas fa-trash d-flex align-items-center"
                                    ></i> */}
                                    <div>
                                      <div>&nbsp;</div>
                                      <button
                                        onClick={() =>
                                          handleDeletepart(item.id, part.id)
                                        }
                                        className="btn btn-outline-danger btn-sm "
                                        type="button"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}

                            <button
                              onClick={() => handleAddParts(item.id)}
                              type="button"
                              className="btn btn-secondary ml-5 mt-4"
                            >
                              Add
                            </button>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })
                : ""}

              <button
                onClick={handleAddTab}
                type="button"
                className="btn btn-light-primary btn-elevate mt-3 form-group"
              >
                New
              </button>
            </div>
          ) : (
            ""
          )}

          {workers?.split(",")?.length > 1 &&
          workerOptions?.find(
            (item) =>
              item.payment_type_id == 2 &&
              workers?.split(",")?.includes(item.value.toString())
          ) ? (
            <div className="form-group row mb-3">
              <div className="col">
                <label>Modify Percentage</label>
                &nbsp;&nbsp;
                <Switch
                  variant="contained"
                  onClick={() => {
                    setShowModifyPercentage(!showModifyPercentage);
                  }}
                  color="primary"
                  checked={showModifyPercentage}
                ></Switch>
              </div>
            </div>
          ) : null}

          {showModifyPercentage ? (
            <>
              <div className="row">
                {workerOptions
                  ?.filter(
                    (item) =>
                      item.payment_type_id == 2 &&
                      workers?.split(",")?.includes(item.value.toString())
                  )
                  ?.map((data, index) => {
                    //hideconsole.log(data);
                    return (
                      <div className="col-lg-6 form-group mb-3" key={index}>
                        <label>
                          {`Percentage for `} <b>{data.label}</b>
                        </label>
                        <br />
                        <input
                          type="number"
                          className={"input_css"}
                          value={
                            modifyPercentageData[data.value] ||
                            (modifyPercentageData[data.value] === ""
                              ? ""
                              : data.payment)
                          }
                          onChange={(e) => {
                            handleModifyPercentage(e.target.value, data.value);
                          }}
                          name={`percentage${data.name}`}
                          placeholder={`percentage`}
                        />
                      </div>
                    );
                  })}
              </div>
            </>
          ) : null}
        </>

        <AddEditRowModal
          type="add"
          show={showNewPartModal}
          onHide={() => setShowNewPartModal(false)}
          initialValue={{ Id: "", Name: "", Price: "", Quantity: "" }}
          allData={currentInventory.data}
          allTitle={title}
          handleSendManageInventory={(action, title, updatedData) => {
            const newPart = updatedData[updatedData.length - 1];
            addNewPart(newPart);
            setShowNewPartModal(false);
          }}
        />
      </Modal.Body>
    </>
  );
}
