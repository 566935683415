//TODO: This used to be a modal in AddItemToInvoice.js but I will refactor it someday to use only one component. This is because we will be using this part of the code in multiple instances

import React, { useState, useEffect, Component, useMemo } from "react";
import { Paper, Slider, Divider } from "@material-ui/core/";
import { toast } from "react-toastify";
import Select from "react-select";

import styles from "./CustomerAddItemDialog.module.scss";
import { Switch } from "@material-ui/core";
import { initial } from "lodash";

export function AddItemToInvoiceComponent({
  getData,
  allTitle,
  handletax,
  handleMarginType,
  jobTypeOptions,
  setItemData,
  itemData,
  itemPrice,
  setItemPrice
}) {

  return (
    <>
      <div>

        <div>
          <div className="form-group">
            <div className="row">
              <div className="col-6 mt-2">
                <label>Item Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={itemData.name}
                  autoComplete="off"
                  onChange={(e) => {
                    setItemData({ ...itemData, itemName: e.target.value });
                  }}
                  className="form-control"
                ></input>
              </div>
              <div className="col-6 mt-2">
                <label>Description</label>
                <input
                  type="textarea"
                  name="description"
                  placeholder="Description"
                  value={itemData.description}
                  autoComplete="off"
                  onChange={(e) => {
                    setItemData({ ...itemData, description: e.target.value });
                  }}
                  className="form-control"
                ></input>
              </div>
            </div>

            <div className="row">
              {itemData ? (
                <div className="col-4 mt-2">
                  <label>Name</label>
                  <input
                    type="text"
                    name="partPrice"
                    placeholder="Part Name"
                    value={itemData.partName}
                    autoComplete="off"
                    disabled
                    className="form-control"
                  ></input>
                </div>
              ) : (
                ""
              )}
              {itemData ? (
                <div className="col-4 mt-2">
                  <label>Price</label>
                  <input
                    type="number"
                    name="partPrice"
                    placeholder="Part Price"
                    value={itemData.partPrice}
                    autoComplete="off"
                    disabled
                    className="form-control"
                  ></input>
                </div>
              ) : (
                ""
              )}
              <div className="col-4 mt-2">
                <label>Default Item Quantity</label>
                <input
                  type="number"
                  name="defaultQuantity"
                  placeholder="Item Quantity"
                  value={itemData.defaultQuantity}
                  autoComplete="off"
                  className="form-control"
                  onChange={(e) => {
                    setItemData({
                      ...itemData,
                      defaultQuantity: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>

            <div className="row">
              {itemData ? (
                <div className="col mt-2">
                  <label className="mb-0">Margin</label>
                  <div className="row align-items-center d-flex">
                    <div className="col-lg-2 col-md-6 col-sm-6 ">
                      <input
                        style={{
                          minWidth: "80px",
                          width: `${Math.max(itemData.margin.length, 2) *
                            10}px`, // Dynamic width
                          transition: "width 0.2s ease-in-out",
                        }}
                        value={itemData.margin}
                        placeholder="0"
                        onChange={(e) => {
                          const { value } = e.target;

                          // Allow empty value or numbers only
                          if (value === "" || /^\d*$/.test(value)) {
                            setItemData({
                              ...itemData,
                              margin: value, // Keep it as a string during typing
                            });
                          }
                        }}
                        onBlur={() => {
                          // Clean up and validate on blur
                          let cleanValue = parseFloat(itemData.margin) || 0;
                          if (!itemData.fixedMargin) {
                            cleanValue = Math.min(cleanValue, 300); // Cap at 300% for percentage mode
                          }
                          setItemData({
                            ...itemData,
                            margin: cleanValue.toString(),
                          }); // Update with valid value
                        }}
                        className="form-control"
                        type="text"
                      />
                    </div>

                    {itemData.fixedMargin === false && (
                      <div className="col-lg-7 mr-3">
                        <Slider
                          style={{ marginLeft: "10px" }}
                          getAriaValueText={(value) => `${value}%`}
                          aria-labelledby="discrete-slider-small-steps"
                          step={10}
                          marks={[
                            { value: 0, label: "0%" },
                            { value: 50, label: "50%" },
                            { value: 100, label: "100%" },
                            { value: 150, label: "150%" },
                            { value: 200, label: "200%" },
                            { value: 250, label: "250%" },
                            { value: 300, label: "300%" },
                          ]}
                          min={0}
                          max={300} // Cap the slider at 300
                          valueLabelDisplay="auto"
                          value={parseFloat(itemData.margin)}
                          onChange={(event, value) => {
                            setItemData({ ...itemData, margin: value });
                          }}
                        />
                      </div>
                    )}

                    <div className="col-lg-2">
                      <button
                        type="button"
                        id="marginType"
                        className={
                          itemData.fixedMargin
                            ? "btn btn-light-warning w-100 font-weight-bolder"
                            : "btn btn-light-success w-100 font-weight-bolder "
                        }
                        // style={{ display: "none" }}
                        name="fixedMargin"
                        onClick={handleMarginType}
                        value={itemData.fixedMargin}
                      >
                        {itemData.fixedMargin ? "$" : "%"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-4 mt-2">
                <label>Item Price</label>
                <input
                  type="number"
                  name="total"
                  placeholder="Total"
                  value={itemPrice}
                  onChange={(e) => {
                    setItemPrice(e.target.value);
                  }}
                  autoComplete="off"
                  disabled={itemData}
                  className="form-control"
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mt-2">
                <label>Job Type</label>
                <Select
                  classNamePrefix="mySelect"
                  name="group_id"
                  label="Group Id"
                  value={jobTypeOptions.filter((item) =>
                    itemData?.jobType
                      ?.split(",")
                      ?.includes(item.value.toString())
                  )}
                  onChange={(e) => {
                    //hideconsole.log(e);
                    setItemData({
                      ...itemData,
                      jobType: e?.map((item) => item.value)?.toString() || "",
                    });
                  }}
                  options={jobTypeOptions}
                  isMulti
                  isSearchable
                  required
                >
                  {" "}
                </Select>
              </div>

              <div className="col-4 mt-2">
                <label>Taxable Item?</label>
                <div>
                  <label>
                    No
                    <Switch
                      checked={!!itemData.is_taxable}
                      onChange={handletax}                    
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    Yes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <button
            type="button"
            onClick={HandleHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
            className="btn btn-primary btn-elevate ml-3"
          >
            Add
          </button>
        </div> */}
      </div>
    </>
  );
}
