import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import EmailModal from "./EmailModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SMSModal from "./SMSModal";
import * as actions from "../../../_redux/customers/customersActions";
import { JobEditDialogHeader } from "./JobEditDialogHeader";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { JobTabDialog } from "./JobTabDialog";
import { JobEditForm } from "./JobEditForm";
import { FileTabDialog } from "./FileTabDialog";
import { useCustomersUIContext } from "../CustomersUIContext";
import NotesTab from "./NotesTab";
import TasksTab from "./TasksTab";
import axios from "axios";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "moment-timezone";
import CustomAlertModal from "../../../../../../_metronic/_helpers/CustomAlertModal";
import { useRef } from "react";
import Select from "react-select";
import { error } from "jquery";

const SmsCounter = require("sms-counter-npm");
// let IsGsmCOuntComment = 0;
// let msgCountAsNumComment = 160;
export function JobEditDialog({
  id,
  show,
  onHide,
  data,
  getAppointmentData,
  newGetListData,
  listFromTrello,
}) {
  const [tasks, setTasks] = useState([]); // Store the list of tasks
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [rawWorkerOptions, setRawWorkerOptions] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [listId, setListId] = useState(null);
  const [newListId, setNewListId] = useState(null);
  const [transferredTasks, setTransferredTasks] = useState([]);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  // Customers UI Context
  const [jobData, setJobData] = useState();
  const [IsGsmCOuntComment, setIsGsmCOuntComment] = React.useState(0);
  const [msgCountAsNumComment, setmsgCountAsNumComment] = React.useState(160);
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: false,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );
  const [tempTamplateString, settempTamplateString] = React.useState("");
  const [TamplateString, setTamplateString] = React.useState("");
  const [customer, setCustomer] = React.useState([]);
  const [note, setNote] = useState("");
  const [noteArr, setNoteArr] = useState([]);
  const [AllFileArr, setAllFileArr] = useState([]);
  const [customerData, setCustomerData] = React.useState(customerForEdit);
  const [isEdit, setIsEdit] = useState(false);
  const [idForNote, setIdForNote] = useState(null);

  const [lat, setlat] = React.useState("");
  const [lng, setlng] = React.useState("");
  const [changePercentageData, setchangePercentageData] = React.useState([]);
  const [IsChangePercentage, setIsChangePercentage] = React.useState(false);

  const [tradieComment, setTradieComment] = React.useState("");
  const [companyComment, setCompanyComment] = React.useState(
    customerForEdit?.company_comment ? customerForEdit?.company_comment : null
  );
  const [check, setCheck] = React.useState(false);

  const [statusValue, setStatusValue] = React.useState(
    customerForEdit?.job_status_id ? customerForEdit?.job_status_id : null
  );
  const [tax, setTax] = React.useState(0);
  const [tax2, setTax2] = React.useState(0);
  const [partsValue, setPartsValue] = React.useState([]);
  const [partBelong, setPartBelong] = React.useState(
    customerForEdit?.parts_belong ? customerForEdit?.parts_belong : null
  );
  const [typeValue, setTypeValue] = React.useState(
    customerForEdit?.job_type ? customerForEdit?.job_type : null
  );
  const [workerValue, setWorkerValue] = React.useState(
    customer?.user_id ? customer?.user_id : []
  );
  const [status, setStatus] = React.useState([]);
  const [worker, setWorker] = React.useState([]);
  const [view, setView] = React.useState(false);
  const [type, setType] = React.useState([]);
  const [showLead, setShowLead] = React.useState(false);
  const [leadsList, setLeadsList] = React.useState([]);
  const [leadsListValue, setLeadsListValue] = React.useState([]);
  const [commentCount, setCommentCount] = React.useState(0);
  const [numberCount, setnumberCount] = React.useState({
    messageLength: 160,
    currentMsglength: 0,
    remainingLength: 0,
    MessageCount: 0,
  });
  const [partsData, setPartsData] = React.useState([]);
  const [partsOptions, setPartsOptions] = React.useState([]);
  const history = useHistory();
  const [jobStatus, setJobStatus] = React.useState(1);
  const [payBy, setPayBy] = React.useState(0);
  const [splitPayment, setSplitPayment] = React.useState(false);
  const [payBy2, setPayBy2] = React.useState(0);
  const [money, setMoney] = React.useState(0);
  const [money2, setMoney2] = React.useState(0);
  const [includeTax, setIncludeTax] = React.useState(0);
  const [includeTax2, setIncludeTax2] = React.useState(0);
  const [jobTypes, setJobTypes] = React.useState("");
  const [appointment, setAppointment] = React.useState(0);
  const [appointmentTime, setAppointmentTime] = React.useState("");
  const [workers, setWorkers] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [paymentApprovalCode, setPaymentApprovalCode] = React.useState("");
  const [sendSMS, setSendSMS] = React.useState(false);
  const [sendReview, setSendReview] = React.useState(false);
  const [jobsValidation, setJobsValidation] = React.useState([]);
  const [jobStatusOptions, setJobStatusOptions] = React.useState([]);
  const [workerOptions, setWorkerOptions] = React.useState([]);
  const [jobTypeOptions, setJobTypeOptons] = React.useState([]);
  const [modifyPercentageData, setModifyPercentageData] = React.useState({});
  const [showModifyPercentage, setShowModifyPercentage] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const userData = useSelector((state) => state.auth.user);
  const { rolePermissions } = useSelector((state) => state.rightSettings);
  const [isClosed, setIsClosed] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [creditCardFee, setCreditCardFee] = useState(false);
  const [fees, setFees] = useState(undefined);
  const [creditCardFeeAmount, setCreditCardFeeAmount] = useState(false);
  const [closedBy, setClosedBy] = useState(0);
  const [eventExists, setEventExist] = useState(false);
  const [dateCreated, setDateCreated] = useState(
    moment().format("yyyy-MM-DD HH:mm:ss")
  );
  const [suburbName, setSuburbName] = useState("");

  // Add this right before jobDiaryStyles
  const entryTypeLabels = {
    "": "All Entry Types",
    address: "Address",
    email: "Email Messages",
    file: "File",
    note: "Note",
    review: "Review",
    schedule: "Schedule",
    sms: "SMS",
    status: "Status",
    task: "Task",
  };

  // state needed for task transfer
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [jobDiaryStyles, setJobDiaryStyles] = useState({});
  const [diaryEntries, setDiaryEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");

  useEffect(() => {
    console.log("new part options", partsOptions);
  }, [partsOptions]);

  // useEffect(() => {
  //   if (diaryEntries.length === 0) {
  //     setDiaryEntries([
  //       {
  //         type: "default",
  //         content: "Default diary entry created.",
  //         timestamp: new Date().toISOString(),
  //         user: userData.name || "System",
  //       },
  //     ]);
  //   }
  // }, [diaryEntries]);

  const updateDiaryEntry = (
    type,
    content,
    user = userData.name || "System",
    saved = false
  ) => {
    if (!content) {
      setDiaryEntries((prev) =>
        prev.filter(
          (entry) =>
            // Remove entries of this type that are not part of a session
            entry.type !== type || entry.session
        )
      );
      return;
    }

    setDiaryEntries((prev) => {
      // Keep entries that are part of a session
      const sessionEntries = prev.filter((entry) => entry.session);

      // Filter out any entries of the same type that are NOT part of a session
      const otherTypeEntries = prev.filter(
        (entry) => entry.type !== type || entry.session
      );

      // Create new entry
      const newEntry = {
        type,
        content,
        timestamp: new Date().toISOString(),
        user,
        saved,
        // Add a unique ID to help with deduplication
        entryId: `${type}-${Date.now()}`,
      };

      // Return the combined array with the new entry
      return [...otherTypeEntries, newEntry];
    });
  };

  // Job status changes
  useEffect(() => {
    if (jobStatus) {
      const statusLabel = jobStatusOptions.find(
        (opt) => opt.value === jobStatus
      )?.label;
      if (statusLabel) {
        updateDiaryEntry("status", `Job status changed to: ${statusLabel}`);
      }
    }
  }, [jobStatus]);

  // Review request changes
  useEffect(() => {
    if (sendReview) {
      const reviewType = sendReview === 1 ? "Email" : "SMS";
      updateDiaryEntry("review", `Review requested via ${reviewType}`);
    } else {
      updateDiaryEntry("review", null); // This will remove the entry
    }
  }, [sendReview]);

  // File changes useEffect
  // useEffect(() => {
  //   if (AllFileArr.length === 0) {
  //     updateDiaryEntry("file", null);
  //   } else {
  //     updateDiaryEntry(
  //       "file",
  //       AllFileArr.map((file) => ({
  //         filename: file,
  //         url: `https://storage.googleapis.com/platform-295512.appspot.com/${file}`,
  //       }))
  //     );
  //   }
  // }, [AllFileArr]);

  // Schedule changes
  const previousAppointmentRef = useRef({ appointment, appointmentTime });

  useEffect(() => {
    // Only update if there's an actual change in either appointment or appointmentTime
    if (
      previousAppointmentRef.current.appointment !== appointment ||
      previousAppointmentRef.current.appointmentTime !== appointmentTime
    ) {
      if (appointment && appointmentTime) {
        updateDiaryEntry(
          "schedule",
          `Appointment scheduled for: ${new Date(
            appointmentTime
          ).toLocaleString()}`
        );
      } else {
        updateDiaryEntry("schedule", null);
      }
      // Update the ref with current values
      previousAppointmentRef.current = { appointment, appointmentTime };
    }
  }, [appointment, appointmentTime]);

  // Note changes
  useEffect(() => {
    if (noteArr?.length > 0) {
      // Get the most recent note
      const notes = noteArr.map((note) => {
        // Handle both string and object formats
        let content, timestamp;
        try {
          if (typeof note === "string") {
            // Try to parse if it's a stringified JSON
            const parsed = JSON.parse(note);
            content = parsed.note || note;
            timestamp = parsed.date || note.date;
          } else {
            content = note.note || note;
            timestamp = note.date;
          }
        } catch {
          content = note;
          timestamp = new Date().toISOString();
        }
        return { content, timestamp };
      });

      const formattedNotes = notes.map((note) => `${note.content}`).join("\n");

      updateDiaryEntry("note", formattedNotes);
    }
  }, [noteArr]);

  // task changes

  const previousTasksRef = useRef(null); // Reference to store the previous state of tasks

  // Use a state flag to track if we're in the initial task load
  const [isInitialTasksLoad, setIsInitialTasksLoad] = useState(true);

  useEffect(() => {
    const fetchWorkerOptionsAndUpdateDiary = async () => {
      try {
        // Only process tasks if initial data is loaded
        if (!isInitialDataLoaded) {
          return;
        }

        const response = await requestFromServer.getWorkers();
        if (response?.data?.result?.length > 0) {
          const fetchedWorkerOptions = response.data.result;

          // Combine tasks to get the current unique state
          const currentTasks = _.uniqBy(
            [...(tasks || [])],
            (task) => `${task.task}-${task.dueDate}-${task.assignedTo}`
          );

          // If this is the initial load, just set the reference without adding a diary entry
          if (isInitialTasksLoad) {
            previousTasksRef.current = currentTasks;
            setIsInitialTasksLoad(false);
            return;
          }

          // For subsequent updates, compare with previous state
          const tasksChanged = !_.isEqual(
            _.sortBy(previousTasksRef.current, [
              "task",
              "dueDate",
              "assignedTo",
            ]),
            _.sortBy(currentTasks, ["task", "dueDate", "assignedTo"])
          );

          if (tasksChanged) {
            previousTasksRef.current = currentTasks;
            if (currentTasks.length === 0) {
              updateDiaryEntry("task", null);
            } else {
              updateDiaryEntry(
                "task",
                currentTasks.map((task) => ({
                  taskName: task.task,
                  dueDate: task.dueDate,
                  assignedTo:
                    fetchedWorkerOptions.find(
                      (w) => w.value === task.assignedTo
                    )?.label || "Unassigned",
                })),
                userData.name,
                false // Mark as unsaved for user-made changes
              );
            }
          }
        }
      } catch (error) {
        console.error("Error fetching worker options:", error);
      }
    };

    // Only run if we have tasks to process
    if (tasks?.length > 0) {
      fetchWorkerOptionsAndUpdateDiary();
    } else if (isInitialDataLoaded && !isInitialTasksLoad) {
      // Only clear tasks if we're past the initial load
      updateDiaryEntry("task", null);
    }
  }, [tasks, isInitialDataLoaded]);

  const previousAddressRef = useRef(address);

  useEffect(() => {
    // Only update if there's an actual change in the address AND initial data has been loaded
    if (isInitialDataLoaded && previousAddressRef.current !== address) {
      if (address) {
        updateDiaryEntry("address", `Job address set to: ${address}`);
      } else {
        updateDiaryEntry("address", null);
      }
      // Update the ref with current value
      previousAddressRef.current = address;
    }
  }, [address, isInitialDataLoaded]);

  useEffect(() => {
    const updateStyles = () => {
      const screenWidth = window.innerWidth;

      // Dynamically calculate styles based on screen size
      setJobDiaryStyles({
        position: "absolute",
        top: "0vh", // Starts 10% below the top of the viewport
        right:
          screenWidth >= 1920
            ? "-25vw" // For ultra-wide screens (1920px and above)
            : screenWidth >= 1440
            ? "-20vw" // For desktop screens (1440px)
            : screenWidth >= 1280
            ? "-25vw" // For smaller desktop screens (1280px)
            : screenWidth >= 1024
            ? "-25vw" // For tablets (1024px)
            : screenWidth >= 768
            ? "-25vw" // For small tablets (768px)
            : "0.5vw", // For mobile screens (less than 768px)
        width:
          screenWidth >= 1920
            ? "25vw" // For ultra-wide screens (1920px and above)
            : screenWidth >= 1440
            ? "20vw" // For desktop screens (1440px)
            : screenWidth >= 1280
            ? "18vw" // For smaller desktop screens (1280px)
            : screenWidth >= 1024
            ? "15vw" // For tablets (1024px)
            : screenWidth >= 768
            ? "10vw" // For small tablets (768px)
            : "0.5vw", // For mobile screens (less than 768px)
        height: "87vh",
        borderLeft: "1px solid #e0e0e0",
        padding: "2vw", // Padding scales based on viewport width
        backgroundColor: "#f9f9f9",
        overflowY: "auto",
        zIndex: "1",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for visual separation
      });
    };

    // Update styles on resize
    updateStyles();
    window.addEventListener("resize", updateStyles);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateStyles);
  }, []);

  // useEffect(() => {
  //   console.log("data: ", data);
  // }, [data]);

  useEffect(() => {
    requestFromServer
      .getJobsStatus()
      .then((response) => {
        //hideconsole.log("job status", response.data.result);
        setJobStatusOptions(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });

    requestFromServer.getWorkers().then((response) => {
      if (response.data.result.length) {
        setWorkerOptions(response.data.result);
        // setView(true);
      }
    });

    requestFromServer.getJobsType().then((response) => {
      if (response.data.result.length) {
        setJobTypeOptons(response.data.result);
      }
    });

    requestFromServer.getCompanyLeadsList().then((response) => {
      if (response.data.result.length) {
        setLeadsList(response.data.result);
        setShowLead(true);
        //hideconsole.log("true", response.data.result);
      } else {
        setView(false);
      }
    });

    requestFromServer.getJobsValidation().then((res) => {
      setJobsValidation(res.data);
    });

    requestFromServer
      .getToolOptions()
      .then((response) => {
        setPartsOptions(response.data.result);
      })
      .catch((error) => console.log(error));

    requestFromServer.fees().then((res) => {
      //hideconsole.log(res);
      setFees(res?.data?.result?.[0]);
    });
  }, []);

  useEffect(() => {
    requestFromServer
      .getAllUsers()
      .then((response) => {
        console.log("API Response:", response.data);
        if (response.data.result.length) {
          setRawWorkerOptions(response.data.result);
        } else {
          console.warn("No users found");
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const getData = () => {
    setIsLoading(true);
    requestFromServer.getJobById(id).then((res) => {
      //hideconsole.log(res.data.result);
      const job = res.data.result;
      // console.log("job", job);
      setJobData(job);
      // //hideconsole.log(job.appointment_time?.replace(" ", "T"));
      // //hideconsole.log(
      //   moment
      //     .utc(job.appointment_time)
      //     .local()
      //     .toISOString()
      // );
      setShowModifyPercentage(
        job?.modify_percentage.length > 5 && job.user_id?.split(",")?.length > 1
      );
      setDateCreated(job.date_created);
      setModifyPercentageData(JSON.parse(job.modify_percentage));
      setJobStatus(job.job_status_id);
      setMoney(job.money);
      setPayBy(job.payby_id);
      setIncludeTax(job.include_tax);
      setPartsData(job.parts ? JSON.parse(job.parts) : "");
      setJobTypes(job.job_type);
      setWorkers(job.user_id);
      setAdditionalInfo(job.company_comment);
      setTradieComment(job.tradie_comment);
      setAppointment(job.appointment);
      setCreditCardFee(!!job.credit_fee);
      setClosedBy(job.closed_by);
      setCompanyId(job.company_id);
      setCustomerId(job.customer_id);
      setAppointmentTime(
        job.appointment_time
          ? moment
              .utc(job.appointment_time)
              .tz(userData.time_zone)
              .format("yyyy-MM-DDTHH:mm:ss")
          : ""
      );
      setCustomer({
        ...customer,
        name: job.name,
        address: job.address,
        phone: job.phone,
        email: job.email,
      });
      setAddress(job.address);
      setIsClosed(job.date_closed);
      // setPhone(job.phone);
      // setEmail(job.email);
      setLeadsListValue(job.leads_id?.split(","));
      setNoteArr(JSON.parse(job.note));
      job.payment_approval_code &&
        job.payment_approval_code != "null" &&
        setPaymentApprovalCode(job.payment_approval_code);
      console.log("Fetched Job Data:", job); // Log the entire job data
      console.log("Job Files (before split):", job.files); // Log the raw `files` data

      // Ensure job.files exists and is split into an array
      setAllFileArr(job.files ? job.files.split(",") : []);
      console.log(
        "AllFileArr (after split):",
        job.files ? job.files.split(",") : []
      );
      setSplitPayment(job.split_payment);
      setMoney2(job.money2);
      setIncludeTax2(job.include_tax2);
      setPayBy2(job.payby_id2);
      // console.log("getTasks", JSON.parse(job.tasks_info));
      setTasks(JSON.parse(job.tasks_info));
      setListId(job.list_id);
      setIsLoading(false);

      // Set initial address ref value to match the loaded job address
      previousAddressRef.current = job.address;
      // Mark initial data as loaded to enable address change tracking
      setIsInitialDataLoaded(true);
      ////hideconsole.log(job.parts ? JSON.parse(job.parts) : "");
    });
  };

  const fetchDiaryEntries = async (jobId) => {
    try {
      const response = await axios.get(
        `https://www.api.sendatradie.com/jobs/jobdiary/${jobId}`
      );
      if (response.data.success && response.data.diary) {
        // Flatten the session changes for display
        const entries = response.data.diary.flatMap((session) =>
          session.changes.map((change) => ({
            ...change,
            session: session.session,
            timestamp: new Date(session.timestamp).toISOString(), // Ensure consistent ISO format
          }))
        );
        setDiaryEntries(entries); // Set entries only from DB
      } else {
        setDiaryEntries([]);
      }
    } catch (error) {
      console.error("Error fetching diary entries:", error);
      setDiaryEntries([]);
      toast.error("Failed to load job diary entries.");
    }
  };

  useEffect(() => {
    if (id != null && show) {
      fetchDiaryEntries(id); // Only fetch from the database
    }
  }, [id, show]);

  useEffect(() => {
    if (id != null && show) {
      getData(); // Fetch other job details
      fetchDiaryEntries(id); // Fetch the diary entries
    }
  }, [id, show]);

  // save Files
  const saveFiles = (files) => {
    requestFromServer
      .addfiles(files, id)
      .then((res) => {
        const newFileList = res.data.files.split(",");
        const removedFiles = AllFileArr.filter(
          (file) => !newFileList.includes(file)
        );

        setAllFileArr(newFileList);

        // Create diary entry
        const newDiaryEntry = {
          job_id: id,
          company_id: companyId,
          data: [
            {
              type: "file",
              content: [
                // Add any new files
                ...newFileList
                  .filter((file) => !AllFileArr.includes(file))
                  .map((file) => ({
                    filename: file,
                    url: `https://storage.googleapis.com/platform-295512.appspot.com/${file}`,
                    status: "added",
                  })),
                // Add any removed files
                ...removedFiles.map((file) => ({
                  filename: file,
                  status: "removed",
                })),
              ],
              timestamp: new Date().toISOString(),
              user: userData.name || "System",
              saved: true,
            },
          ],
        };

        // Save diary entry
        axios
          .post("https://www.api.sendatradie.com/jobs/jobdiary/add", newDiaryEntry)
          .then((response) => {
            if (response.status === 200) {
              console.log("File diary entry saved successfully");
              setDiaryEntries((prev) => [...prev, newDiaryEntry.data[0]]);
            }
          })
          .catch((error) => {
            console.error("Error saving file diary entry:", error);
          });

        toast.success("File added.");
      })
      .catch((error) => {
        let data = error.message || error;
        if (error && error.response && error.response.data) {
          data = error.response.data.data;
        } else {
          data = "Can't update Job";
        }
        toast.error(data);
      });
  };

  useEffect(() => {
    const worker = workers?.toString("")?.split(",");
    if (worker?.[0] && worker.length == 1 && appointmentTime && id) {
      requestFromServer
        .fetchWorkersCalendar({
          user_id: worker[0],
          appointmentTime: moment
            .utc(moment.tz(appointmentTime, userData.time_zone))
            .format("yyyy-MM-DD HH:mm:ss"),
          jobID: id,
        })
        .then((res) => {
          setEventExist(res.data.eventExists);
          //hideconsole.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [workers, appointmentTime]);

  const handleSubmit = () => {
    //hideconsole.log(noteArr);
    //hideconsole.log("send_review is", sendReview);

    //combine new added tasks and existing
    // In handleSubmit()
    // Enhanced to handle task deletions properly
    let tasksToSave = [...(tasks || [])];

    // If we have a tasks array with deletion metadata (from TasksManagementDialog)
    if (tasks && tasks.deletedTaskIds && Array.isArray(tasks.tasks)) {
      // Get the set of IDs to remove
      const deletedIds = new Set(tasks.deletedTaskIds);

      // Filter out deleted tasks and use the updated tasks
      tasksToSave = tasks.tasks.filter(
        (task) => !task.id || !deletedIds.has(task.id)
      );
      console.log("Saving tasks with deletions applied:", tasksToSave);
    }

    // Make sure we have unique tasks
    const combinedTasks = _.uniqBy(
      tasksToSave,
      (task) => `${task.task}-${task.dueDate}-${task.assignedTo}`
    );

    const newChanges = diaryEntries.filter(
      (entry) =>
        // Only include entries that:
        // 1. Don't have a session property (regular entries)
        // 2. Don't have saved property (not saved directly like emails)
        // 3. Were created after the job was created
        !entry.session &&
        !entry.saved &&
        entry.timestamp > new Date(dateCreated).toISOString()
    );

    if (newChanges.length > 0) {
      const sessionEntry = {
        timestamp: new Date().toISOString(),
        user: userData.name || "System",
        changes: newChanges,
      };

      // Add session to diaryEntries
      setDiaryEntries((prev) => [
        ...prev.filter((entry) => entry.session),
        sessionEntry,
      ]);
    }
    // console.log("combined tasks", combinedTasks);

    const job = {
      id: id,
      user_id: workers,
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      job_type: jobTypes,
      job_status_id: jobStatus,
      company_comment: additionalInfo,
      tradie_comment: tradieComment,
      payby_id: payBy,
      money: money ? parseFloat(money) : 0,
      payment_approval_code: paymentApprovalCode,
      ScheduleAppointment: appointment,
      address: address,
      sms: sendSMS,
      local_appointment_time: appointmentTime,
      appointment_time: appointmentTime
        ? moment
            .utc(moment.tz(appointmentTime, userData.time_zone))
            .format("yyyy-MM-DD HH:mm:ss")
        : null,
      parts: JSON.stringify(partsData),
      include_tax: includeTax,
      lat: lat,
      lng: lng,
      send_review: sendReview,
      send_sms: sendSMS,
      leads_id: leadsListValue,
      changePercentageData: changePercentageData,
      note: JSON.stringify(noteArr),
      date_closed:
        jobStatus == 3 || jobStatus == 4 || jobStatus == 6
          ? moment().format()
          : "",
      modifyPercentage: showModifyPercentage ? modifyPercentageData : {},
      payBy2: payBy2,
      include_tax2: includeTax2,
      money2: splitPayment ? (money2 ? parseFloat(money2) : 0) : 0,
      splitPayment: splitPayment,
      credit_fee: payBy == 2 && creditCardFee,
      custom_fields: JSON.stringify(customFieldsData),
      tasks_info: JSON.stringify(combinedTasks),
      list_id: newListId,
      prev_list_id: listId ? listId : null, // here we set prev_list to keep track of changes
      suburbName: suburbName,
    };

    // console.log(" id in job", job.list_id);

    //hideconsole.log(job);
    //hideconsole.log(jobsValidation);

    if (jobsValidation.name && !job.name?.length) {
      toast.error("Name required.");
    } else if (jobsValidation.address && !job.address) {
      toast.error("Address required.");
    } else if (jobsValidation.workers && !job.user_id) {
      toast.error("Workers required.");
    } else if (jobsValidation.additional_info && !job.company_comment) {
      toast.error("Additional info required.");
    } else if (jobsValidation.email && !job.email) {
      toast.error("Email required.");
    } else if (jobsValidation.job_type && !job.job_type) {
      toast.error("Job type required.");
    } else if (jobsValidation.leads && !job.leads_id) {
      toast.error("Leads required.");
    } else if (jobsValidation.amount_due && !job.money) {
      toast.error("Money required.");
    } else if (jobsValidation.parts && job.parts?.length < 3) {
      toast.error("Parts required.");
    } else if (jobsValidation.payment_method && !payBy) {
      toast.error("Payment method required.");
    } else if (jobsValidation.tradie_comment && !job.tradie_comment) {
      toast.error("Tradie comment required.");
    } else if (jobsValidation.phone && !job.phone) {
      toast.error("Phone required.");
    } else if (job.money && !job.payby_id) {
      toast.error("Please select payment method.");
    } else if (
      job.ScheduleAppointment &&
      (!job.appointment_time || job.appointment_time == "Invalid date")
    ) {
      toast.warning("Please select an appointment time.");
    } else {
      setIsSubmitting(true);
      //hideconsole.log("handlsubmit");
      //hideconsole.log(job);
      requestFromServer
        .editJob(job)
        .then((res) => {
          //hideconsole.log(res);
          if (res.status == 200) {
            axios
              .post("https://www.api.sendatradie.com/jobs/jobdiary/add", {
                job_id: id,
                company_id: companyId,
                data: newChanges, // Pass all diary entries
              })
              .then((diaryRes) => {
                if (diaryRes.status === 200) {
                  console.log("Job diary entries saved successfully.");
                } else {
                  toast.warning("Job saved, but diary entries were not saved.");
                }
              })
              .catch((diaryError) => {
                console.error("Error saving job diary:", diaryError);
                toast.error("Failed to save job diary entries.");
              });

            if (res.data.data.includes("No SMS remains")) {
              toast.warning(res.data.data);
            } else {
              toast.success("Saved.");
            }
            if (history.location.pathname.includes("/Jobs/")) {
              history.push(history.location.pathname + "#onJobsChange");
            } else if (history.location.pathname == "/dashboard") {
              getAppointmentData();
            } else if (history.location.pathname.includes("/pipeline")) {
              // console.log("state change?", history.location.pathname);

              newGetListData();
            }
          } else {
            toast.error(res);
          }
          handleClose();
          setIsSubmitting(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.data);
          console.log(err);
          setIsSubmitting(false);
        });
    }

    // do request to server transfer task
    if (transferredTasks.length) {
      console.log(transferredTasks);
      requestFromServer
        .addTransferTask(transferredTasks)
        .then((response) => {
          // Check if response was successful
          if (response.status === 200 && response.data.success) {
            // console.log(response.data.message);

            // remove task from state
            setTransferredTasks([]);

            // Show toast notification for success
            // toast.success("Task transferred successfully!");

            // Remove task from this job only on successful transfer
          } else {
            console.error(response.data.message);
            // Show toast notification for failure
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "Error transferring task:",
            error.response ? error.response.data : error.message
          );
          // Show toast notification for error
          // toast.error("Error transferring task: " + (error.response ? error.response.data.message : error.message));
        });
    }
  };

  // useEffect(() => {
  //   console.log("tasks updated", tasks);
  // }, [tasks]);

  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);

    // Set a consistent height for all tabs
    setJobDiaryStyles((prevStyles) => ({
      ...prevStyles,
      height: "80vh", // Standard height for all tabs
    }));
  }

  const handleClose = () => {
    //hideconsole.log("handle close");
    onHide();
    setNote("");
    setNoteArr([]);
    setAllFileArr([]);
    setAddress("");
    setlat("");
    setlng("");
    setCompanyComment("");
    setTradieComment("");
    setMoney("");
    setTax(0);
    setIsInitialDataLoaded(false);
    setIsInitialTasksLoad(true);
    previousTasksRef.current = null;
    setTypeValue();
    setLeadsListValue([]);
    setPartBelong([]);
    setTypeValue([]);
    setIsEdit(false);
    setIdForNote("");
    setIsChangePercentage(false);
    setchangePercentageData([]);
    setTypeValue();
    setWorkerValue([]);
    setAppointmentTime("");
    setValue(0);
    setPartsData([]);
    setModifyPercentageData({});
    setShowModifyPercentage(false);
    setSendReview(0);
    // setCustomerData({})
    setPayBy(0);
    setPaymentApprovalCode("");
    setSplitPayment(false);
    setPayBy2(0);
    setMoney2(0);
    setIncludeTax2(0);
    setCreditCardFee(false);
  };

  useEffect(() => {
    const str = tempTamplateString;
    //hideconsole.log(str);

    const str2 = str.replace(
      "{Customer_Name}",
      customerData?.name ? customerData?.name : ""
    );
    const str3 = str2.replace(
      "{Customer_Phone}",
      customerData?.phone ? customerData?.phone : ""
    );
    const str4 = str3.replace(
      "{Customer_Address}",
      address ? address : customerForEdit?.address
    );
    const str5 = str4.replace(
      "{Additional_Information}",
      companyComment ? companyComment : ""
    );
    const str6 = str5.replaceAll("\r\n ", "");
    const str7 = str6.replaceAll(" \r\n", "");
    //hideconsole.log(str7);
    const countSegment = SmsCounter.count(str7);
    //hideconsole.log(countSegment);
    //hideconsole.log(address);
    setnumberCount({
      messageLength:
        countSegment.messages === 0
          ? 160
          : countSegment.encoding.includes("GSM")
          ? 160 * countSegment.messages
          : 67 * countSegment.messages,
      currentMsglength: countSegment.length,
      remainingLength: countSegment.remaining,
      MessageCount: countSegment.messages,
    });
  }, [
    tempTamplateString,
    TamplateString,
    companyComment,
    customerData,
    address,
  ]);

  const [partsBelongOptions, setPartsBelongOptions] = React.useState([
    { value: 0, label: "Company" },
  ]);

  useEffect(() => {
    //let temOptions = workers;
    //hideconsole.log("workers: ", workers);
    //hideconsole.log(workerOptions);

    setPartsBelongOptions(
      [{ value: 0, label: "Company" }].concat(
        workerOptions?.filter((item) =>
          workers?.split(",").includes(item.value.toString())
        )
      )
    );
  }, [workers]);

  useEffect(() => {
    if (fees?.tax) {
      let tax = includeTax
        ? money * (parseFloat(fees.tax) / 100)
        : money - money / (1 + parseFloat(fees.tax) / 100);
      setTax(tax);
      //hideconsole.log(creditCardFee);
      if (creditCardFee) {
        let creditFee = includeTax
          ? (parseFloat(money) + parseFloat(tax)) * (fees.credit_fee / 100)
          : money * (fees.credit_fee / 100);
        // //hideconsole.log(money + parseFloat(tax));
        // //hideconsole.log("Set cred");
        // //hideconsole.log(creditFee);
        setCreditCardFeeAmount(creditFee);
      } else {
        setCreditCardFeeAmount(0);
      }
    }
  }, [money, includeTax, fees, creditCardFee]);

  useEffect(() => {
    if (fees?.tax) {
      let tax = includeTax2
        ? money2 * (parseFloat(fees.tax) / 100)
        : money2 - money2 / (1 + parseFloat(fees.tax) / 100);
      setTax2(tax);
      //hideconsole.log(tax);
    }
  }, [money2, includeTax2, fees]);

  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  const [customFieldsData, setCustomFieldsData] = useState(null);

  useEffect(() => {
    axios
      .post("https://www.api.sendatradie.com/company/getCustomFields", {
        page_id: "/jobs",
      })
      .then((res) => {
        if (!JSON.parse(res.data.data[0]?.fields)) {
          setCustomFieldsArray([]);
          setCustomFieldsData([]);
        } else {
          if (!jobData?.custom_fields) {
            setCustomFieldsData(
              new Array(JSON.parse(res.data.data[0]?.fields)?.length).fill(-1)
            );
          } else if (jobData?.custom_fields) {
            let tempArr = JSON.parse(jobData?.custom_fields);

            while (
              tempArr.length != JSON.parse(res.data.data[0]?.fields).length
            )
              tempArr.push(-1);

            setCustomFieldsData(tempArr);
          }
          setCustomFieldsArray(JSON.parse(res.data.data[0]?.fields));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jobData]);

  useEffect(() => {
    //hideconsole.log("customFieldsData updated");
  }, [customFieldsData]);

  return (
    id != null && (
      <>
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <JobEditDialogHeader
            id={id}
            onHide={() => {
              handleClose();
            }}
          />
          <Paper>
            {isLoading ? (
              <>
                <Skeleton height={45} />
                <ModalBody>
                  <Skeleton height={15} />
                  <Skeleton height={45} className="mb-5" />
                  <Skeleton height={15} />
                  <Skeleton height={45} />
                  <Skeleton height={45} className="mb-5" />
                  <Skeleton height={15} />
                  <Skeleton height={45} className="mb-5" />
                  <Skeleton height={15} />
                  <Skeleton height={45} className="mb-5" />
                </ModalBody>
              </>
            ) : (
              <>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="auto"
                  onChange={handleChange}
                >
                  <Tab label="Job"></Tab>
                  <Tab label="Customer"></Tab>
                  <Tab label="Files"></Tab>
                  <Tab label="Notes"></Tab>
                  <Tab label="Tasks"></Tab>
                </Tabs>
                <div style={{ height: "60vh", overflow: "auto" }}>
                  {value === 0 && (
                    <JobEditForm
                      jobId={id}
                      listFromTrello={listFromTrello}
                      setNewListId={setNewListId}
                      currentListId={listId}
                      closedBy={closedBy}
                      dateCreated={dateCreated}
                      customFieldsData={customFieldsData}
                      setCustomFieldsData={setCustomFieldsData}
                      customFieldsArray={customFieldsArray}
                      setCustomFieldsArray={setCustomFieldsArray}
                      IsGsmCOuntComment={IsGsmCOuntComment}
                      setIsGsmCOuntComment={setIsGsmCOuntComment}
                      msgCountAsNumComment={msgCountAsNumComment}
                      setmsgCountAsNumComment={setmsgCountAsNumComment}
                      commentCount={commentCount}
                      setCommentCount={setCommentCount}
                      setnumberCount={setnumberCount}
                      numberCount={numberCount}
                      handleClose={handleClose}
                      setLeadsListValue={setLeadsListValue}
                      setLeadsList={setLeadsList}
                      leadsListValue={leadsListValue}
                      leadsList={leadsList}
                      setShowLead={setShowLead}
                      showLead={showLead}
                      type={type}
                      setType={setType}
                      view={view}
                      setView={setView}
                      worker={worker}
                      setWorker={setWorker}
                      typeValue={typeValue}
                      setTypeValue={setTypeValue}
                      setPartBelong={setPartBelong}
                      partBelong={partBelong}
                      setPartsValue={setPartsValue}
                      partsValue={partsValue}
                      setTax={setTax}
                      tax={fees?.tax ?? 0}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      money={money}
                      setMoney={setMoney}
                      setTradieComment={setTradieComment}
                      tradieComment={tradieComment}
                      companyComment={companyComment}
                      setCompanyComment={setCompanyComment}
                      workerValue={workerValue}
                      setWorkerValue={setWorkerValue}
                      actionsLoading={actionsLoading}
                      customer={
                        customerForEdit || customersUIProps.initCustomer
                      }
                      onHide={onHide}
                      data={data}
                      note={note}
                      check={check}
                      setCheck={setCheck}
                      setCustomerData={setCustomerData}
                      address={address ? address : customerForEdit?.address}
                      changePercentageData={changePercentageData}
                      setchangePercentageData={setchangePercentageData}
                      IsChangePercentage={IsChangePercentage}
                      setIsChangePercentage={setIsChangePercentage}
                      sendReview={sendReview}
                      setSendReview={setSendReview}
                      partsOptions={partsOptions}
                      partsData={partsData}
                      setPartsData={setPartsData}
                      handleSubmit={handleSubmit}
                      jobStatusOptions={jobStatusOptions}
                      setJobStatus={setJobStatus}
                      statusOptions={status}
                      jobStatus={jobStatus}
                      payBy={payBy}
                      setPayBy={setPayBy}
                      workerOptions={workerOptions}
                      workers={workers}
                      setWorkers={setWorkers}
                      jobTypes={jobTypes}
                      jobTypeOptions={jobTypeOptions}
                      setJobTypes={setJobTypes}
                      includeTax={includeTax}
                      setIncludeTax={setIncludeTax}
                      sendSMS={sendSMS}
                      setSendSMS={setSendSMS}
                      appointment={appointment}
                      setAppointment={setAppointment}
                      additionalInfo={additionalInfo}
                      setAdditionalInfo={setAdditionalInfo}
                      appointmentTime={appointmentTime}
                      setAppointmentTime={setAppointmentTime}
                      partsBelongOptions={partsBelongOptions}
                      showModifyPercentage={showModifyPercentage}
                      setShowModifyPercentage={setShowModifyPercentage}
                      modifyPercentageData={modifyPercentageData}
                      setModifyPercentageData={setModifyPercentageData}
                      paymentApprovalCode={paymentApprovalCode}
                      setPaymentApprovalCode={setPaymentApprovalCode}
                      isSubmitting={isSubmitting}
                      splitPayment={splitPayment}
                      payBy2={payBy2}
                      money2={money2}
                      includeTax2={includeTax2}
                      setSplitPayment={setSplitPayment}
                      setPayBy2={setPayBy2}
                      setMoney2={setMoney2}
                      setIncludeTax2={setIncludeTax2}
                      creditCardFee={creditCardFee}
                      setCreditCardFee={setCreditCardFee}
                      creditCardFeeAmount={fees?.credit_fee ?? 0}
                      tax2={tax2}
                      setPartsOptions={setPartsOptions}
                    />
                  )}
                  {value === 1 && (
                    <JobTabDialog
                      customFieldsData={customFieldsData}
                      setSuburbName={setSuburbName}
                      setCustomFieldsData={setCustomFieldsData}
                      customFieldsArray={customFieldsArray}
                      setCustomFieldsArray={setCustomFieldsArray}
                      partBelong={partBelong}
                      check={check}
                      setCheck={setCheck}
                      partsValue={partsValue}
                      leadsListValue={leadsListValue}
                      typeValue={typeValue}
                      tax={tax}
                      workerValue={workerValue}
                      IsGsmCOuntComment={IsGsmCOuntComment}
                      setIsGsmCOuntComment={setIsGsmCOuntComment}
                      msgCountAsNumComment={msgCountAsNumComment}
                      setmsgCountAsNumComment={setmsgCountAsNumComment}
                      commentCount={commentCount}
                      setCommentCount={setCommentCount}
                      name={customerName}
                      phone={customerData?.phone || customerForEdit?.phone}
                      email={customerData?.email || customerForEdit?.email}
                      setName={setCustomerName}
                      setnumberCount={setnumberCount}
                      numberCount={numberCount}
                      // saveCustomer={saveCustomer}
                      actionsLoading={actionsLoading}
                      customer={customer}
                      onHide={handleClose}
                      setCustomer={setCustomer}
                      note={note}
                      companyComment={companyComment}
                      setAddress={setAddress}
                      // address={address}
                      address={address ? address : customerForEdit?.address}
                      setlat={setlat}
                      setlng={setlng}
                      changePercentageData={changePercentageData}
                      lat={lat ? lat : customerForEdit?.latitude}
                      lng={lng ? lng : customerForEdit?.longitude}
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                    />
                  )}
                  {value === 2 && (
                    <FileTabDialog
                      customFieldsData={customFieldsData}
                      setCustomFieldsData={setCustomFieldsData}
                      customFieldsArray={customFieldsArray}
                      setCustomFieldsArray={setCustomFieldsArray}
                      actionsLoading={actionsLoading}
                      customer={
                        customerForEdit || customersUIProps.initCustomer
                      }
                      onHide={handleClose}
                      saveFiles={saveFiles}
                      setAllFileArr={setAllFileArr}
                      AllFileArr={AllFileArr}
                      saveJob={handleSubmit}
                      id={id}
                      isSubmitting={isSubmitting}
                      updateDiaryEntry={updateDiaryEntry}
                      companyId={companyId}
                    />
                  )}
                  {value === 3 && (
                    <>
                      <NotesTab
                        customFieldsData={customFieldsData}
                        setCustomFieldsData={setCustomFieldsData}
                        customFieldsArray={customFieldsArray}
                        setCustomFieldsArray={setCustomFieldsArray}
                        setNote={setNote}
                        note={note}
                        actionsLoading={actionsLoading}
                        onHide={handleClose}
                        customerData={customerData || customerForEdit}
                        //saveCustomer={saveCustomer}
                        workerOptions={workerOptions}
                        noteArr={noteArr}
                        setNoteArr={setNoteArr}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        idForNote={idForNote}
                        setIdForNote={setIdForNote}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                      />
                    </>
                  )}
                  {value === 4 && (
                    <TasksTab
                      tasksData={tasks}
                      setUpdatedTasks={setTasks}
                      companyId={companyId}
                      customerId={customerId}
                      setTasksToTransfer={setTransferredTasks}
                    />
                  )}
                </div>

                {!isClosed ||
                rolePermissions
                  ?.find((item) => item.permission_id == 14)
                  ?.roles?.includes(userData.group_id?.toString()) ? (
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => setShowSmsModal(true)}
                      className="btn btn-success me-2"
                    >
                      <i className="bi bi-chat-dots me-2"></i>
                      SMS Client
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowEmailModal(true)}
                      className="btn btn-info me-2"
                    >
                      <i className="bi bi-envelope me-2"></i>
                      Email Client
                    </button>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-light btn-elevate"
                    >
                      Cancel
                    </button>
                    <> </>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="btn btn-primary btn-elevate"
                    >
                      {!isSubmitting && "Save"}
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ml-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </Modal.Footer>
                ) : (
                  ""
                )}
              </>
            )}
            {/* Job Diary */}
            <div
              style={{
                ...jobDiaryStyles,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* Job Diary Header */}
              <div
                style={{
                  flexShrink: 0,
                  backgroundColor: "#D0E8FF", // Light mellow green background
                  borderBottom: "2px solid #A3C9F7", // Subtle green border
                  padding: "15px 20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Subtle shadow for separation
                }}
              >
                <h5
                  style={{
                    fontWeight: "600",
                    marginBottom: "0",
                    fontSize: "16px", // A rich green for the header text
                  }}
                >
                  Job Diary
                </h5>
                {/* Search Box */}
                <div style={{ position: "relative", marginTop: "8px" }}>
                  <input
                    type="text"
                    placeholder="Search Entries"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px 15px 10px 40px",
                      borderRadius: "8px",
                      border: "1px solid #A3C9F7", // Light green border
                      backgroundColor: "#EDF6FF", // Subtle off-green background
                      fontSize: "14px", // A darker green for text
                      fontFamily: "inherit", // Keep the font consistent
                      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Subtle shadow
                    }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      left: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#5A9BD5", // Green for the search icon
                    }}
                  />
                </div>

                <div style={{ marginTop: "8px" }}>
                  <Select
                    value={{
                      value: filterQuery,
                      label: entryTypeLabels[filterQuery] || "All Entry Types",
                    }}
                    onChange={(option) => setFilterQuery(option?.value || "")}
                    options={[
                      { value: "", label: "All Entry Types" },
                      { value: "address", label: "Address" },
                      { value: "email", label: "Email Messages" },
                      { value: "file", label: "File" },
                      { value: "note", label: "Note" },
                      { value: "review", label: "Review" },
                      { value: "schedule", label: "Schedule" },
                      { value: "sms", label: "SMS" },
                      { value: "status", label: "Status" },
                      { value: "task", label: "Task" },
                    ]}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: "#EDF6FF",
                        borderColor: "#A3C9F7",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isSelected
                          ? "#5A9BD5"
                          : state.isFocused
                          ? "#EDF6FF"
                          : "white",
                        color: state.isSelected ? "white" : "#333",
                      }),
                    }}
                  />
                </div>
              </div>

              {/* Scrollable Entries */}
              <div
                style={{
                  flexGrow: 1,
                  overflowY: "auto",
                  padding: "20px",
                  backgroundColor: "#EDF6FF", // Light greenish background
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {/* Group entries by date */}
                {Object.entries(
                  // Remove duplicate entries by using the entryId or a combination of type and timestamp
                  _.uniqBy(
                    diaryEntries || [],
                    (entry) =>
                      entry.entryId || `${entry.type}-${entry.timestamp}`
                  )
                    .filter((entry) => {
                      const searchTerm = searchQuery.toLowerCase();
                      const filterTerm = filterQuery.toLowerCase();

                      // If both search and filter are empty, show all entries
                      if (!searchTerm && !filterTerm) return true;

                      // Determine if the entry matches the filter term (type filtering)
                      const matchesFilter =
                        !filterTerm || entry.type === filterTerm;

                      // Search in content and convert objects to searchable strings
                      let searchableContent = "";

                      // Handle different content types
                      if (
                        entry.type === "task" &&
                        Array.isArray(entry.content)
                      ) {
                        searchableContent = entry.content
                          .map(
                            (task) =>
                              `${task.taskName} ${task.assignedTo} ${task.dueDate}`
                          )
                          .join(" ");
                      } else if (
                        entry.type === "file" &&
                        Array.isArray(entry.content)
                      ) {
                        searchableContent = entry.content
                          .map((file) => file.filename)
                          .join(" ");
                      } else {
                        searchableContent = entry.content?.toString() || "";
                      }

                      // Include entry type in searchable content for general searches
                      searchableContent = `${entry.type} ${searchableContent}`.toLowerCase();

                      // Determine if the entry matches the search term
                      const matchesSearch =
                        !searchTerm || searchableContent.includes(searchTerm);

                      // Return true if the entry matches both the search and filter criteria
                      return matchesFilter && matchesSearch;
                    })
                    // Sort entries by timestamp (newest first) before grouping
                    .sort(
                      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                    )
                    .reduce((groupedEntries, entry) => {
                      const date = new Date(entry.timestamp).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      );
                      groupedEntries[date] = groupedEntries[date] || [];
                      groupedEntries[date].push(entry);
                      return groupedEntries;
                    }, {})
                )
                  // Sort dates in reverse chronological order (newest first)
                  .sort(([dateA], [dateB]) => {
                    return new Date(dateB) - new Date(dateA);
                  })
                  .map(([date, entries]) => (
                    <div key={date}>
                      {/* Date Header */}
                      <div
                        style={{
                          marginBottom: "10px",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          backgroundColor: "#B3D9FF", // Subtle green header background
                          fontSize: "14px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {date}
                      </div>

                      {/* Entries for the date (newest entries first) */}
                      {entries
                        .sort(
                          (a, b) =>
                            new Date(b.timestamp) - new Date(a.timestamp)
                        )
                        .map((entry, index) => (
                          <div
                            key={index}
                            style={{
                              padding: "15px",
                              borderRadius: "10px",
                              backgroundColor: "#FFFFFF",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              marginBottom: "10px",
                              transition: "all 0.2s ease-in-out",
                              borderLeft: `4px solid ${
                                entry.type === "review"
                                  ? "#FFD700" // Gold for reviews
                                  : entry.type === "schedule"
                                  ? "#4CAF50" // Green for schedule
                                  : entry.type === "status"
                                  ? "#2196F3" // Blue for status
                                  : entry.type === "note"
                                  ? "#9C27B0" // Purple for notes
                                  : entry.type === "task"
                                  ? "#FF9800" // Orange for tasks
                                  : entry.type === "file"
                                  ? "#795548" // Brown for files
                                  : entry.type === "sms"
                                  ? "#00BCD4" // Cyan for SMS
                                  : entry.type === "email"
                                  ? "#E91E63" // Pink for email
                                  : "#607D8B" // Blue grey for default
                              }`,
                              cursor: "pointer",
                              position: "relative",
                              overflow: "hidden",
                              "&:hover": {
                                transform: "translateY(-2px)",
                                boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                              },
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.transform =
                                "translateY(-2px)";
                              e.currentTarget.style.boxShadow = `0 5px 15px rgba(0,0,0,0.1), 0 0 0 1px ${
                                entry.type === "review"
                                  ? "#FFD700"
                                  : entry.type === "schedule"
                                  ? "#4CAF50"
                                  : entry.type === "status"
                                  ? "#2196F3"
                                  : entry.type === "note"
                                  ? "#9C27B0"
                                  : entry.type === "task"
                                  ? "#FF9800"
                                  : entry.type === "file"
                                  ? "#795548"
                                  : entry.type === "sms"
                                  ? "#00BCD4"
                                  : entry.type === "email"
                                  ? "#E91E63"
                                  : "#607D8B"
                              }40`; // 40 here is for opacity
                              e.currentTarget.style.backgroundColor = "#F8F9FA";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.transform = "translateY(0)";
                              e.currentTarget.style.boxShadow =
                                "0px 2px 4px rgba(0, 0, 0, 0.1)";
                              e.currentTarget.style.backgroundColor = "#FFFFFF";
                            }}
                          >
                            {/* Timestamp with Live indicator */}
                            <div
                              style={{
                                fontSize: "12px",
                                color: "#5A9BD5",
                                fontWeight: "500",
                                fontStyle: "italic",
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {!entry.session &&
                              !(
                                entry.type === "email" || entry.type === "sms"
                              ) &&
                              !entry.saved && ( // Changed from entry.saved to !entry.saved
                                  <span
                                    style={{
                                      backgroundColor: "#4CAF50",
                                      color: "white",
                                      padding: "2px 8px",
                                      borderRadius: "12px",
                                      fontStyle: "normal",
                                    }}
                                  >
                                    UNSAVED
                                  </span>
                                )}
                              {new Date(entry.timestamp).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }
                              )}
                            </div>
                            {/* Entry Icon and Content */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {/* Icon based on entry type */}
                              <i
                                className={`bi ${
                                  entry.type === "review"
                                    ? "bi-star-fill"
                                    : entry.type === "schedule"
                                    ? "bi-calendar3"
                                    : entry.type === "status"
                                    ? "bi-tag-fill"
                                    : entry.type === "note"
                                    ? "bi bi-journal-text"
                                    : entry.type === "task"
                                    ? "bi-card-checklist"
                                    : entry.type === "file"
                                    ? "bi-file-earmark"
                                    : entry.type === "sms"
                                    ? "bi bi-chat-left-dots"
                                    : entry.type === "welcome"
                                    ? "bi bi-journal-bookmark-fill"
                                    : entry.type === "address"
                                    ? "bi-geo-alt-fill"
                                    : entry.type === "email"
                                    ? "bi-envelope-arrow-up" // Email-specific icon
                                    : "bi-info-circle" // fallback icon
                                }`}
                                style={{ fontSize: "18px", color: "#5A9BD5" }}
                              ></i>

                              {/* Content */}
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#3F4254",
                                  lineHeight: "1.6",
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                }}
                              >
                                {entry.type === "file" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "8px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Files updated
                                    </div>
                                    {(Array.isArray(entry.content)
                                      ? entry.content
                                      : []
                                    ).map((file, idx) => (
                                      <div key={idx}>
                                        <img
                                          src={file.url}
                                          alt={file.filename}
                                          style={{
                                            width: "70%",
                                            height: "auto",
                                            borderRadius: "4px",
                                            opacity:
                                              file.status === "removed"
                                                ? 0.5
                                                : 1, // Add opacity for removed files
                                            filter:
                                              file.status === "removed"
                                                ? "grayscale(100%)"
                                                : "none", // Add grayscale for removed files
                                          }}
                                        />
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color:
                                              file.status === "removed"
                                                ? "#dc3545"
                                                : "#666",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                          }}
                                        >
                                          {file.status === "removed" && (
                                            <i className="bi bi-x-circle"></i>
                                          )}
                                          {file.filename}{" "}
                                          {file.status === "removed" &&
                                            "removed"}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : entry.type === "task" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "8px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Tasks
                                    </div>

                                    {(Array.isArray(entry.content)
                                      ? entry.content
                                      : []
                                    ).map((task, idx) => (
                                      <div
                                        key={idx}
                                        style={{
                                          padding: "8px",
                                          backgroundColor: "white",
                                          borderRadius: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {/* Task Name */}
                                        <div style={{ fontWeight: "500" }}>
                                          {task.taskName}
                                        </div>

                                        {/* Due Date & Assigned Worker (on separate lines) */}
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color: "#666",
                                            marginTop: "4px",
                                          }}
                                        >
                                          <div>Due: {task.dueDate}</div>
                                          <div>
                                            Assigned to: {task.assignedTo}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : entry.type === "sms" ? (
                                  typeof entry.content === "string" ? (
                                    entry.content
                                      .split("\n")
                                      .filter(
                                        (line) =>
                                          !line
                                            .trim()
                                            .toLowerCase()
                                            .startsWith("to reply:")
                                      )
                                      .join("\n")
                                  ) : (
                                    entry.content
                                  )
                                ) : (
                                  entry.content?.element || entry.content
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            </div>
          </Paper>
          <CustomAlertModal
            title={`${
              workerOptions?.find((obj) => obj.value == workers)?.label
            } might be busy`}
            message={`An existing event found on the calendar of ${
              workerOptions?.find((obj) => obj.value == workers)?.label
            } at the appointment time.`}
            handleSubmit={() => {
              setEventExist(false);
            }}
            show={eventExists}
            handleClose={() => setEventExist(false)}
            submitText="Confirm"
            hide
            Cancel={true}
          />
        </Modal>
        <EmailModal
          show={showEmailModal}
          onHide={() => setShowEmailModal(false)}
          customerEmail={customer?.email}
          customerName={customer?.name}
          customerPhone={customer?.phone}
          id={id}
          companyId={companyId}
          appointmentTime={appointmentTime}
          jobStatus={jobStatus}
          address={address}
          additionalInfo={additionalInfo}
          money={money}
          payBy={payBy}
          companyComment={companyComment}
          tradieComment={tradieComment}
          updateDiaryEntry={updateDiaryEntry}
        />
        <SMSModal
          show={showSmsModal}
          onHide={() => setShowSmsModal(false)}
          customerPhone={customer?.phone}
          customerName={customer?.name}
          customerEmail={customer?.email}
          id={id}
          companyId={companyId}
          jobStatus={jobStatus}
          appointmentTime={appointmentTime}
          moneyDue={money}
          paymentMethod={payBy}
          additionalInfo={additionalInfo}
          companyComment={companyComment}
          tradieComment={tradieComment}
          address={address}
          updateDiaryEntry={updateDiaryEntry}
        />
      </>
    )
  );
}
