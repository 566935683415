import React, {
  Component,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import loadable from "@loadable/component";
import { SuburbRevenue } from "../../widgets/suburbRevenue";
import { Spinner, Button, ButtonToolbar } from "react-bootstrap";
import axios from "axios";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import { updateDataSettingRequest } from "../../../../_metronic/_partials/axios/Requests";
import { connect, useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";

import "react-grid-layout/css/styles.css";
import * as requestFromServer from "../../../../app/modules/Jobs/_redux/customers/customersCrud";
import { useDispatch } from "react-redux";
import { fetchRecurrentJobToggleValue } from "../../../../app/modules/Auth/_redux/settingAction";
import Coupon from "../../widgets/Coupon";
import { ContactsListCustomerLoad } from "../../../../redux/slice/contactsListSlice";
import { ContactsListUserLoad } from "../../../../redux/slice/contactsListSlice";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { NewWebLeads } from "../../widgets/NewWebLeads/NewWebLeads";
import OnboardingProgress from "../../../layout/components/header/OnboardingProgress";
import moment from "moment";

// Dashboard-specific styles
const dashboardStyles = `
  /* Make entire widget draggable only when not locked and on hover */
  .dashboard-container .react-grid-item {
    cursor: default !important;
  }
  
  /* Show draggable cursor on hover only when unlocked */
  .dashboard-container:not(.locked) .react-grid-item:hover {
    cursor: move !important;
  }

  /* Reset cursor for interactive elements */
  .dashboard-container .react-grid-item .lock-button,
  .dashboard-container .react-grid-item button,
  .dashboard-container .react-grid-item input,
  .dashboard-container .react-grid-item select,
  .dashboard-container .react-grid-item .btn,
  .dashboard-container .react-grid-item .react-select,
  .dashboard-container .react-grid-item a {
    cursor: pointer !important;
  }

  /* Add padding to lock button */
  .dashboard-container .react-grid-item .lock-button {
    padding: 12px !important;
  }

  /* Only show hover effect when widget is being dragged */
  .dashboard-container .react-grid-item.react-draggable-dragging {
    background-color: rgba(0, 0, 0, 0.02);
  }

  /* Show hover effect only on the draggable handle area */
  .dashboard-container .react-grid-item .block.card {
    transition: background-color 0.2s;
  }
  
  .dashboard-container .react-grid-item .block.card:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const JobStatusGraph = loadable(() =>
  import("../../widgets/jobs-status-graph/JobStatusGraph")
);
const Timesheet = loadable(() => import("../../widgets/timesheet"));
const TopLeads = loadable(() => import("../../widgets/topLeads"));
const TopJobTypes = loadable(() => import("../../widgets/topJobTypes"));
const AppointmentCalendar = loadable(() =>
  import("../../widgets/appointmentsCalendar")
);

const RevenueGraph = loadable(() => import("../../widgets/revenueGraph"));
const AdminDashboard = loadable(() => import("../../widgets/Admin"));
const MonthlyRevenue = loadable(() => import("../../widgets/MonthlyRevenue"));
const Timeline = loadable(() => import("../../widgets/Timeline"));
const TestWidget = loadable(() => import("../../widgets/GMB"));
// const GoogleSearch = loadable(() => import("../../widgets/GoogleSearch"));
const EmployeeEfficiency = loadable(() =>
  import("../../widgets/EmployeeEfficiency")
);

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// *Dashboard
function Dashboard(props) {
  console.log("=== DASHBOARD PROPS ===", {
    settings: JSON.stringify(props.settings?.settings),
    rolePermissions: JSON.stringify(props.rolePermissions),
  });

  const [gmbLoggedIn, setGmbLoggedIn] = useState(false); // Default to false

  useEffect(() => {
    axios
      .post(
        "https://www.api.sendatradie.com/company/googleMyBusinessLocations"
      )
      .then((res) => {
        if (!res.data || !res.data.data || res.data.data.length === 0) {
          console.log("❌ No GMB data received, assuming NOT logged in.");
          setGmbLoggedIn(false);
          return;
        }

        const locationData = res.data.data[0];
        const locationIds = locationData?.location_ids
          ? JSON.parse(locationData.location_ids)
          : [];

        if (!Array.isArray(locationIds) || locationIds.length === 0) {
          console.log("❌ No GMB locations found, setting to NOT logged in.");
          setGmbLoggedIn(false);
          return;
        }

        console.log("✅ GMB Locations Found:", locationIds);
        setGmbLoggedIn(true);
      })
      .catch((err) => {
        console.log("🚨 GMB API Error, assuming NOT logged in:", err);
        setGmbLoggedIn(false); // Handle API failure gracefully
      });
  }, []);

  //hideconsole.log("Dashboard props.settings", props.settings);
  const [items, setItems] = useState([]);
  const [mounted, setmounted] = useState(false);
  const [workersOptions, setWorkersOpitons] = useState([]);
  const [workerFetched, setWorkerFetched] = useState(false);
  const [timelineKey, setTimelineKey] = useState(0);

  const iso = useSelector((state) => state.auth.user.iso);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Role Permissions:", props.rolePermissions);
  }, [props.rolePermissions]);
  useEffect(() => {
    dispatch(fetchRecurrentJobToggleValue());
    dispatch(ContactsListCustomerLoad());
    dispatch(ContactsListUserLoad());
    dispatch(auth.actions.userCountryCode(iso));
  }, []);

  const onDrop = (elemParams) => {
    alert(
      `Element parameters:\n${JSON.stringify(
        elemParams,
        ["x", "y", "w", "h"],
        2
      )}`
    );
  };

  useEffect(() => {
    requestFromServer
      .getWorkerOptions()
      .then((response) => {
        setWorkerFetched(true);
        //hideconsole.log(response.data);
        if (response.data.result) {
          response.data.result.unshift({ value: 0, label: "Unassigned" });
          setWorkersOpitons(response.data.result);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    if (!props.settings || !props.userData || !props.rolePermissions) return;

    // Add test widget to settings if it doesn't exist
    // if (!props.settings.settings) {
    //   props.settings.settings = {};
    // }

    // props.settings.settings.test_widget = {
    //   active: 1,
    //   layout: {
    //     x: 0,
    //     y: 0,
    //     w: 6,
    //     h: 4,
    //     i: "test_widget",
    //     minW: 3,
    //     minH: 3,
    //   },
    // };

    let arr = [];

    function getComponent(key) {
      // //hideconsole.log(key);
      switch (key) {
        case "suburb_revenue":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 8)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <SuburbRevenue />;
          }
          break;
        case "job_status":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 3)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            //hideconsole.log("JobStatGraph switch worked");
            return <JobStatusGraph />;
          }
          break;
        case "revenue":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 2)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <RevenueGraph />;
          }
          break;
        case "top_job_types":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 5)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <TopJobTypes />;
          }
          break;
        case "appointments":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 4)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <AppointmentCalendar />;
          }
          break;
        case "top_leads":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 6)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <TopLeads />;
          }
          break;
        case "google_my_business_reviews":
          console.log({
            gmbLoggedIn,
            userGroupId: props.userData?.group_id,
          });
          if (gmbLoggedIn) {
            // Only checks gmbLoggedIn
            return <TestWidget />;
          }
          break;
        case "timesheet":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 7)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <Timesheet />;
          }
          break;
        case "monthly_revenue":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 8)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <MonthlyRevenue />;
          }
          break;
        case "admin":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 8)
              ?.roles?.includes(props.userData?.group_id?.toString()) &&
            props.userData?.company_id == 2
          ) {
            return <AdminDashboard workersOptions={workersOptions} />;
          }
          break;
        case "timeline":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 16)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <Timeline key={timelineKey} />;
          }
          break;
        case "coupon":
          return <Coupon />;
          break;
        case "webleads":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 17)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <NewWebLeads />;
          }
          break;
        // case "google_search":
        //   if (
        //     props.rolePermissions
        //       ?.find((item) => item.permission_id == 17)
        //       ?.roles?.includes(props.userData?.group_id?.toString())
        //   ) {
        //     return <GoogleSearch />;
        //   }
        //   break;
        case "employee_efficiency":
          if (props?.settings?.settings?.employee_efficiency) {
            if (!props.settings.settings.employee_efficiency.layout) {
              props.settings.settings.employee_efficiency.layout = {};
            }

            // // Set width to full screen and proper height
            // props.settings.settings.employee_efficiency.layout = {
            //   ...props.settings.settings.employee_efficiency.layout,
            //   h: 15,
            //   i: "employee_efficiency",
            //   w: 12,
            //   minH: 10,
            //   minW: 12,
            //   moved: false,
            //   static: false,
            // };
          }
          return <EmployeeEfficiency />;
          break;
      }
    }

    for (let key in props?.settings?.settings) {
      const component = getComponent(key);
      if (props?.settings?.settings[key]?.active && component) {
        let obj = {
          id: key,
          content: component,
          layout: props?.settings?.settings[key].layout,
        };
        arr.push(obj);
      }
    }

    console.log(arr);
    setItems([...arr]);
  }, [props.settings, props.rolePermissions, props.userData, gmbLoggedIn]);

  useEffect(() => {
    console.log("check timelineKey stoped", timelineKey);
  }, [timelineKey]);

  function onLayoutChange(e) {
    //hideconsole.log(e);
    if (!props?.settings?.draggingData?.isDragDisabled) {
      let settings = { ...props.settings.settings };
      let location = localStorage.getItem("Location");
      e.forEach((obj) => {
        settings[obj.i].layout = obj;
      });
      updateDataSettingRequest(location, settings);
    }
  }

  function handleResizeStop(e) {
    console.log("stoped resize");
    setTimelineKey((prevKey) => prevKey + 1);
  }

  const userData = useSelector((state) => state.auth.user);

  return (
    <>
      {/* Add dashboard-specific styles */}
      <style>{dashboardStyles}</style>

      <div
        className={`h-100 tour_dashboard dashboard-container ${
          props?.settings?.draggingData?.isDragDisabled ? "locked" : ""
        }`}
      >
        {/* {moment
          .duration(moment().diff(moment(userData.register_date)))
          .asDays() <= 15 && userData.group_id == 1 ? (
          <OnboardingProgress />
        ) : (
          ""
        )} */}

        {workerFetched ? (
          <ResponsiveReactGridLayout
            rowHeight={30}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            onLayoutChange={onLayoutChange}
            onDrop={onDrop}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
            isDroppable={true}
            droppingItem={{ i: "xx", h: 50, w: 250 }}
            isDraggable={!props?.settings?.draggingData?.isDragDisabled}
            isResizable={!props?.settings?.draggingData?.isDragDisabled}
            draggableHandle=".block"
            draggableCancel=".lock-button, .btn, .react-select, input, select, a, button"
            onResizeStop={handleResizeStop}
          >
            {items.map((item, i) => {
              if (item.id == "timeline") {
                item.layout.minH = workersOptions.length;
                if (item.layout.minH > item.layout.h) {
                  item.layout.h = workersOptions.length;
                }
                // item.layout.
                //hideconsole.log(item);
              }
              return (
                <div
                  key={item.id}
                  data-grid={item.layout}
                  style={
                    {
                      // cursor: props?.settings?.draggingData?.isDragDisabled
                      //   ? "default"
                      //   : "move",
                      // ...(item.id === "google_my_business_reviews"
                      //   ? {
                      //       height: "auto !important",
                      //       overflowY: "auto",
                      //       maxHeight: "100vh",
                      //       maxWidth: "1000px", // Add this line to limit width
                      //       margin: "0 auto",
                      //       position: "relative",
                      //     }
                      //   : {}),
                    }
                  }
                  className={`block card ${item.id}`}
                >
                  {/* <div
                    style={
                      item.id === "google_my_business_reviews"
                        ? {
                            position: "relative",
                            height: "100%",
                            maxHeight: "100%",
                          }
                        : { maxHeight: "100%" }
                    }
                  > */}
                  {item.content}
                  {/* </div> */}
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        ) : (
          <div className="h-100">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" variant="primary" size="xl" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    draggingData: state.draggingData,
    rolePermissions: state.rightSettings.rolePermissions,
    userData: state.auth.user,
  };
};
export default connect(mapStateToProps, { saveSetting, saveDraggable })(
  Dashboard
);
