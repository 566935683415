import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import CustomBootstrapTable from "../../../_metronic/_helpers/CustomBootstrapTable";

export default function AddUrlPrefix() {
  const [urlPrefix, setUrlPrefix] = useState("");
  const [verificationMethod, setVerificationMethod] = useState("META");
  const [loadingToken, setLoadingToken] = useState(false);
  const [token, setToken] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);

  // State for verified properties table
  const [verifiedProperties, setVerifiedProperties] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const tableRef = React.useRef();

  // Function to fetch verified properties from the backend
  const fetchVerifiedProperties = useCallback(async () => {
    setTableLoading(true);
    try {
      const response = await axios.get(
        "https://www.api.sendatradie.com/company/fetchVerifiedProperties"
      );
      if (response.data.success) {
        setVerifiedProperties(response.data.data);
      } else {
        toast.error(
          response.data.message || "Failed to fetch verified properties."
        );
      }
    } catch (err) {
      console.error("Error fetching verified properties", err);
      toast.error("Failed to fetch verified properties");
    } finally {
      setTableLoading(false);
    }
  }, []);

  // Refresh verified properties on mount
  useEffect(() => {
    fetchVerifiedProperties();
  }, [fetchVerifiedProperties]);

  const handleGetToken = async () => {
    if (!urlPrefix) {
      toast.error("URL prefix is required");
      return;
    }
    setLoadingToken(true);
    try {
      const response = await axios.post(
        "https://www.api.sendatradie.com/company/getVerificationToken",
        { urlPrefix, verificationMethod }
      );
      console.log("Token response:", response.data);
      if (response.data.success) {
        setToken(response.data.token);
        toast.success(
          "Verification token retrieved. Please add it to your site."
        );
      } else {
        toast.error(
          response.data.message || "Failed to get verification token."
        );
      }
    } catch (err) {
      console.error("Error getting token:", err);
      toast.error("Failed to get verification token");
    } finally {
      setLoadingToken(false);
    }
  };

  const handleVerify = async () => {
    if (!urlPrefix) {
      toast.error("URL prefix is required");
      return;
    }
    setVerifying(true);
    try {
      const response = await axios.post(
        "https://www.api.sendatradie.com/company/verifyUrlPrefix",
        { urlPrefix, verificationMethod }
      );
      console.log("Verification response:", response.data);
      if (response.data.success) {
        setVerificationResult(response.data.data);
        toast.success("URL Prefix verified and added successfully.");
        // Refresh verified properties table after a successful verification
        fetchVerifiedProperties();
      } else {
        toast.error(response.data.message || "Failed to verify URL prefix.");
      }
    } catch (err) {
      console.error("Error verifying URL prefix:", err);
      toast.error("Failed to verify URL prefix");
    } finally {
      setVerifying(false);
    }
  };

  // Transform verified properties for the table.
  // Each property is expected to have a "site" object with "identifier" and "type".
  const tableData = verifiedProperties.map((prop, index) => ({
    id: index, // use prop.id if available or fallback to index
    identifier: prop.identifier,
    type: prop.type,
  }));

  // Define columns for CustomBootstrapTable
  const columns = [
    {
      dataField: "identifier",
      text: "Identifier",
    },
  ];

  return (
    <Card>
      <CardHeader title="Google Search Console">
        <CardHeaderToolbar>
          {/* The toolbar can be used for additional controls if needed */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* URL Prefix and Verification Form */}
        <h5>Add New Property</h5>
        <div className="form-group row">
          <div className="col-lg-4">URL Prefix:</div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              value={urlPrefix}
              onChange={(e) => setUrlPrefix(e.target.value)}
              placeholder="https://www.example.com"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4">Verification Method:</div>
          <div className="col-lg-6">
            <select
              className="form-control"
              value={verificationMethod}
              onChange={(e) => setVerificationMethod(e.target.value)}
            >
              <option value="META">META</option>
              <option value="FILE">FILE</option>
              {/* Uncomment if DNS method is enabled */}
              {/* <option value="DNS">DNS</option> */}
            </select>
          </div>
        </div>
        <hr />
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            disabled={loadingToken}
            onClick={handleGetToken}
          >
            {loadingToken ? "Getting Token..." : "Get Verification Token"}
          </button>
          {token && (
            <div className="mt-2">
              <strong>Token:</strong> {token}
              <br />
              {verificationMethod === "META" && (
                <span>
                  Please add the following meta tag to your site's{" "}
                  <code>&lt;head&gt;</code> section:
                  <br />
                  <code>
                    {`<meta name="google-site-verification" content="${token}" />`}
                  </code>
                </span>
              )}
              {verificationMethod === "FILE" && (
                <span>
                  Please upload the verification file provided by Google to your
                  site's root directory.
                </span>
              )}
            </div>
          )}
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary"
            disabled={verifying}
            onClick={handleVerify}
          >
            {verifying ? "Verifying..." : "Verify URL Prefix"}
          </button>
        </div>
        {/* Section to display Verified Properties */}
        <hr />
        <h5>Verified Properties</h5>
        <div className="mt-3">
          <CustomBootstrapTable
            data={tableData}
            columns={columns}
            ids={selectedIds}
            setIds={setSelectedIds}
            isLoading={tableLoading}
            isSelectable={false}
            enableSizeFive={false}
            tableRef={tableRef}
          />
        </div>
      </CardBody>
    </Card>
  );
}
