import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./PartnerLoginForm.css";

// Import environment variables or config
const API_URL = process.env.REACT_APP_API_URL || "https://www.api.sendatradie.com";

/**
 * Partner Reset Password Form
 * Allows partners to set a new password after receiving a reset link
 */
const PartnerResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);
  const [tokenChecked, setTokenChecked] = useState(false);
  
  const history = useHistory();
  const location = useLocation();
  
  // Extract token from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  
  // Verify token on component mount
  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setTokenValid(false);
        setTokenChecked(true);
        return;
      }
      
      try {
        const response = await axios.get(
          `${API_URL}/register/partners/verify-reset-token?token=${token}`
        );
        
        if (response.data && response.data.success) {
          setTokenValid(true);
        } else {
          setTokenValid(false);
        }
      } catch (error) {
        console.error("Token verification error:", error);
        setTokenValid(false);
      } finally {
        setTokenChecked(true);
      }
    };
    
    verifyToken();
  }, [token]);
  
  // Initial form values
  const initialValues = {
    password: "",
    confirmPassword: ""
  };
  
  // Form validation schema
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required")
  });
  
  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    if (!token) {
      toast.error("Reset token is missing");
      return;
    }
    
    try {
      setLoading(true);
      
      // API request to reset password
      const response = await axios.post(
        `${API_URL}/register/partners/reset-password`,
        {
          token,
          password: values.password
        }
      );
      
      // Show success message
      if (response.data && response.data.success) {
        setResetSuccess(true);
        
        // Redirect to login page after 3 seconds
        setTimeout(() => {
          history.push("/partner-login");
        }, 3000);
      } else {
        throw new Error("Failed to reset password");
      }
    } catch (error) {
      console.error("Password reset error:", error);
      
      if (error.response) {
        if (error.response.status === 400) {
          // Token is invalid or expired
          setTokenValid(false);
        } else {
          toast.error(error.response.data.message || "An error occurred. Please try again.");
        }
      } else {
        toast.error("Connection error. Please check your internet connection and try again.");
      }
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };
  
  // Show loading state while token is being checked
  if (!tokenChecked) {
    return (
      <div className="partner-login-container">
        <div className="card">
          <div className="card-body text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-3">Verifying your reset link...</p>
          </div>
        </div>
      </div>
    );
  }
  
  // Show error if token is invalid
  if (!tokenValid) {
    return (
      <div className="partner-login-container">
        <div className="card">
          <div className="card-body">
            <div className="partner-login-header">
              <div className="logo-container mb-4">
                <img 
                  src="/media/logos/logo-dark.png" 
                  alt="SendaTradie Logo" 
                  className="sendatradie-logo" 
                />
              </div>
              <h1 className="card-title">Invalid Reset Link</h1>
            </div>
            
            <div className="alert alert-danger" role="alert">
              This password reset link is invalid or has expired.
            </div>
            
            <p className="text-center">
              Please request a new password reset link.
            </p>
            
            <div className="text-center mt-4">
              <Link to="/partner-forgot-password" className="btn btn-primary">
                Request New Reset Link
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="partner-login-container">
      <div className="card">
        <div className="card-body">
          <div className="partner-login-header">
            <div className="logo-container mb-4">
              <img 
                src="/media/logos/logo-dark.png" 
                alt="SendaTradie Logo" 
                className="sendatradie-logo" 
              />
            </div>
            <h1 className="card-title">Reset Password</h1>
            <p className="text-muted">
              Create a new password for your account
            </p>
          </div>
          
          {resetSuccess ? (
            <div className="text-center">
              <div className="alert alert-success" role="alert">
                <i className="fa fa-check-circle mr-2"></i>
                Your password has been reset successfully!
              </div>
              <p>
                You will be redirected to the login page in a few seconds.
              </p>
              <Link to="/partner-login" className="btn btn-primary mt-3">
                Go to Login
              </Link>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={ResetPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className="login-form">
                  <div className="form-group">
                    <label htmlFor="password">New Password<span className="text-danger">*</span></label>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                      placeholder="Enter new password"
                    />
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                    <small className="form-text text-muted">
                      Password must be at least 8 characters long
                    </small>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password<span className="text-danger">*</span></label>
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      className={`form-control ${errors.confirmPassword && touched.confirmPassword ? 'is-invalid' : ''}`}
                      placeholder="Confirm new password"
                    />
                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                  </div>
                  
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      disabled={isSubmitting || loading}
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          Resetting Password...
                        </>
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerResetPassword;