import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const AdditionalInfoModal = ({
  show,
  onClose,
  templateName,
  setTemplateName,
  templateContent,
  setTemplateContent,
  onSave,
  isLoading,
}) => {
  const [existingTemplates, setExistingTemplates] = useState([]);
  const [isFetchingTemplates, setIsFetchingTemplates] = useState(false);
  const [editingTemplateId, setEditingTemplateId] = useState(null);

  const fetchTemplates = async () => {
    setIsFetchingTemplates(true);
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        "https://www.api.sendatradie.com/jobs/getAdditionalInfoTemplates"
      );
      console.log("API Response:", response.data); // Debugging log
      if (response.data.success) {
        setExistingTemplates(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setIsFetchingTemplates(false);
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.delete(
        `https://www.api.sendatradie.com/jobs/deleteAdditionalInfoTemplate/${templateId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Template deleted successfully!");
      fetchTemplates(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  const handleUpdateTemplate = async (templateId) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.put(
        `https://www.api.sendatradie.com/jobs/updateAdditionalInfoTemplate/${templateId}`,
        {
          name: templateName,
          template: templateContent,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Template updated successfully!");
      fetchTemplates(); // Refresh the list after update
      setEditingTemplateId(null); // Reset editing state
      setTemplateName(""); // Clear form
      setTemplateContent(""); // Clear form
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchTemplates();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        onClose();
        setEditingTemplateId(null); // Reset editing state
      }}
      backdrop="static"
      keyboard={false}
      style={{ zIndex: 99999, position: "fixed", top: 0, left: 0 }}
      container={document.body}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Additional Info Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Template Name</label>
          <input
            type="text"
            className="form-control"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Enter template name"
          />
        </div>
        <div className="form-group">
          <label>Template Content</label>
          <textarea
            className="form-control"
            rows="3"
            value={templateContent}
            onChange={(e) => setTemplateContent(e.target.value)}
            placeholder="Enter template content"
          ></textarea>
        </div>

        {editingTemplateId && (
          <button
            className="btn btn-secondary mb-3 mt-2"
            onClick={() => {
              setEditingTemplateId(null);
              setTemplateName("");
              setTemplateContent("");
            }}
          >
            Cancel Edit
          </button>
        )}

        <div className="mb-4">
          <label className="form-label">
            <h3>Existing Templates</h3>
          </label>
          {isFetchingTemplates ? (
            <div className="text-muted">Loading templates...</div>
          ) : existingTemplates.length === 0 ? (
            <div className="text-muted">No existing templates found.</div>
          ) : (
            <div
              className="list-group"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              {existingTemplates.map((template) => (
                <div
                  key={template.id}
                  className="list-group-item small py-2 d-flex justify-content-between align-items-center"
                >
                  <div>
                    <h6 className="mb-1 fw-semibold">{template.name}</h6>
                    <p className="mb-0 text-muted">{template.template}</p>
                  </div>
                  <div>
                    <button
                      className="btn btn-sm btn-outline-primary me-2 mr-2"
                      onClick={() => {
                        setTemplateName(template.name);
                        setTemplateContent(template.template);
                        setEditingTemplateId(template.id);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => handleDeleteTemplate(template.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={onClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={async () => {
            if (editingTemplateId) {
              await handleUpdateTemplate(editingTemplateId);
            } else {
              await onSave();
            }
            fetchTemplates();
          }}
          disabled={isLoading}
        >
          {isLoading
            ? editingTemplateId
              ? "Updating..."
              : "Saving..."
            : editingTemplateId
            ? "Update"
            : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalInfoModal;
