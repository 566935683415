import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { JobsTable } from "./customers-table/JobsTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { toast } from "react-toastify";
import moment from "moment";
import * as requestFromServer from "../../_redux/customers/customersCrud";
import qs from "qs";
import { useHistory } from "react-router";
import { typeOf } from "react-is";
import { CustomerReccurringJobs } from "./customer-reccurring-jobs/CustomerReccurringJobs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchRecurrentJobToggleValue } from "../../../Auth/_redux/settingAction";
import axios from "axios";

export function JobsList(props) {
  const dispatch = useDispatch();
  const settings = props.settings;
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  const [status, setStatus] = React.useState([]);
  // const [rawStatus, setRawStatus] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const [filters, setFilters] = React.useState({
    status: 0,
    jobType: 0,
    worker: 0,
    search: "",
    id: 0,
  });
  const [dates, setDates] = React.useState({
    start: "",
    end: "",
    key: "selection",
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dates: {
        start: dates.start,
        end: dates.end,
      },
    }));
  }, [dates]); // Run the effect when dates change

  const [worker, setWorker] = React.useState([]);
  const [workerFetched, setWorkerFetched] = React.useState([]);
  const [jobType, setJobType] = React.useState([]);
  const [jobTypeFetched, setJobTypeFetched] = React.useState([]);
  const [hide, setHide] = React.useState(false);
  const history = useHistory();
  const [useDateClosed, setUseDateClosed] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const rawStatus = [
    { value: 0, label: "All" },
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "On the Way",
    },
    {
      value: 3,
      label: "Closed",
    },
    {
      value: 4,
      label: "Cancelled",
    },
    {
      value: 5,
      label: "Awaiting Payment",
    },
    {
      value: 6,
      label: "Call Back",
    },
  ];
  const [resetPage, setResetPage] = useState(false);

  // useEffect(() => {
  //   //hideconsole.log(qs.parse(history.location.search, { ignoreQueryPrefix: true }));
  //   const params = qs.parse(history.location.search, {
  //     ignoreQueryPrefix: true,
  //   });
  //   if (params.id) {
  //     //hideconsole.log("params-------");
  //     //hideconsole.log(params);
  //     //hideconsole.log(history.location.search);
  //     const { id, tab } = params;

  //     setFilters({ ...filters, id: id });
  //     props.setTab(parseInt(tab));
  //   }
  // }, [history]);

  useEffect(() => {
    console.log("check props", props);
  }, [props]);

  useEffect(() => {
    setStatus(
      rawStatus?.filter((item) => {
        if (props.tab == 0 || props.tab == 2) {
          return item.value != 3 && item.value != 4;
        } else if (props.tab == 1) {
          return item.value == 0 || item.value == 3 || item.value == 4;
        }
      })
    );
  }, [props.tab]);

  useEffect(() => {
    requestFromServer
      .getWorkers()
      .then((response) => {
        if (response.data.result.length) {
          response.data.result.unshift({ value: 0, label: "All" });
          setWorker(response.data.result);
          setWorkerFetched(true);
          setShow(true);
        } else {
          setShow(false);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message == "User not authorized."
        ) {
          history.push("/logout");
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });

    requestFromServer
      .getJobsType()
      .then((response) => {
        if (response.data.result.length) {
          response.data.result.unshift({ value: 0, label: "All" });
          setJobType(response.data.result);
          setJobTypeFetched(true);
          //hideconsole.log(response.data.result);
          setHide(true);
        } else {
          setHide(false);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message == "User not authorized."
        ) {
          history.push("/logout");
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(fetchRecurrentJobToggleValue());
  }, []);

  const state = useSelector((state) => state);

  return (
    <>
      <Card className="tour_jobs_list">
        <CardHeader title="Jobs List">
          <CardHeaderToolbar>
            <button
              type="button"
              id="tour_new_job"
              className="btn btn-primary tour_new_job"
              onClick={() => {
                customersUIProps.newCustomerButtonClick();
              }}
            >
              New Job
            </button>

            {/* <input
              type="file"
              className="ml-3"
              accept="application/pdf"
              onChange={(e) => {
                //hideconsole.log("changed-------------");
                extractText(e);
              }}
            ></input> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper>
            <Tabs
              value={props.tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={props.handleChange}
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab label="Active" />
              <Tab label="History" />
              <Tab label="Appointments" />
              {/* if user has enabled recurring jobs, we only then show this tab */}
              {state.settings.reccurrentJobToggleValue == 1 ? (
                <Tab label="Recurring Jobs" />
              ) : null}
            </Tabs>
          </Paper>
          <br />

          {props?.tab != 3 ? (
            <>
              <CustomersFilter
                value={props.tab}
                filters={filters}
                setFilters={setFilters}
                setDates={setDates}
                worker={worker}
                show={show}
                status={status}
                type={jobType}
                hide={hide}
                useDateClosed={useDateClosed}
                setUseDateClosed={setUseDateClosed}
                rawStatus={rawStatus}
                resetPage={resetPage}
                setResetPage={setResetPage}
              />

              <CustomersGrouping value={props.tab} ids={ids} setIds={setIds} />

              {/* {(props.tab === 0 || props.tab === 1 || props.tab === 2) && ( */}
              <JobsTable
                status={status}
                value={props.tab}
                settings={settings}
                filters={filters}
                setFilters={setFilters}
                ids={ids}
                setIds={setIds}
                worker={worker}
                dates={dates}
                useDateClosed={useDateClosed}
                resetPage={resetPage}
                setResetPage={setResetPage}
                jobType={jobType}
              />
            </>
          ) : state.settings.reccurrentJobToggleValue == 1 ? (
            <>
              <CustomerReccurringJobs />
            </>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
}
