// import npm packages
import axios from "axios";

// import local files
import { BASE_URL } from "./config.js";

class CustomAxios {
  /**
   * Constructor to set Static Header for API calls.
   */
  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }

  /**
   * Call API.
   * @param {string, string, object, object, boolean}
   * @returns {Promise}
   */
  call(method, url, params = {}, data = {}) {
    try {
      let persisted = localStorage.getItem("persist:demo1-auth");
      if (persisted) {
        const auth = JSON.parse(persisted);
        if (auth && auth.authToken) {
          const authToken = JSON.parse(auth.authToken);
          if (authToken) {
            this.headers.Authorization = `Bearer ${authToken}`;
          }
        }
      }
    } catch (error) {
      console.error("Error setting authorization header:", error);
    }

    return axios({
      method,
      url,
      baseURL: BASE_URL,
      params,
      data,
      headers: this.headers,
      timeout: 60000,
      responseType: "json",
    });
  }
}

export default new CustomAxios();
