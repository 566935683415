import React, { useMemo, useEffect, useState, useRef } from "react";
import { useCustomersUIContext } from "../CustomersUIContext";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import "../../../../../../_metronic/_assets/sass/layout/_customStyle.scss";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CustomDateRangePicker from "../../../../../../_metronic/_helpers/CustomDateRangePicker";
import { useSelector } from "react-redux";
import { Switch } from "@material-ui/core";

export function CustomersFilter(props, { listLoading, worker, show }) {
  //using usehistory for routing to login page
  const history = useHistory();
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    //hideconsole.log(props.worker);
  }, [props.worker]);

  return (
    <div>
      <div className="row tour_filters align-items-center">
        <div className="col-lg-2">
          <small className="form-text text-muted">
            <b>Filter</b> by Status
          </small>
          <Select
            classNamePrefix="mySelect"
            name="filter_status"
            value={props.status.find(
              (item) => item.value === props.filters.status
            )}
            onChange={(e) =>
              props.setFilters({ ...props.filters, status: e.value })
            }
            options={props.status}
          ></Select>
        </div>

        {props.hide ? (
          <div className="col-lg-2">
            <small className="form-text text-muted">
              <b>Filter</b> by Job Type
            </small>
            <Select
              classNamePrefix="mySelect"
              name="filter_jobType"
              value={props.type?.find(
                (item) => item.value === props.filters.jobType
              )}
              onChange={(e) =>
                props.setFilters({ ...props.filters, jobType: e.value })
              }
              options={props.type}
            ></Select>
          </div>
        ) : (
          ""
        )}

        {userData.group_id != 3 ? (
          <div className="col-lg-2">
            <small className="form-text text-muted">
              <b>Filter</b> by Worker
            </small>
            <Select
              classNamePrefix="mySelect"
              name="filter_worker"
              value={props.worker?.find(
                (item) => item.value === props.filters.worker
              )}
              onChange={(e) =>
                props.setFilters({ ...props.filters, worker: e.value })
              }
              options={props.worker}
            ></Select>
          </div>
        ) : (
          ""
        )}
        <div className="col-lg-2">
          <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small>
          <input
            type="text"
            className="form-control"
            name="searchText"
            placeholder="Search"
            // onBlur={handleBlur}
            value={props.filters.search}
            onChange={(e) => {
              props.setFilters({
                ...props.filters,
                search: e.target.value,
              });
            }}
          />
        </div>
        <CustomDateRangePicker
          setDateToSend={props.setDates}
          className="col-lg-3"
          oneYearRange
        />

        <div className="col-lg-auto p-0">
          <small className="form-text text-muted">
            <b>Use Date Closed</b>
          </small>
          <Switch
            onChange={(e) => {
              props.setUseDateClosed(e.target.checked);
            }}
            name="header.self.fixed.desktop"
            checked={props?.useDateClosed}
            color="primary"
          />
        </div>
      </div>
      {/* <div className="row mt-2 align-items-center">
        <div className="col-lg-2 col-md-2 col-sm-12">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm"
            onClick={() => props.setResetPage(true)}
          >
            <i className="fas fa-search"></i> Search
          </button>
        </div>
      </div> */}
    </div>
  );
}
