import React, { Suspense, lazy, useState, useEffect } from "react";
import loadable from "@loadable/component";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SocketProvider } from "./contexts/SocketProvider.js";
import moment from "moment";
import { toast } from "react-toastify";
import PartnerSignupForm from "./modules/Partnership/PartnerSignupForm.js";
import PartnerLoginForm from "./modules/Partnership/PartnerLoginForm.js";
import PartnerDashboard from "./modules/Partnership/PartnerDashboard.js";
// import DashboardPage from "./pages/DashboardPage.js";
import Dashboard from "../_metronic/_partials/dashboards/RevenuJobStatus";
import CalendarView from "./pages/Calendar.js";
import SettingsAutoClockIn from "./modules/SettingsPage/SettingsAutoClockIn.js";
import AddUrlPrefix from "./modules/SettingsPage/GoogleSearchSettings.js";
// const Dashboard = loadable(() =>
//   import("../_metronic/_partials/dashboards/RevenuJobStatus")
// );
const Payment = loadable(() =>
  import("./modules/SettingsPage/Payemnt/Payment.js")
);
const Plans = loadable(() => import("./pages/Plan.js"));
const SmsPlan = loadable(() => import("./pages/SmsPlan.js"));
const ReviewSms = loadable(() => import("./modules/Template/ReviewSms.js"));
const EmailTemplate = loadable(() =>
  import("./modules/Template/EmailTemplate.js")
);
const JobMessage = loadable(() => import("./modules/Template/JobMessage.js"));
const ManageLeads = loadable(() => import("./pages/Manageleads.js"));

const ReferAFriend = loadable(() =>
  import("./modules/ReferAFriend/ReferAFriend.js")
);

const AdminSupportDashboard = loadable(() =>
  import("./pages/AdminSupportDashboard.js")
);
const SettingNotification = loadable(() =>
  import("./modules/SettingNotification/SettingNotification.js")
);

const ClientEmailTemplate = loadable(() =>
  import("./modules/Template/ClientEmailTemplate.js")
);

const MinisiteBuilder = loadable(() =>
  import("./modules/Minisite/MinisiteBuilder.js")
);

const ClientSMSTemplate = loadable(() =>
  import("./modules/Template/ClientSMSTemplate.js")
);
const SettingJobType = loadable(() =>
  import("./modules/SettingJobType/SettingJobType.js")
);
const RegistrationEmailFlowIndex = loadable(() =>
  import(
    "./modules/SettingsPage/RegistrationEmailFlow/RegistrationEmailFlowIndex.js"
  )
);
const SettingsBankingPage = loadable(() =>
  import("./modules/SettingsPage/SettingsBankingPage.js")
);
const StoragePlan = loadable(() => import("./pages/StoragePlan.js"));
const GoogleMyBusiness = loadable(() =>
  import("./modules/SettingsPage/GoogleMyBusiness/GoogleMyBusiness.js")
);
const JobFormSettings = loadable(() =>
  import("./modules/SettingsPage/JobFormSettings.js")
);
const CustomFieldsSettings = loadable(() =>
  import("./modules/SettingsPage/CustomFieldsSettings.js")
);
const Partners = loadable(() =>
  import("./modules/Partnership/PartnersTable.js")
);
const ReccurringJob = loadable(() =>
  import("./modules/SettingsPage/ReccurringJob.js")
);
const EmailTemplateGeneratorIndex = loadable(() =>
  import(
    "./modules/SettingsPage/EmailTempalteGenerator/EmailTemplateGeneratorIndex.js"
  )
);
const EmailMarketingIndex = loadable(() =>
  import("./modules/SettingsPage/EmailMarketing/EmailMarketingIndex.js")
);
const SocialAccounts = loadable(() =>
  import("./modules/SettingsPage/SocialAccounts.js")
);
const LoginList = loadable(() => import("./modules/SettingsPage/LoginList.js"));
const ShiftAllocationIndex = loadable(() =>
  import("./modules/ShiftAllocation/ShiftAllocationIndex.js")
);
const FeaturePermissions = loadable(() =>
  import("./modules/SettingsPage/FeaturePermissions/FeaturePermissions.js")
);
const InsightIndex = loadable(() =>
  import("./modules/Insight/InsightIndex.js")
);
const MicrositeIndex = loadable(() =>
  import("./modules/SettingsPage/CompanySpotlight/MicrositeIndex")
);

const Trello = loadable(() => import("./pages/Trello.js"));

const Calendar = loadable(() => import("./pages/Calendar.js"));

const FormBuilder = loadable(() => import("./pages/FormBuilder.js"));

const InvoiceBuilder = loadable(() => import("./pages/InvoiceBuilder.js"));

const InvoiceBuilderPreview = loadable(() =>
  import("./pages/InvoiceBuilderPreview.js")
);

const Forms = loadable(() => import("./pages/Forms.js"));

const AddPaymentTerms = loadable(() => import("./pages/AddPaymentTerms.js"));

const ScheduleUpdateAlert = loadable(() =>
  import("./pages/ScheduleUpdateAlert.js")
);

const Fingertip = loadable(() => import("./pages/Fingertip.js"));

const Reader = loadable(() => import("./pages/Reader.js"));

const InvoiceReminderIndex = loadable(() =>
  import("./modules/SettingsPage/InvoiceReminder/InvoiceReminderIndex")
);

const GoogleMapReviews = loadable(() =>
  import("./modules/GoogleMapReviews/GoogleMapReviewsIndex")
);

const IncomingCallSettings = loadable(() =>
  import("./modules/SettingsPage/IncomingCallSettings")
);
const AccountPage = loadable(() => import("./modules/AccountPage/AccountPage"));
const AutomationsPage = loadable(() =>
  import("./modules/Automations/AutomationsPage")
);
const SettingsPlugin = loadable(() =>
  import("./modules/SettingsPage/SettingsPlugin")
);
const Report = loadable(() => import("./pages/Report"));
const AddCustomeReport = loadable(() =>
  import("./modules/Report/AddCustomeReport")
);
const AddEditClientTab = loadable(() =>
  import("./modules/Client/components/AddEditClient/AddEditClientTab")
);
const CustomerReceiptDialog = loadable(() =>
  import(
    "./modules/Jobs/pages/Jobs/customer-receipt-dialog/CustomerReceiptDialog"
  )
);
const SettingsPage = loadable(() =>
  import("./modules/SettingsPage/SettingsPage")
);
const AccountingPage = loadable(() =>
  import("./modules/Client/Accounting/AccountingPage")
);
const ManageInventory = loadable(() => import("./pages/ManageInventory"));
const ParticularReportPage = loadable(() =>
  import("./pages/ParticularReportsPage")
);
const ClientImport = loadable(() => import("./modules/Client/ClientImport"));
const SettingInventory = loadable(() =>
  import("./modules/SettingInventory/SettingInventory")
);
const Maps = loadable(() => import("./pages/Maps"));
const Team = loadable(() => import("./pages/Team"));
const Invoices = loadable(() => import("./pages/Invoices"));
const Client = loadable(() => import("./pages/Client"));
const JobsPage = loadable(() => import("./modules/Jobs/pages/Jobs/JobsPage"));
const Quotes = loadable(() => import("./modules/Quotes/Component/Quotes"));
const NewQuotes = loadable(() =>
  import("./modules/Quotes/Component/NewQuotes/NewQuotes")
);
const MessageComponent = loadable(() =>
  import("./modules/Message/MessageComponent")
);
const FranchisePage = loadable(() =>
  import("./modules/Franchise/FranchisePage")
);
const JobsValidation = loadable(() =>
  import("./modules/FieldValidation/JobsValidation")
);
const SMSPage = loadable(() => import("./modules/Message/SMS/SMSPage"));
const StockTemplates = loadable(() =>
  import("./modules/ManageInventory/StockTemplates/StockTemplatesPage")
);
const TestMessage = loadable(() => import("./modules/Message/testMessage"));
const SettingsTaxPage = loadable(() =>
  import("./modules/SettingsPage/SettingsTaxPage")
);
const SettingInvoicePage = loadable(() =>
  import("./modules/SettingInvoice/SettingInvoicePage")
);
const SettingsNumbering = loadable(() =>
  import("./modules/SettingsPage/SettingsNumbering")
);
const CallMaskingPage = loadable(() =>
  import("./modules/SettingCallMasking/CallMaskingPage")
);
const CallPlan = loadable(() => import("./pages/CallPlan"));
const SettingInvoiceQuotes = loadable(() =>
  import("./modules/SettingInvoiceQuotes/SettingInvoiceQuotes")
);
const ReviewSettingPage = loadable(() =>
  import("./modules/ReviewSettingPage/ReviewSettingPage")
);
const RolePermissionPage = loadable(() =>
  import("./modules/SettingRolePermission/RolePermissionPage")
);
const SettingsCalendar = loadable(() =>
  import("./modules/SettingsPage/SettingsCalendar")
);
const OnboardingPage = loadable(() =>
  import("./modules/Onboarding/OnboardingPage")
);

export default function BasePage(props) {
  const isTokenValid = () => {
    const expiryTime = localStorage.getItem("expiryTime");
    return expiryTime && moment(expiryTime).isAfter(moment());
  };

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: isTokenValid() && !!auth.user,
    }),
    shallowEqual
  );

  const { user } = useSelector((state) => state.auth);
  let settings = {};
  settings = props.settings;
  const history = useHistory();

  useEffect(() => {
    // Skip subscription check for partner routes
    if (
      history.location.pathname === "/partner-login" ||
      history.location.pathname === "/partner-signup" ||
      history.location.pathname === "/partner-dashboard"
    ) {
      return; // Skip subscription check for partner pages
    }
    
    // !IMOPORTANT If payment until is before today, go to plans page.
    if (
      user && user.subscription_date && 
      moment(user.subscription_date).isBefore(moment().subtract(1, "d")) &&
      history.location.pathname != "/plans"
    ) {
      toast.info("Plan is inactive.");
      history.push("/plans");
    }
  }, [history.location.pathname]);

  // Determine if this is a partner route
  const isPartnerRoute = 
    history.location.pathname === "/partner-login" || 
    history.location.pathname === "/partner-signup" || 
    history.location.pathname === "/partner-dashboard";
    
  // Only use SocketProvider for authenticated routes with valid user
  const content = (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
          <Redirect exact from="/" to={props.redirectUrl} />
          {/* <Redirect exact from="/" to="/dashboard" /> */}
          
          {/* Partner routes - no auth required */}
          <Route
            path="/partner-login"
            component={PartnerLoginForm}
          />
          <Route
            path="/partner-signup"
            component={PartnerSignupForm}
          />
          <Route
            path="/partner-dashboard"
            component={PartnerDashboard}
          />
          
          <ContentRoute
            path="/dashboard"
            isAuth={isAuthorized}
            settings={settings}
            render={(props) => <Dashboard {...props} settings={settings} />}
          />
          {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
          {/* <ContentRoute path="/my-page" component={MyPage} /> */}
          <ContentRoute
            path="/jobs/:id/invoice"
            component={CustomerReceiptDialog}
          ></ContentRoute>
          {/* <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
          {/* <PRoute
          path="/jobs"
          component={JobsPage}
          settings={settings}
          isAuth={isAuthorized}
        /> */}
          <ContentRoute
            path="/referfriend"
            render={(props) => <ReferAFriend {...props} settings={settings} />}
          />
          {/* <ContentRoute
            path="/formsExample"
            render={(props) => (
              <FormsExamplesPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/tabsExample"
            render={(props) => (
              <TabsExamplesPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/tablesExample"
            render={(props) => (
              <TablesExamplesPage {...props} settings={settings} />
            )}
          /> */}
          <ContentRoute
            path="/settings/notification"
            render={(props) => (
              <SettingNotification {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/reader"
            render={(props) => <Reader {...props} settings={settings} />}
          />
          <ContentRoute
            path="/pipeline"
            render={(props) => <Trello {...props} settings={settings} />}
          />
          <ContentRoute
            path="/FormBuilder"
            render={(props) => <FormBuilder {...props} settings={settings} />}
          />
          <ContentRoute
            path="/Invoicebuilder"
            render={(props) => (
              <InvoiceBuilder {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/template/client-email"
            render={(props) => (
              <ClientEmailTemplate {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/minisite"
            render={(props) => (
              <MinisiteBuilder {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/template/client-SMS"
            render={(props) => (
              <ClientSMSTemplate {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/InvoicebuilderPreview"
            render={(props) => (
              <InvoiceBuilderPreview {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/AddPaymentTerms"
            render={(props) => (
              <AddPaymentTerms {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/Forms"
            render={(props) => <Forms {...props} settings={settings} />}
          />
          <ContentRoute
            path="/ScheduleUpdateAlert"
            render={(props) => (
              <ScheduleUpdateAlert {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/calendar"
            render={(props) => <CalendarView {...props} settings={settings} />}
          />
          <ContentRoute
            path="/fingertip"
            render={(props) => <Fingertip {...props} settings={settings} />}
          />
          <ContentRoute
            path="/partners"
            render={(props) => <Partners {...props} settings={settings} />}
          />
          <ContentRoute
            path="/admin-support-dashboard"
            render={(props) => (
              <AdminSupportDashboard {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/inventory"
            render={(props) => (
              <SettingInventory {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/payment"
            render={(props) => <Payment {...props} settings={settings} />}
          />
          <ContentRoute
            path="/settings/review"
            render={(props) => (
              <ReviewSettingPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/invoice/reminder"
            render={(props) => (
              <InvoiceReminderIndex {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/t_c"
            render={(props) => (
              <SettingInvoiceQuotes {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/shift/allocation"
            render={(props) => (
              <ShiftAllocationIndex {...props} settings={settings} />
            )}
          />
          /* /social/company/spotlight */
          <ContentRoute
            path="/social/company/spotlight"
            render={(props) => (
              <MicrositeIndex {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/customSettings/featurePermissions"
            render={(props) => (
              <FeaturePermissions {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/jobtype"
            render={(props) => (
              <SettingJobType {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/accounting"
            render={(props) => (
              <AccountingPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/email/marketing"
            render={(props) => (
              <EmailMarketingIndex {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/insight"
            render={(props) => <InsightIndex {...props} settings={settings} />}
          />
          <ContentRoute
            path="/settings/fieldvalidation/jobs"
            render={(props) => (
              <JobsValidation {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/sms"
            render={(props) => <SMSPage {...props} settings={settings} />}
          />
          <ContentRoute
            path="/settings/autoClockIn"
            render={(props) => (
              <SettingsAutoClockIn {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/stock"
            render={(props) => (
              <StockTemplates {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/loginlist"
            render={(props) => <LoginList {...props} settings={settings} />}
          />
          <ContentRoute
            path="/settings/tax"
            render={(props) => (
              <SettingsTaxPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/invoice"
            render={(props) => (
              <SettingInvoicePage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/banking"
            render={(props) => (
              <SettingsBankingPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/rolePermissions"
            render={(props) => (
              <RolePermissionPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/calendar"
            render={(props) => (
              <SettingsCalendar {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/onboarding"
            render={(props) => (
              <OnboardingPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/numbering"
            render={(props) => (
              <SettingsNumbering {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/registration/emailFlow"
            render={(props) => (
              <RegistrationEmailFlowIndex {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/callmasking"
            render={(props) => (
              <CallMaskingPage {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/plugin"
            render={(props) => (
              <SettingsPlugin {...props} settings={settings} />
            )}
          />
          <ContentRoute
            path="/settings/googleSearch"
            render={(props) => <AddUrlPrefix {...props} settings={settings} />}
          />
          <ContentRoute
            path="/settings"
            render={(props) => <SettingsPage {...props} settings={settings} />}
          />
          <ContentRoute
            path="/quotes/newquotes"
            render={(props) => <NewQuotes {...props} settings={settings} />}
          />
          <ContentRoute
            path="/quotes/editquotes/:id"
            render={(props) => <NewQuotes {...props} settings={settings} />}
          />
          <ContentRoute
            path="/quotes"
            render={(props) => <Quotes {...props} settings={settings} />}
          />
          <ContentRoute
            path="/social/googleMyBusiness"
            render={(props) => <GoogleMyBusiness {...props} />}
          />
          <ContentRoute
            path="/callSettings/incomingCall"
            render={(props) => <IncomingCallSettings {...props} />}
          />
          <ContentRoute
            path="/reviews"
            render={(props) => <GoogleMapReviews {...props} />}
          />
          <ContentRoute
            path="/jobsettings/form"
            render={(props) => <JobFormSettings {...props} />}
          />
          <ContentRoute
            path="/jobsettings/customFields"
            render={(props) => <CustomFieldsSettings {...props} />}
          />
          <ContentRoute
            path="/emailTemplate/generator"
            render={(props) => <EmailTemplateGeneratorIndex {...props} />}
          />
          <ContentRoute
            path="/jobsettings/reccurringJob"
            render={(props) => <ReccurringJob {...props} />}
          />
          <ContentRoute
            path="/social/accounts"
            render={(props) => <SocialAccounts {...props} />}
          />
          <ContentRoute
            path="/client/newestimate/:id"
            render={(props) => <NewQuotes {...props} settings={settings} />}
          />
          <ContentRoute
            path="/jobs"
            render={(props) => <JobsPage {...props} settings={settings} />}
          />
          <Route
            path="/team"
            render={(props) => <Team {...props} settings={settings} />}
          />
          <Route
            path="/account"
            render={(props) => <AccountPage {...props} settings={settings} />}
          />
          <Route
            path="/message"
            render={(props) => (
              <MessageComponent {...props} settings={settings} />
            )}
          />
          <Route
            path="/reports/add/customereport"
            render={(props) => (
              <AddCustomeReport {...props} settings={settings} />
            )}
          />
          <Route
            path="/reports/:name"
            render={(props) => (
              <ParticularReportPage {...props} settings={settings} />
            )}
          />
          <Route
            path="/reports"
            render={(props) => <Report {...props} settings={settings} />}
          />
          <Route
            path="/automations"
            render={(props) => (
              <AutomationsPage {...props} settings={settings} />
            )}
          />
          <Route
            path="/manage/leads"
            render={(props) => <ManageLeads {...props} settings={settings} />}
          />
          <Route
            path="/manage/inventory"
            render={(props) => (
              <ManageInventory {...props} settings={settings} />
            )}
          />
          <Route path="/invoices" render={(props) => <Invoices />} />
          <Route
            path="/clients/add"
            render={(props) => (
              <AddEditClientTab {...props} settings={settings} />
            )}
          />
          <Route
            path="/clients/edit"
            render={(props) => (
              <AddEditClientTab {...props} settings={settings} />
            )}
          />
          <Route
            path="/clients/import"
            render={(props) => <ClientImport {...props} settings={settings} />}
          />
          <Route
            path="/clients"
            render={(props) => <Client {...props} settings={settings} />}
          />
          <Route
            path="/maps"
            render={(props) => <Maps {...props} settings={settings} />}
          />
          <Route
            path="/testMessage"
            render={(props) => <TestMessage {...props} settings={settings} />}
          />
          <Route
            path="/template/reviewsms"
            exact
            render={(props) => <ReviewSms {...props} settings={settings} />}
          />
          <Route
            path="/template/email"
            exact
            render={(props) => <EmailTemplate {...props} settings={settings} />}
          />
          <Route
            path="/template/jobmessage"
            exact
            render={(props) => <JobMessage {...props} settings={settings} />}
          />
          <Route
            path="/plans"
            exact
            render={(props) => <Plans {...props} settings={settings} />}
          />
          <Route
            path="/plans/sms"
            render={(props) => <SmsPlan {...props} settings={settings} />}
          />
          <Route
            path="/plans/call"
            render={(props) => <CallPlan {...props} settings={settings} />}
          />
          <Route
            path="/plans/storage"
            render={(props) => <StoragePlan {...props} settings={settings} />}
          />
          <Route
            path="/franchise"
            render={(props) => <FranchisePage {...props} settings={settings} />}
          />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
  );
  
  // For partner routes or when no user is logged in, don't use SocketProvider
  return isPartnerRoute || !user || !user.id ? content : (
    <SocketProvider id={user.id}>
      {content}
    </SocketProvider>
  );
}
