import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddItemToInvoiceComponent } from "../AddItemToInvoice/AddItemToinvoiceComponent";
import { Switch } from "@material-ui/core";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import * as requestFromServerForInv from "./../ManageInventoryCrud/ManageInventoryCrud.js";

export function AddEditRowModal({
  type,
  show,
  onHide,
  allData,
  id,
  initialValue,
  allTitle,
  handleSendManageInventory,
}) {
  const [formValues, setFormValues] = useState({});
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(true);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [itemPrice, setItemPrice] = useState(0);
  const [itemData, setItemData] = useState({
    itemName: "",
    description: "",
    partName: "",
    partPrice: 0,
    margin: 0,

    fixedMargin: false,
    id: null,
    jobType: "",
    is_taxable: false,
    defaultQuantity: 1,
  });

  const resetForm = () => {
    console.log("reseting...");
    if (type == "add") {
      setFormValues({});
    }

    setItemData({
      itemName: "",
      description: "",
      partName: "",
      partPrice: 0,
      margin: 0,
      fixedMargin: false,
      id: null,
      jobType: "",
      is_taxable: false,
      defaultQuantity: 1,
    });
    setItemPrice(0);
    setIsSwitchOn(false);
    setIsSwitchDisabled(true);
  };

  useEffect(() => {
    if (itemData) {
      const partPrice = parseFloat(itemData.partPrice) || 0;
      const margin = parseFloat(itemData.margin) || 0;

      setItemPrice(
        itemData.fixedMargin
          ? partPrice + margin
          : (partPrice * (margin / 100 + 1)).toFixed(2) 
      );
    }
    // else {
    //   setItemPrice(0); // Default fallback when values are invalid
    // }
  }, [itemData]);

  useEffect(() => {
    if (initialValue && allTitle) {
      const nameTitle = allTitle.find((obj) => obj.key == "Name")?.name;
      const priceTitle = allTitle.find((obj) => obj.key == "Price")?.name;
      // const descriptionTitle = allTitle.find((obj) => obj.key == "Description")
      // ?.name;
      setItemData({
        ...itemData,
        partName: initialValue[nameTitle],
        partPrice: initialValue[priceTitle],
        // description: initialValue[descriptionTitle],
        id: initialValue.id,
      });
    }
    console.log("allTitle");
    console.log(allTitle);

    console.log("check initial value", JSON.stringify(initialValue));
  }, [initialValue, allTitle]);

  useEffect(() => {
    console.log("check values", initialValue);
    if (type == "edit") {
      setFormValues(initialValue || {});
    }
  }, [initialValue]);

  useEffect(() => {
    requestFromServer.getJobsType().then((response) => {
      if (response.data.result.length) {
        // const val = response.data.result;
        // const dataval = [...val, { value: 0, label: "All" }];
        setJobTypeOptions(response.data.result);
      }
    });
  }, []);

  useEffect(() => {
    const isFormValid =
      formValues["Id"] &&
      formValues["Name"] &&
      formValues["Name"].trim() !== "" &&
      formValues["Price"] &&
      !isNaN(Number(formValues["Price"])) &&
      Number(formValues["Price"]) > 0 &&
      (!formValues["Quantity"] ||
        (Number.isInteger(Number(formValues["Quantity"])) &&
          Number(formValues["Quantity"]) > 0));

    setIsSwitchDisabled(!isFormValid);
  }, [formValues]);

  const handleToggleSwitch = () => {
    if (!isSwitchDisabled) {
      setIsSwitchOn((prev) => !prev);
    }
  };

  const HandleHide = () => {
    resetForm();
    onHide();
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    console.log("check part", name, value);

    // if (name === "Price" || name === "Quantity") {
    //   value = parseFloat(value);
    // }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name === "Price" || name === "Quantity" ? Number(value) : value,
    }));

    setItemData((prevValues) => ({
      ...prevValues,
      [name === "Name"
        ? "partName"
        : name === "Price"
        ? "partPrice"
        : name]: value,
    }));
  };

  const handletax = () => {
    console.log("check switch");
    setItemData((prev) => {
      const newState = { ...prev, is_taxable: !prev.is_taxable };
      return newState;
    });
  };

  const handleMarginType = () => {
    console.log("check margin");
    setItemData((prev) => {
      return { ...prev, fixedMargin: !prev.fixedMargin };
    });
  };

  // const handleSubmit = () => {
  //   // Validation for required fields
  //   console.log("check item data", itemData);

  //   if (!itemData.itemName.trim()) {
  //     toast.error("Item Name is required.");
  //     return;
  //   }

  //   if (!itemData.defaultQuantity || itemData.defaultQuantity <= 0) {
  //     toast.error("Quantity must be a positive number.");
  //     return;
  //   }

  //   if (!itemPrice || itemPrice <= 0) {
  //     toast.error("Price must be a positive number.");
  //     return;
  //   }
  //   requestFromServerForInv
  //     .addItemToInvoice({ ...itemData, itemPrice: itemPrice })
  //     .then((response) => {
  //       //hideconsole.log(response.data.result);
  //       toast.success("Item added successfully");
  //       getData();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("item is not added");
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSwitchOn) {
      console.log("check item data", itemData);

      if (!itemData.itemName.trim()) {
        toast.error("Item Name is required.");
        return;
      }

      if (!itemData.defaultQuantity || itemData.defaultQuantity <= 0) {
        toast.error("Quantity must be a positive number.");
        return;
      }

      if (!itemPrice || itemPrice <= 0) {
        toast.error("Price must be a positive number.");
        return;
      }
      requestFromServerForInv
        .addItemToInvoice({ ...itemData, itemPrice: itemPrice })
        .then((response) => {
          //hideconsole.log(response.data.result);
          toast.success("Item added successfully");
        })
        .catch((error) => {
          console.log(error);
          toast.error("item is not added");
        });
    }

    if (type === "edit") {
      const updatedData = allData.map((val) =>
        val.id === id ? formValues : val
      );
      handleSendManageInventory("change_RC", allTitle, updatedData);
    } else {
      const newData = [...allData, { ...formValues, id: allData.length + 1 }];
      handleSendManageInventory("change_RC", allTitle, newData);
    }

    HandleHide();
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={HandleHide}>
        <Modal.Header>
          <Modal.Title>{type === "edit" ? "Edit" : "Add"} Row</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close"
            onClick={HandleHide}
          >
            x
          </button>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {Object.keys(initialValue).map((key, index) =>
              key !== "id" ? (
                <div key={index} className="form-group mb-3">
                  <label>{key}</label>
                  <input
                    type="text"
                    name={key}
                    value={formValues[key] || ""}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
              ) : null
            )}

            <div>
              <label>
                Create as new invoice
                <Switch
                  checked={isSwitchOn}
                  onChange={handleToggleSwitch}
                  color="primary"
                  disabled={isSwitchDisabled}
                />
              </label>
              {isSwitchOn && (
                <AddItemToInvoiceComponent
                  allTitle={allTitle}
                  jobTypeOptions={jobTypeOptions}
                  handleMarginType={handleMarginType}
                  handletax={handletax}
                  setItemData={setItemData}
                  itemData={itemData}
                  setItemPrice={setItemPrice}
                  itemPrice={itemPrice}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={HandleHide}
              className="btn btn-light"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <ToastContainer />
    </>
  );
}
