import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import { Line } from "react-chartjs-2";
import CustomAxios from "../../../../utils/axios";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Lock from "../Lock";

function MonthlyRevenue({ settings, saveDraggable, saveSetting, ...props }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const history = useHistory();
  const userData = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [revenueData, setRevenueData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    total: 0,
    profit: 0,
    datasets: [
      {
        label: "Revenue",
        fill: true,
        data: [0, 0, 0, 0, 0, 0],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Profit",
        fill: true,
        data: [0, 0, 0, 0, 0, 0],
        borderColor: "rgb(255,205,86)",
        backgroundColor: "rgba(255,205,86,0.5)",
      },
    ],
  });
  const [showProfit, setShowProfit] = useState(false);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return value.toLocaleString();
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 10,
          padding: 10,
          font: {
            size: 11,
          },
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  useEffect(() => {
    //hideconsole.log(revenueData);
    //hideconsole.log(revenueData.newCustomersData);
  }, [revenueData]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    let data = {
      //   start: dateToSend ? dateToSend.start : moment().format("yyyy-MM-DD"),
      //   end: dateToSend ? dateToSend.end : moment().format("yyyy-MM-DD"),
      //   worker: worker,
      // show_profit: showProfit
    };
    CustomAxios.call("post", `company/fetchMonthlyRevenue`, null, data)
      .then((response) => {
        let revenue = { ...revenueData };
        revenue.total = 0;
        revenue.profit = 0;
        response.data.revenue.forEach((row) => {
          let i = 0;
          let date = moment();
          while (
            date
              .clone()
              .subtract(i, "month")
              .month() != row.month
          ) {
            i++;
          }
          revenue.datasets[0].data[5 - i] = row.sum;
          revenue.datasets[1].data[5 - i] = row.profit;
        });
        for (let i = 0; i < 6; i++) {
          revenue.total += revenue.datasets[0].data[5 - i];
          revenue.profit += revenue.datasets[1].data[5 - i];
          revenue.labels[5 - i] = moment()
            .subtract(i, "month")
            .format("MMM");
        }

        setRevenueData(revenue);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          history.push("/logout");
        } else {
        }
        setLoading(false);
      });
  };

  return (
    <div
      className={`card card-custom p-3`}
      style={{
        boxShadow: "none",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Monthly Revenue
        </h3>
        <Lock settings={settings} saveDraggable={saveDraggable} />
      </div>

      <div
        className="card-body"
        style={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(255, 255, 255, 0.8)",
              zIndex: 1,
            }}
          >
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <div className="row mb-4">
          <div className="col-md-6">
            <div
              style={{
                background: "#fff",
                borderRadius: "12px",
                padding: "1.5rem",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                height: "100%",
              }}
            >
              <div className="d-flex flex-column">
                <span
                  style={{
                    fontSize: "1rem",
                    color: "#3699ff",
                    fontWeight: "600",
                  }}
                >
                  Total of Last 6 Months
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#181c32",
                    marginBottom: "1rem"
                  }}
                >
                  ${revenueData.total.toFixed(2)}
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    color: "#3699ff",
                    fontWeight: "600", 
                  }}
                >
                  Profit
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#181c32",
                  }}
                >
                  ${revenueData.profit.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              style={{
                background: "#fff",
                borderRadius: "12px",
                padding: "1.5rem",
                boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                height: "100%",
              }}
            >
              <div className="d-flex flex-column">
                <span
                  style={{
                    fontSize: "1rem",
                    color: "#3699ff",
                    fontWeight: "600",
                  }}
                >
                  Monthly Average
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#181c32",
                    marginBottom: "1rem"
                  }}
                >
                  ${(revenueData.total / 6).toFixed(2)}
                </span>

                <span
                  style={{
                    fontSize: "1rem",       
                    color: "#3699ff",
                    fontWeight: "600",
                  }}
                >
                  Profit
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#181c32",
                  }}
                >
                  ${(revenueData.profit / 6).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            minHeight: "300px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              minWidth: "400px",
              position: "relative",
            }}
          >
            <Line options={options} data={revenueData} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    draggingData: state.draggingData,
    userData: state.auth.user,
  };
};
export default connect(mapStateToProps, { saveSetting, saveDraggable })(
  MonthlyRevenue
);
