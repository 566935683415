import React, { useState, useMemo, memo, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import DOMPurify from "dompurify";
import "./PartnerSignupForm.css";

// Import environment variables or config
const API_URL = process.env.REACT_APP_API_URL || "https://www.api.sendatradie.com";

// Toast configuration
const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

// Partnership types (moved outside component to avoid re-creation)
const partnershipTypes = [
  { id: "influencer", label: "Influencer" },
  { id: "distributer", label: "Distributer" },
  { id: "technology", label: "Technology" },
  { id: "service_provider", label: "Service provider" },
  { id: "other", label: "Other" },
];

// Initial form values (moved outside component to avoid re-creation)
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  companyWebsite: "",
  password: "",
  confirmPassword: "",
  reasonForPartnership: "",
  partnershipType: "",
};

/**
 * Calculate password strength score (0-3)
 * @param {string} password - The password to evaluate
 * @returns {number} Strength score (0-3)
 */
const calculatePasswordStrength = (password) => {
  if (!password) return 0;

  let score = 0;

  // Length check
  if (password.length >= 8) score++;

  // Character variety checks
  if (/[A-Z]/.test(password)) score++;
  if (/[0-9]/.test(password) || /[!@#$%^&*]/.test(password)) score++;

  return score;
};

/**
 * Validate Australian phone numbers
 * @param {string} phone - Phone number to validate
 * @returns {boolean} True if valid, false otherwise
 */
const isValidAustralianPhone = (phone) => {
  if (!phone) return false;
  return /^(\+?61|0)[2-478](?:\d{8}|\d{9})$/.test(phone.replace(/\s+/g, ""));
};

// Success message component (memoized)
const SuccessMessage = memo(({ onReset }) => (
  <div className="partner-signup-container">
    <div className="card">
      <div className="card-body text-center">
        <div className="success-icon mb-5">
          <i className="flaticon2-check-mark text-success display-3"></i>
        </div>
        <h2 className="mb-4">Application Submitted!</h2>
        <p className="lead">
          Thank you for applying to the SendaTradie Partner Program.
        </p>
        <p>
          We've received your application and will review it shortly. You'll
          receive an email confirmation with more details.
        </p>
        <button className="btn btn-primary mt-5" onClick={onReset}>
          Submit Another Application
        </button>
      </div>
    </div>
  </div>
));

/**
 * Partner Program Signup Form
 * Allows users to apply for the SendaTradie partnership program
 */
const PartnerSignupForm = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPartnershipType, setSelectedPartnershipType] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [partnershipTypeError, setPartnershipTypeError] = useState("");
  const [submissionAttempts, setSubmissionAttempts] = useState(0);

  // Enhanced validation schema
  const PartnerSignupSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string()
          .trim()
          .required("First name is required")
          .min(2, "First name must be at least 2 characters")
          .max(100, "First name must be at most 100 characters")
          .matches(
            /^[a-zA-Z\s]*$/,
            "First name can only contain letters and spaces"
          ),
        lastName: Yup.string()
          .trim()
          .required("Last name is required")
          .min(2, "Last name must be at least 2 characters")
          .max(100, "Last name must be at most 100 characters")
          .matches(
            /^[a-zA-Z\s]*$/,
            "Last name can only contain letters and spaces"
          ),
        email: Yup.string()
          .email("Please enter a valid email address")
          .required("Email is required")
          .max(100, "Email must be at most 100 characters")
          .matches(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            "Please enter a valid email format"
          ),
        phone: Yup.string()
          .required("Phone number is required")
          .test(
            "is-valid-phone",
            "Please enter a valid Australian phone number",
            (value) => isValidAustralianPhone(value)
          )
          .transform((value) => value.replace(/\s+/g, "")),
        companyName: Yup.string()
          .trim()
          .required("Company name is required")
          .min(2, "Company name must be at least 2 characters")
          .max(100, "Company name must be at most 100 characters"),
        companyWebsite: Yup.string()
          .trim()
          .required("Company website is required")
          .url("Please enter a valid URL (e.g., https://www.example.com)")
          .max(255, "Website URL must be at most 255 characters")
          .matches(
            /^https?:\/\//,
            "Website URL must start with http:// or https://"
          ),
        password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter"
          )
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter"
          )
          .matches(/[0-9]/, "Password must contain at least one number")
          .matches(
            /[!@#$%^&*]/,
            "Password must contain at least one special character (!@#$%^&*)"
          )
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords don't match")
          .required("Please confirm your password"),
        reasonForPartnership: Yup.string()
          .trim()
          .required("Please provide a reason for partnership")
          .min(
            10,
            "Please provide a more detailed reason (at least 10 characters)"
          )
          .max(1000, "Reason must not exceed 1000 characters"),
        partnershipType: Yup.string().required(
          "Please select a partnership type"
        ),
      }),
    []
  );

  // Password strength indicator helpers (memoized)
  const getPasswordStrengthColor = useCallback((strength) => {
    switch (strength) {
      case 1:
        return "danger";
      case 2:
        return "warning";
      case 3:
        return "success";
      default:
        return "dark";
    }
  }, []);

  const getPasswordStrengthText = useCallback((strength) => {
    switch (strength) {
      case 1:
        return "Weak";
      case 2:
        return "Medium";
      case 3:
        return "Strong";
      default:
        return "Enter password";
    }
  }, []);

  // Update partnership type selection to also update formik values
  const handlePartnershipTypeSelect = useCallback((type, setFieldValue) => {
    setSelectedPartnershipType(type);
    if (setFieldValue) {
      setFieldValue("partnershipType", type);
    }
    setPartnershipTypeError("");
  }, []);

  // Enhanced handleSubmit with better error handling and feedback
  const handleSubmit = useCallback(
    async (values, formikBag) => {
      try {
        const { setSubmitting, resetForm } = formikBag;

        // Prevent multiple submissions
        if (loading) {
          toast.warning(
            "Please wait while we process your previous submission.",
            toastConfig
          );
          setSubmitting(false);
          return;
        }

        // Validate partnership type
        if (!selectedPartnershipType) {
          setPartnershipTypeError("Please select a partnership type");
          toast.error("Please select a partnership type", toastConfig);
          setSubmitting(false);
          return;
        }

        // Set loading state
        setLoading(true);
        setSubmissionAttempts((prev) => prev + 1);

        // Set partnership type in values
        values.partnershipType = selectedPartnershipType;

        // Sanitize inputs before sending to server
        const sanitizedData = {
          firstName: DOMPurify.sanitize(values.firstName?.trim() || ""),
          lastName: DOMPurify.sanitize(values.lastName?.trim() || ""),
          email: (values.email?.trim() || "").toLowerCase(),
          phone: (values.phone?.trim() || "").replace(/\s+/g, ""),
          companyName: DOMPurify.sanitize(values.companyName?.trim() || ""),
          companyWebsite: DOMPurify.sanitize(
            values.companyWebsite?.trim() || ""
          ),
          password: values.password || "",
          partnershipType: selectedPartnershipType,
          reasonForPartnership: DOMPurify.sanitize(
            values.reasonForPartnership?.trim() || ""
          ),
          submissionDate: new Date().toISOString(),
        };

        // Get CSRF token if implemented
        const csrfToken = localStorage.getItem("csrf_token");

        // Show loading toast
        // const loadingToastId = toast.loading(
        //   "Submitting your application...",
        //   toastConfig
        // );

        // API request with proper error handling
        await axios.post(`${API_URL}/register/partners/signup`, sanitizedData, {
          headers: csrfToken ? { "X-CSRF-Token": csrfToken } : {},
        });

        // Dismiss loading toast and show success
        // toast.dismiss(loadingToastId);
        toast.success(
          "Application submitted successfully! We'll review it shortly.",
          {
            ...toastConfig,
            autoClose: 7000,
          }
        );

        // Show success state
        setFormSubmitted(true);
        resetForm();
        setSelectedPartnershipType(null);
        setPasswordStrength(0);
      } catch (error) {
        if (error.response) {
          const errorMessage =
            error.response.data?.message ||
            "There was an error submitting your application. Please try again.";

          switch (error.response.status) {
            case 409:
              toast.error(
                "This email is already registered. Please use a different email address.",
                toastConfig
              );
              break;
            case 429:
              toast.error(
                "Too many submission attempts. Please try again later.",
                toastConfig
              );
              break;
            case 400:
              toast.error(
                "Invalid data provided. Please check your inputs.",
                toastConfig
              );
              break;
            default:
              toast.error(errorMessage, toastConfig);
          }
        } else if (error.request) {
          toast.error(
            "Network error. Please check your internet connection and try again.",
            toastConfig
          );
        } else {
          console.error("Error submitting form:", error);
          toast.error(
            "An unexpected error occurred. Please try again later.",
            toastConfig
          );
        }
      } finally {
        setLoading(false);
        formikBag.setSubmitting(false);
      }
    },
    [selectedPartnershipType, loading]
  );

  // Reset the form (for "Submit Another Application" button)
  const handleReset = useCallback(() => {
    setFormSubmitted(false);
  }, []);

  // Memoize the partnership type buttons to prevent re-renders
  const PartnershipTypeButtons = useMemo(
    () => (
      <div className="partnership-type-buttons">
        {partnershipTypes.map((type) => (
          <button
            key={type.id}
            type="button"
            className={`btn partnership-type-button ${
              selectedPartnershipType === type.id
                ? "btn-primary active"
                : "btn-light"
            }`}
            onClick={() => {
              setSelectedPartnershipType(type.id);
              // setFieldValue handled in the Form component
            }}
          >
            {type.label}
          </button>
        ))}
      </div>
    ),
    [selectedPartnershipType]
  );

  // If the form has been successfully submitted, show the success message component
  if (formSubmitted) {
    return <SuccessMessage onReset={handleReset} />;
  }

  // Password strength indicator component
  const PasswordStrengthIndicator = memo(({ strength }) => (
    <div className="password-strength mt-2">
      <div className="d-flex justify-content-between align-items-center">
        <small className={`text-${getPasswordStrengthColor(strength)}`}>
          {getPasswordStrengthText(strength)}
        </small>
        <div className="strength-meter d-flex">
          <div
            className={`strength-segment bg-${
              strength >= 1 ? getPasswordStrengthColor(1) : "secondary"
            }`}
          ></div>
          <div
            className={`strength-segment ml-1 bg-${
              strength >= 2 ? getPasswordStrengthColor(2) : "secondary"
            }`}
          ></div>
          <div
            className={`strength-segment ml-1 bg-${
              strength >= 3 ? getPasswordStrengthColor(3) : "secondary"
            }`}
          ></div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="partner-signup-container">
      <div className="card">
        <div className="card-body">
          <div className="partner-signup-header">
            <div className="logo-container mb-4">
              <img
                src="/media/logos/logo-dark.png"
                alt="SendaTradie Logo"
                className="sendatradie-logo"
              />
            </div>
            <h1 className="card-title">Partner Program Application</h1>
            <p className="text-muted">
              Join our partnership program and grow your business with
              SendaTradie
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={PartnerSignupSchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              isValid,
              dirty,
              handleSubmit: formikHandleSubmit,
            }) => (
              <Form
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  // Validate partnership type
                  if (!selectedPartnershipType) {
                    setPartnershipTypeError("Please select a partnership type");
                    toast.error(
                      "Please select a partnership type",
                      toastConfig
                    );
                    return;
                  }
                  // Set partnership type before submitting
                  setFieldValue("partnershipType", selectedPartnershipType);
                  formikHandleSubmit();
                }}
              >
                {/* Partnership type selection */}
                <div className="form-section">
                  <h5 className="section-title">
                    Partnership Type<span className="text-danger">*</span>
                  </h5>
                  <div className="partnership-type-buttons">
                    {partnershipTypes.map((type) => (
                      <button
                        key={type.id}
                        type="button"
                        className={`btn partnership-type-button ${
                          selectedPartnershipType === type.id
                            ? "btn-primary active"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          handlePartnershipTypeSelect(type.id, setFieldValue)
                        }
                      >
                        {type.label}
                      </button>
                    ))}
                  </div>
                  {/* Show partnership type error if any */}
                  {partnershipTypeError && (
                    <div className="text-danger mt-2 small">
                      {partnershipTypeError}
                    </div>
                  )}
                </div>

                {/* Personal and company details */}
                <div className="form-section mt-5">
                  <h5 className="section-title">Contact Information</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="firstName">
                          First Name<span className="text-danger">*</span>
                        </label>
                        <Field
                          id="firstName"
                          name="firstName"
                          type="text"
                          className={`form-control ${
                            errors.firstName && touched.firstName
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Your first name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="lastName">
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <Field
                          id="lastName"
                          name="lastName"
                          type="text"
                          className={`form-control ${
                            errors.lastName && touched.lastName
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Your last name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">
                      Email Address<span className="text-danger">*</span>
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email ? "is-invalid" : ""
                      }`}
                      placeholder="your.email@example.com"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <Field
                      id="phone"
                      name="phone"
                      type="tel"
                      className={`form-control ${
                        errors.phone && touched.phone ? "is-invalid" : ""
                      }`}
                      placeholder="Australian mobile or landline (e.g. 0412345678 or +61412345678)"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="invalid-feedback"
                    />
                    <small className="form-text text-muted">
                      Format: +61 or 0 followed by your number, no spaces or
                      formatting
                    </small>
                  </div>

                  <h5 className="section-title mt-4">Company Information</h5>
                  <div className="form-group">
                    <label htmlFor="companyName">
                      Company Name<span className="text-danger">*</span>
                    </label>
                    <Field
                      id="companyName"
                      name="companyName"
                      type="text"
                      className={`form-control ${
                        errors.companyName && touched.companyName
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Your company name"
                    />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="companyWebsite">
                      Company Website<span className="text-danger">*</span>
                    </label>
                    <Field
                      id="companyWebsite"
                      name="companyWebsite"
                      type="url"
                      className={`form-control ${
                        errors.companyWebsite && touched.companyWebsite
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="https://www.yourcompany.com"
                    />
                    <ErrorMessage
                      name="companyWebsite"
                      component="div"
                      className="invalid-feedback"
                    />
                    <small className="form-text text-muted">
                      Include the full URL with https://
                    </small>
                  </div>

                  <h5 className="section-title mt-4">Account Security</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="password">
                          Password<span className="text-danger">*</span>
                        </label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          className={`form-control ${
                            errors.password && touched.password
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Create a secure password"
                          onChange={(e) => {
                            handleChange(e);
                            // Only calculate strength when user stops typing
                            const value = e.target.value;
                            setTimeout(() => {
                              setPasswordStrength(
                                calculatePasswordStrength(value)
                              );
                            }, 300);
                          }}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />

                        {/* Password strength indicator */}
                        <PasswordStrengthIndicator
                          strength={passwordStrength}
                        />

                        <div className="password-requirements mt-2">
                          <small className="form-text text-muted">
                            Password must contain at least 8 characters, one
                            uppercase letter, and one number or special
                            character
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className={`form-control ${
                            errors.confirmPassword && touched.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Confirm your password"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <h5 className="section-title mt-4">Partnership Details</h5>
                  <div className="form-group">
                    <label htmlFor="reasonForPartnership">
                      Why do you want to partner with SendaTradie?
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      id="reasonForPartnership"
                      name="reasonForPartnership"
                      as="textarea"
                      className={`form-control ${
                        errors.reasonForPartnership &&
                        touched.reasonForPartnership
                          ? "is-invalid"
                          : ""
                      }`}
                      rows="5"
                      placeholder="Please describe your goals for this partnership and how you plan to contribute..."
                    />
                    <ErrorMessage
                      name="reasonForPartnership"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                {/* Terms and conditions */}
                <div className="form-group mt-4">
                  <div className="form-check">
                    <Field
                      type="checkbox"
                      id="terms"
                      name="terms"
                      className="form-check-input"
                      required
                    />
                    <label className="form-check-label" htmlFor="terms">
                      I agree to the{" "}
                      <a href="/terms" target="_blank">
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="/privacy" target="_blank">
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                </div>

                {/* Submit button */}
                <div className="form-group mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                    disabled={isSubmitting || loading}
                  >
                    {loading || isSubmitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submitting...
                      </>
                    ) : (
                      "Submit Application"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PartnerSignupForm;
