import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./PartnerLoginForm.css";

// Import environment variables or config
const API_URL = process.env.REACT_APP_API_URL || "https://www.api.sendatradie.com";

/**
 * Partner Login Form
 * Allows partners to log in to their dedicated dashboard
 */
const PartnerLoginForm = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Initial form values
  const initialValues = {
    email: "",
    password: ""
  };

  // Form validation schema
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setLoading(true);

      // API request to login partner
      const response = await axios.post(
        `${API_URL}/register/partners/login`,
        {
          email: values.email.trim(),
          password: values.password
        }
      );

      // Store token in localStorage
      if (response.data && response.data.token) {
        localStorage.setItem("partner_token", response.data.token);
        localStorage.setItem("partner_data", JSON.stringify(response.data.partner));
        
        // Redirect to partner dashboard
        history.push("/partner-dashboard");
      } else {
        throw new Error("Login failed: No authentication token received");
      }
    } catch (error) {
      console.error("Login error:", error);
      
      if (error.response) {
        // Handle server response errors
        if (error.response.status === 401) {
          setErrors({ 
            password: "Invalid email or password" 
          });
        } else {
          toast.error(error.response.data.message || "Login failed. Please try again.");
        }
      } else {
        toast.error("Connection error. Please check your internet connection and try again.");
      }
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <div className="partner-login-container">
      <div className="card">
        <div className="card-body">
          <div className="partner-login-header">
            <div className="logo-container mb-4">
              <img 
                src="/media/logos/logo-dark.png" 
                alt="SendaTradie Logo" 
                className="sendatradie-logo" 
              />
            </div>
            <h1 className="card-title">Partner Login</h1>
            <p className="text-muted">
              Log in to access your SendaTradie Partner Dashboard
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className="login-form">
                <div className="form-group">
                  <label htmlFor="email">Email Address<span className="text-danger">*</span></label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                    placeholder="your.email@example.com"
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password<span className="text-danger">*</span></label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                    placeholder="Enter your password"
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>

                <div className="d-flex justify-content-between align-items-center mb-5">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="rememberMe" />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember me
                    </label>
                  </div>
                  <a href="/partner-forgot-password" className="forgot-password-link">
                    Forgot Password?
                  </a>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                    disabled={isSubmitting || loading}
                  >
                    {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Logging in...
                      </>
                    ) : (
                      "Log In"
                    )}
                  </button>
                </div>

                <div className="text-center mt-4">
                  <p>
                    Don't have a partner account?{" "}
                    <a href="/partner-signup" className="signup-link">
                      Apply Now
                    </a>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PartnerLoginForm;