import axios from "axios";

export const COMPANY_URL = "https://www.api.sendatradie.com/company";

export function postSendInventory(data) {
  return axios.post(`${COMPANY_URL}/sendInventory`, data);
}
export function getSendInventory() {
  return axios.post(`${COMPANY_URL}/getInventory`);
}

export function uploadPDFInvoice(textExtract) {
  return axios.post(
    `${COMPANY_URL}/upload-invoice`,
    { textExtract },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function addItemToInvoice(data) {
  return axios.post(`${COMPANY_URL}/addItemToInvoice`, data);
}
export function deleteInvoiceItems(data) {
  return axios.post(`${COMPANY_URL}/deleteInvoiceItems`, data);
}
