import { useContext, useEffect, useState } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "./shephardStyle.scss";
import axios from "axios";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: "font-weight-bold",
    modalOverlayOpeningRadius: 6,
    scrollTo: {
      behavior: "smooth",
    },
    arrow: false,
  },
  useModalOverlay: true,
};

export default function DashboardTour({ page, newSteps, startTour, setStartTour }) {
  const [lockTour, setLockTour] = useState(false); // Lock mechanism

  function TourInstance({ startTour, setStartTour }) {
    const tour = useContext(ShepherdTourContext);

    useEffect(() => {
      const handleLoad = async () => {
        if (startTour && !lockTour) {
          setLockTour(true); // Lock the tour
          tour.start();

          try {
            await axios.post(`https://www.api.sendatradie.com/settings/updateFirstView`, { path: page });
          } catch (error) {
            console.error("Error updating first view:", error);
          }

          setStartTour(false);

          // Unlock the tour when it completes
          tour.on("complete", () => setLockTour(false));
          tour.on("cancel", () => setLockTour(false));
        }
      };

      if (document.readyState === "complete") {
        handleLoad();
      } else {
        window.addEventListener("load", handleLoad);
      }

      return () => {
        window.removeEventListener("load", handleLoad);
      };
    }, [startTour, lockTour]); // Depend on lockTour to avoid duplicate execution

    return null;
  }

  return (
    <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
      <TourInstance startTour={startTour} setStartTour={setStartTour} />
    </ShepherdTour>
  );
}
