import React from "react";
import { connect, useSelector } from "react-redux";

export default function Lock({ settings, saveDraggable }) {
  const userData = useSelector((state) => state.auth.user);

  // Handle click with stopPropagation to prevent drag events
  const handleLockClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    saveDraggable(settings.draggingData);
  };

  return userData.pack_id != 1 ? (
    <div
      style={{
        zIndex: settings.draggingData?.isDragDisabled ? undefined : 98, // A numerical value that's lower than modals (1050) but still above content
        position: "absolute", // Change to absolute to remove from normal flow
        top: "5px",
        right: "5px",
        cursor: "pointer !important",
        pointerEvents: "auto",
        width: "30px", // Fixed width
        height: "30px", // Fixed height
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
        borderRadius: "4px",
      }}
      className="btn btn-icon btn-clean d-flex align-items-center lock-button"
      onClick={handleLockClick}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
    >
      <span
        style={{ pointerEvents: "none" }}
        dangerouslySetInnerHTML={{
          __html: settings.draggingData.icon,
        }}
      />
    </div>
  ) : (
    ""
  );
}
