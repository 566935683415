import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./PartnerLoginForm.css";

// Import environment variables or config
const API_URL = process.env.REACT_APP_API_URL || "https://www.api.sendatradie.com";

/**
 * Partner Forgot Password Form
 * Allows partners to request a password reset link
 */
const PartnerForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  
  // Initial form values
  const initialValues = {
    email: ""
  };
  
  // Form validation schema
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required")
  });
  
  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setLoading(true);
      
      // API request to send password reset email
      const response = await axios.post(
        `${API_URL}/register/partners/forgot-password`,
        {
          email: values.email.trim()
        }
      );
      
      // Show success message
      if (response.data && response.data.success) {
        setEmailSent(true);
      } else {
        throw new Error("Failed to send reset email");
      }
    } catch (error) {
      console.error("Forgot password error:", error);
      
      // Don't reveal if email exists for security, show generic success message
      // but still log the error for debugging
      setEmailSent(true);
      
      if (error.response && error.response.status !== 404) {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };
  
  return (
    <div className="partner-login-container">
      <div className="card">
        <div className="card-body">
          <div className="partner-login-header">
            <div className="logo-container mb-4">
              <img 
                src="/media/logos/logo-dark.png" 
                alt="SendaTradie Logo" 
                className="sendatradie-logo" 
              />
            </div>
            <h1 className="card-title">Forgot Password</h1>
            <p className="text-muted">
              Enter your email to reset your password
            </p>
          </div>
          
          {emailSent ? (
            <div className="text-center">
              <div className="alert alert-success" role="alert">
                <i className="fa fa-check-circle mr-2"></i>
                Password reset instructions have been sent to your email.
              </div>
              <p>
                Please check your inbox and follow the instructions to reset your password.
                If you don't receive an email within a few minutes, please check your spam folder.
              </p>
              <Link to="/partner-login" className="btn btn-primary mt-4">
                Return to Login
              </Link>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={ForgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className="login-form">
                  <div className="form-group">
                    <label htmlFor="email">Email Address<span className="text-danger">*</span></label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                      placeholder="your.email@example.com"
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    <small className="form-text text-muted">
                      Enter the email address you used to register your partner account.
                    </small>
                  </div>
                  
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      disabled={isSubmitting || loading}
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          Sending...
                        </>
                      ) : (
                        "Send Reset Link"
                      )}
                    </button>
                  </div>
                  
                  <div className="text-center mt-4">
                    <p>
                      Remember your password?{" "}
                      <Link to="/partner-login" className="login-link">
                        Back to Login
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerForgotPassword;