import React, { useEffect, useMemo, useRef, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "./";
import { Pagination, LoadingDialog } from "../_partials/controls";
import _, { filter, isEqual, isFunction } from "lodash";

export default function CustomPaginationTable({
  data,
  columns,
  ids,
  setIds,
  isLoading,
  isSelectable,
  enableSizeFive,
  tableRef,
  setPagination,
  totalSize,
  resetPage,
  setResetPage,
}) {
  const [tableProps, setTableProps] = React.useState({
    pageNumber: 1,
    pageSize: enableSizeFive ? 5 : 10,
    sortField: "",
    sortOrder: "",
  });
  //   const [sortedData, setSortedData] = React.useState([]);
  const sizePerPageList = enableSizeFive
    ? [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
      ]
    : [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
      ];

  //   const paginationDataValue = (data) => {
  //     const indexLastRow = tableProps.pageNumber * tableProps.pageSize;
  //     const indexFirstRow = indexLastRow - tableProps.pageSize;
  //     const currentRows = data.slice(indexFirstRow, indexLastRow);
  //     return currentRows;
  //   };

  // useEffect(() => {
  //   //hideconsole.log(ids);
  //   //hideconsole.log(filteredData);
  //   //hideconsole.log(columns);
  //   //hideconsole.log(isLoading);
  // }, [ids, isLoading, columns, filteredData]);

  useEffect(() => {
    setPagination(tableProps);
  }, [tableProps]);

  useEffect(() => {
    if (ids?.length) {
      setIds([]);
    }
  }, [data]);

  useEffect(() => {
    if (resetPage) {
      setTableProps((prev) => {
        return { ...prev, pageNumber: 1 };
      });
      setResetPage(false);
    }
  }, [resetPage]);

  const handleTableProps = useCallback((newProps) => {
    setTableProps((prevProps) => {
      if (isFunction(newProps)) {
        newProps = newProps(prevProps);
      }

      if (isEqual(prevProps, newProps)) {
        return prevProps;
      }

      return newProps;
    });
  }, []);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalSize || 0,
    sizePerPageList: sizePerPageList,
    sizePerPage: tableProps.pageSize,
    page: tableProps.pageNumber,
  };

  // useEffect(() => {
  //   //hideconsole.log(sortedData);
  // }, [sortedData]);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      if (!ids.includes(row.id)) {
        setIds([...ids, row.id]);
      }
    } else {
      setIds(ids.filter((x) => x !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setIds(ids);
    } else {
      setIds([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: ids,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    style: { backgroundColor: "#f3f6f9" },
    selectionHeaderRenderer: (props) => {
      // //hideconsole.log(props);
      // //hideconsole.log("props header");

      // const isSelected = props.checked;
      // const props = { isSelected, entities, setIds };
      return (
        <>
          <input type="checkbox" style={{ display: "none" }} />
          <label className="checkbox checkbox-single">
            <input
              type="checkbox"
              checked={props.checked}
              onChange={() => handleOnSelectAll(data, props.checked)}
              onClick={(e) => e.stopPropagation()}
            />
            <span />
          </label>
        </>
      );
    },
    selectionRenderer: (props) => {
      // //hideconsole.log(props);
      // //hideconsole.log("props row");
      // const isSelected = props.checked;
      // const props = { ids, setIds, customerId: entities[rowIndex]?.id };

      return (
        <>
          <input type="checkbox" style={{ display: "none" }} />
          <label className="checkbox checkbox-single">
            <input
              type="checkbox"
              checked={props.checked}
              onChange={() =>
                handleOnSelect(data[props.rowIndex], props.checked)
              }
              onClick={(e) => e.stopPropagation()}
            />
            <span />
          </label>
        </>
      );
    },
  };

  const customColumns = columns.map((column) => ({
    ...column,
    // Adjust header style for maximum boldness and contrast without affecting the background
    headerStyle: () => ({
      fontWeight: "bold",
    }),
  }));

  return (
    <>
      <LoadingDialog isLoading={isLoading} text="Loading..." />

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                ref={tableRef}
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center tour_jobs_select"
                bootstrap4
                remote
                keyField="id"
                data={data}
                columns={
                  customColumns.length
                    ? customColumns
                    : [{ text: "COLUMNS", dataField: "col" }]
                }
                onTableChange={getHandlerTableChange(handleTableProps)}
                selectRow={
                  isSelectable
                    ? selectRow
                    : // ? getSelectRow({
                      //     entities: sortedData,
                      //     ids: ids,
                      //     setIds: setIds,
                      //   })
                      undefined
                }
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={data} />
                <NoRecordsFoundMessage entities={data} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
